export const STATEMENT_TAXES = "Statement of Taxes"
export const SETTINGS = "Statement of Taxes Template"
export const SAVE = "Save"

export const STATEMENT_TAXES_SUMMARY_FIELDS = [
    {id: 'description', label: 'Description', align: "center"},
    {id: 'totalAmount', label: 'Total Amount', align: "center"},
    {id: 'taxAmount', label: 'Tax Amount', align: "center"},
]

export const STATEMENT_TAXES_PAGES = {
    TABLE: "table",
    SETTINGS: "settings",
}

export const SUM_CATEGORY_OPTIONS = [
    "Sum of Category",
    "Sum of Taxes of Category"
];
export const TYPE_OPTIONS = [
    "Paid",
    "Received"
];

// tooltip names
export const BACK = "Back to Menu"
