export const PURCHASE_SUMMARY = "Purchase Summary"


export const PURCHASE_SUMMARY_FIELDS = [
    {id: 'supplierName', label: 'Supplier', align: "center"},
    {id: 'PY_SUBTOTAL_PAYABLE', label: 'PY Subtotal Payable', align: "center"},
    {id: 'PY_TAX_PAYABLE', label: 'PY Tax Payable', align: "center"},
    {id: 'CY_SUBTOTAL', label: 'CY Subtotal', align: "center"},
    {id: 'CY_TAX', label: 'CY Tax', align: "center"},
    {id: 'CY_SUBTOTAL_PAYABLE', label: 'CY Subtotal Payable', align: "center"},
    {id: 'CY_TAX_PAYABLE', label: 'CY Tax Payable', align: "center"},
    {id: 'CY_TOTAL_PAYABLE', label: 'CY Total Payable', align: "center"}
]

export const PURCHASE_SUMMARY_PAGES = {
    TABLE: "table",
}

// tooltip names
export const BACK = "Back to Menu"
