export const CARRIER_TOOLBAR_TITLE = "Carriers";

export const CARRIER_TABLE_HEAD_CELLS = [
    { id: 'name', center: true, disablePadding: false, label: 'Carrier' },
    { id: 'alias', center: false, disablePadding: true, label: 'Location' },
    { id: 'address', center: false, disablePadding: false, label: 'Address' },
    { id: 'accountNumber', center: false, disablePadding: false, label: 'Account' },
    { id: 'isDefault', center: false, disablePadding: false, label: 'Default' },
    { id: 'enabled', center: false, disablePadding: false, label: 'Enabled'},
    { id: '', center: false, disablePadding: false, label: '' },
]

export const DELETE_TITLE = "Delete Carrier?";
export const DELETE_DESCRIPTION = "This action can not be reversed. Continue with deletion?";

export const ADD_CARRIER_TITLE = "Add a carrier for shipping purposes";
export const ADD_CARRIER = "Carrier";

export const CREATE_CARRIER_TITLE = "Add Carrier";
export const CREATE_CARRIER_DESCRIPTION = "Add a Carrier to your company account";

export const EDIT_CARRIER_TITLE = "Edit Carrier";
export const DELETE_CARRIER_TITLE = "Delete Carrier";

export const CREATE_CARRIER = "Save";
export const CANCEL = "Cancel";
export const BACK = "Back";
export const NEXT = "Next";
export const DELETE = "Delete";

export const MARKETPLACE = "Marketplace";

export const DEFAULT_SERVICE = "Default Service";

export const SERVICE = "Service";

export const CARRIER_SETUP = "Carrier Setup";

export const CARRIER_STEPPER_LABELS = [
    "Select Carrier Setup",
    "Select Carrier",
    "Add Account Information",
    "Add Address",
    "Address Validation",
    "Automation Settings"
]

export const LTL_FTL_CARRIER_STEPPER_LABELS = [
    "Select Carrier Setup",
    "Select Carrier",
    "Automation Settings"
]

export const AVAILABLE_CARRIERS = [
    "FedEx",
    "Canpar",
    "USPS",
    "Amazon",
    "GLS",
    "Canada Post",
    "UPS",
    "Amazon Shipping",
]

export const STANDARD_SETUP = "Standard Carrier"
export const BUY_SHIPPING_SETUP = "Amazon Buy Shipping"
export const LTL_FTL_CARRIERS = "LTL/FTL Carriers"

export const BUY_SHIPPING = "Amazon.com - "

export const SETUP_TYPES = [STANDARD_SETUP, BUY_SHIPPING_SETUP, LTL_FTL_CARRIERS]

export const STEP_CASE_STANDARD = {
    SELECT_SETUP: 0,
    SELECT_CARRIER: 1,
    CARRIER_INFORMATION: 2,
    ADDRESS_INFORMATION: 3,
    ADDRESS_VALIDATION: 4,
    AUTOMATION_SETTINGS: 5
}

export const STEP_CASE_FTL = {
    SELECT_SETUP: 0,
    SELECT_CARRIER: 1,
    AUTOMATION_SETTINGS: 2,
}

export const DEFAULT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_LABEL = "Carriers per page"