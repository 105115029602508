import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

// constants for endpoints
import {
    ACCOUNT
} from "../../../components/global/Server/endpoints";

// constants for actions
import {
    GET_USER_SETTING,
    STORE_USER_SETTING,
    SAVE_USER_SETTING, GET_USER_TICKET_FILTER, STORE_USER_TICKET_FILTER, UPDATE_TABLE_FILTER,
} from "../../constants/account";

// secondary actions
import {
    storeTableFilter,
    storeTableSetting,
} from "../../actions/account";
import {
    setLoadingStatus,
    setOpen,
    clearStatus,
    requestStatus,
    setSavingStatus,
    setSavingOpen,
    setCreateOrderOpen, setVoidStatus, setVoidOpen, showErrorMessage
} from "../../actions/global";
import {
    RETRIEVE_USER_TABLE_FILTER_FAILED,
    SERVER_FAILED_ERROR_MESSAGE
} from '../../../pages/Dashboard/DashboardFinal/constants';

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

function* updateTicketTableFilter(action){
    yield call(clearStatus)
    const request = { ticketTableFilter: action.filter.updatedFilter}
    const response = yield call(() => postRequest(ACCOUNT.UPDATE_USER_TICKET_TABLE_FILTER, request));
    if (response.status === 200) {
        yield put(storeTableFilter(response.data))
    }
}

function* saveUserSetting(action) {
    yield call(clearStatus)
    try {
        const request = {
            userId: action.setting.userId,
            pageTitle: action.setting.pageTitle,
            tableSetting: action.setting.tableSetting,
            defaultTable: action.setting.defaultTable,
            filterSetting: action.setting.filterSetting,
            changeFilters: action.setting.changeFilters,
            changePurchaseOrderFilters: action.setting.changePurchaseOrderFilters,
            purchaseOrderFilters: action.setting.purchaseOrderFilters,
            returnFilterSetting: action.setting.returnFilterSetting,
            changeReturnsFilters: action.setting.changeReturnsFilters,
            ticketFilterSetting: action.setting.ticketFilterSetting,
            changeTicketsFilters: action.setting.changeTicketsFilters,
            dense: action.setting.dense,
            inventoryFilterSetting: action.setting.inventoryFilterSetting,
            changeInventoryFilters: action.setting.changeInventoryFilters,
            changeCustomerInvoiceFilters: action.setting.changeCustomerInvoiceFilters,
            customerInvoiceFilterSetting: action.setting.customerInvoiceFilterSetting,
            changeSupplierInvoiceFilters: action.setting.changeSupplierInvoiceFilters,
            supplierInvoiceFilterSetting: action.setting.supplierInvoiceFilterSetting,
            changeCarrierInvoiceFilters: action.setting.changeCarrierInvoiceFilters,
            carrierInvoiceFilterSetting: action.setting.carrierInvoiceFilterSetting,
            changeAutomationProfitLossFilters: action.setting.changeAutomationProfitLossFilters,
            automationSetting: action.setting.automationSetting,
            changePricingDashboardChartSetting: action.setting.changePricingDashboardChartSetting,
            pricingDashboardChartSetting: action.setting.pricingDashboardChartSetting

        };
        const response = yield call(() => postRequest(ACCOUNT.SAVE_USER_SETTING, request));
        if (response.status === 200) {
            yield put(storeTableSetting(response.data))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTableSetting(action) {
    yield call(clearStatus)
    try {
        const request = {
            userId: action.userId
        };
        const response = yield call(() => postRequest(ACCOUNT.GET_USER_SETTING, request));
        if (response.status === 200) {
            yield put(storeTableSetting(response.data))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTicketTableFilter(action) {
    yield call(clearStatus)
    try{
        const request = {
            defaultFilter: action.setting.defaultFilter
        };
        const response = yield call(() => postRequest(ACCOUNT.GET_USER_TICKET_TABLE_FILTER,request));
        if (response.status === 200) {
            yield put(storeTableFilter(response.data))
        }
    } catch (error) {
        yield put(showErrorMessage(RETRIEVE_USER_TABLE_FILTER_FAILED))
    }
}

export default function* accountSagas() {
    yield takeLatest(SAVE_USER_SETTING, saveUserSetting);
    yield takeLatest(GET_USER_SETTING, getTableSetting);
    yield takeLatest(GET_USER_TICKET_FILTER, getTicketTableFilter)
    yield takeLatest(UPDATE_TABLE_FILTER,updateTicketTableFilter)
}
