import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import {
    CREATE_RETURN,
    LIST_RETURN_ACTIONS,
    LIST_RETURN_CONDITIONS,
    LIST_RETURN_REASONS,
    LIST_RETURNS, PREVIEW_RETURN_FILE,
    UPDATE_RETURN,
    LIST_UNCOMPLETED_RETURNS, FETCH_RMA_INFO
} from "../../constants/returns";
import { clearStatus, requestStatus, showErrorMessage, hideError } from "../../actions/global";
import { HTTP_SUCCESS } from "../../constants/global";
import { RETURNS } from "../../../components/global/Server/endpoints";
import {
    storeReturnActions,
    storeReturnConditions,
    storeReturnReasons,
    storeReturns, storeRmaFetch,
    storeUpdatedReturn, storeUpdatedReturnWithPreview
} from "../../actions/returns";
import {RMA_NOT_MAPPED, SERVER_FAILED_ERROR_MESSAGE} from '../../../pages/Dashboard/DashboardFinal/constants';

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

const postFiles = (endpoint, files) => {
    let formData = new FormData()
    files.forEach((file, i) => {
        formData.append('files[' + i + ']', file)
    })

    return axios.post(endpoint, formData, {
        headers: getHeaders()
    })
        .then(response => response)
}

function* listReturnsSaga() {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => getRequest(RETURNS.LIST_RETURNS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeReturns(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* listUncompletedReturnsSaga() {
    yield call(clearStatus)
    const response = yield call(() => getRequest(RETURNS.LIST_UNCOMPLETED_RETURNS))
    const status = {
        status: response.status,
        statusText: response.statusText,
        success: response.status === HTTP_SUCCESS,
    };
    yield put(storeReturns(response.data))
    yield put(requestStatus(status))
}

function* createReturnSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response1 = yield call(() => postRequest(RETURNS.CREATE_RETURN, action.newReturnItem))
        const status1 = {
            status: response1.status,
            statusText: response1.statusText,
            success: response1.status === HTTP_SUCCESS,
        };

        if (!response1.data.success) {
            yield put(showErrorMessage(response1.data.message));
        } else {
            // new added files?
            if (action.newReturnItem.newFiles.length !== 0) {
                const response2 = yield call(() => postRequest(RETURNS.ADD_FILES,
                    {files: action.newReturnItem.newFiles, returnId: response1.data.returnItem.returnId}))
                const status2 = {
                    status: response2.status,
                    statusText: response2.statusText,
                    success: response2.status === HTTP_SUCCESS,
                };
                if (!response2.data.success) {
                    yield put(showErrorMessage(response2.data.message));
                } else {
                    yield put(storeUpdatedReturn(response2.data.returnItem))
                }
                yield put(requestStatus(status2))
            } else {
                yield put(storeUpdatedReturn(response1.data.returnItem))
                yield put(requestStatus(status1))
            }
            yield action.reset()
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}


function* updateReturnSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(RETURNS.UPDATE_RETURN, action.returnItem))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            if (action.returnItem.newFiles.length === 0 && action.returnItem.deletedFiles.length === 0) {
                yield put(storeUpdatedReturn(response.data.returnItem))
            } else {
                // new added files?
                if (action.returnItem.newFiles.length !== 0) {
                    const response2 = yield call(() => postRequest(RETURNS.ADD_FILES, {
                        files: action.returnItem.newFiles,
                        returnId: response.data.returnItem.returnId
                    }))
                    const status2 = {
                        status: response2.status,
                        statusText: response2.statusText,
                        success: response2.status === HTTP_SUCCESS,
                    };
                    if (!response2.data.success) {
                        yield put(showErrorMessage(response2.data.message));
                    } else {
                        yield put(storeUpdatedReturn(response2.data.returnItem))
                    }
                    yield put(requestStatus(status2))
                }
                // deleted files?
                if (action.returnItem.deletedFiles.length !== 0) {
                    const response2 = yield call(() => postRequest(RETURNS.DELETE_FILES, {
                        files: action.returnItem.deletedFiles,
                        returnId: response.data.returnItem.returnId
                    }))
                    const status2 = {
                        status: response2.status,
                        statusText: response2.statusText,
                        success: response2.status === HTTP_SUCCESS,
                    };
                    if (!response2.data.success) {
                        yield put(showErrorMessage(response2.data.message));
                    } else {
                        yield put(storeUpdatedReturn(response2.data.returnItem))
                    }
                    yield put(requestStatus(status2))
                }
            }
            yield action.reset()
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* listReturnActionsSaga() {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => getRequest(RETURNS.LIST_RETURN_ACTIONS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeReturnActions(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}
function* listReturnConditionsSaga() {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => getRequest(RETURNS.LIST_RETURN_CONDITIONS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeReturnConditions(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* listReturnReasonsSaga() {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => getRequest(RETURNS.LIST_RETURN_REASONS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeReturnReasons(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* previewReturnFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(RETURNS.PREVIEW_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedReturnWithPreview(response.data.returnItem))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* fetchRmaInfo(action){
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(RETURNS.FETCH_RMA, action))
        if(response.data.success) {
            yield put(storeRmaFetch(response.data))
        } else{
            yield put(showErrorMessage(RMA_NOT_MAPPED));
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

export default function* returnsSaga() {
    yield takeLatest(LIST_RETURNS, listReturnsSaga)
    yield takeLatest(CREATE_RETURN, createReturnSaga)
    yield takeLatest(UPDATE_RETURN, updateReturnSaga)
    yield takeLatest(LIST_RETURN_ACTIONS, listReturnActionsSaga)
    yield takeLatest(LIST_RETURN_CONDITIONS, listReturnConditionsSaga)
    yield takeLatest(LIST_RETURN_REASONS, listReturnReasonsSaga)
    yield takeLatest(PREVIEW_RETURN_FILE, previewReturnFileSaga)
    yield takeLatest(LIST_UNCOMPLETED_RETURNS, listUncompletedReturnsSaga)
    yield takeLatest(FETCH_RMA_INFO, fetchRmaInfo)
}