import React from 'react'

// Material components
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";

// Custom components
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import DateFnsUtils from "@date-io/date-fns";
import CustomerInvoicesTable from "./CustomerInvoicesTable";

// Redux
import PropTypes from "prop-types";
import { clearStatus } from "../../../redux/actions/global";
import {
    processingSelector, supplierInvoicePDFSelector,
} from "../../../redux/selectors/invoices";
import { ERROR, SUCCESS } from "../../settings/ManageUsers/constants";

// Icons
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PendingActionsIcon from '@mui/icons-material/PendingActions';

// Constants
import ErrorFeedbackBar from '../../global/ErrorFeedbackBar';
import {
    READY_SEND_STATUS,
    INVOICE_ITEM_CELLS,
    NEEDS_REVIEW_STATUS,
    PAID_STATUS,
    DUPLICATE_STATUS,
    PAYMENT_PENDING_STATUS
} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import { statusSelector } from "../../../redux/selectors/global";
import { tableSettingSelector } from '../../../redux/selectors/account';
import { getTableSetting, saveTableSetting } from "../../../redux/actions/account";
import { userInfoSelector } from '../../../redux/selectors/auth';
import {DEFAULT_PDF_ZOOM, getByteArray} from "../../global/constants";
import {DEFAULT_ROWS_PER_PAGE} from "../../returns/constants";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {GetApp, HourglassEmptyTwoTone, Restore} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import CustomerCreateInvoices from "./CustomerCreateInvoices";
import {
    createCustomerInvoice,
    deleteCustomerInvoice,
    deleteCustomerInvoiceAttachment,
    getAllCustomerInvoices,
    getCustomerInvoiceAttachment,
    getCustomerInvoicePDF,
    getCustomerPurchaseOrder,
    listCustomers,
    storeAllCustomerInvoices,
    storeCustomerInvoiceOperationResponse, storeCustomerInvoicePDF,
    updateCustomerInvoice,
    updateCustomerInvoiceChanged,
    updateCustomerInvoiceStatus
} from "../../../redux/actions/customer";
import {
    allCustomerInvoicesSelector,
    customerInvoiceChangedSelector,
    customerInvoiceOperationResponseSelector, customerInvoicePDFSelector, customerInvoicePOSelector, customersSelector
} from "../../../redux/selectors/customer";
import { sendInvoiceOrderEmail} from "../../../redux/actions/settings";
import CustomerEditInvoices from "./CustomerEditInvoices";
import SendIcon from "@material-ui/icons/Send";
import PaymentPopper from "../../invoices/PaymentPopper";
import {INVOICE_TYPE_CUSTOMER_INVOICE, INVOICE_TYPE_SUPPLIER} from "../../invoices/constants";

class CustomerInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            creating: false,
            editing: null,
            viewing: false,
            selecting: false,
            selectedEntries: {},
            invoicePDF: new Uint8Array(0),
            searchText: '',
            scale: DEFAULT_PDF_ZOOM,
            itemError: { index: -1, id: "" },
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            newFiles: [],
            viewingFile: {},
            deletingAttachment: false,
            openInvoiceTemplates: false,
            feedbackBarOpen:false,
            feedbackBarText: "",
            invoicesList: this.props.allInvoices,
            createInvoice: null,
            type: null,
            fetchedList: true
        }
        this.hiddenFileInput = React.createRef()
        this.fileUpload = this.fileUpload.bind(this)
        this.setEditing = this.setEditing.bind(this)
        this.previewInvoice = this.previewInvoice.bind(this)
        this.save = this.save.bind(this)
        this.create = this.create.bind(this)
        this.handleStatusClose = this.handleStatusClose.bind(this)
    }
    componentDidMount() {
        this.props.getAllCustomerInvoices({
            fromDate: this.getFromDate(),
            toDate:  this.getToDate()
        })
        this.setState({
            fetchedList: true
        })
        this.props.getTableSetting(this.props.user.userId)
        this.props.listCustomers()
        if (this.state.editing) {
            this.props.getCustomerPurchaseOrder(this.state.editing.poNumber)
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.viewing && this.state.invoicePDF.length !== this.props.invoicePDF.length)
            this.setState({ invoicePDF: this.props.invoicePDF })

        if ((prevState.editing !== this.state.editing) && (this.state.editing) && !Object.keys(this.props.purchaseOrder).length) {
            this.props.getCustomerPurchaseOrder(this.state.editing.poNumber)
        }

        if (prevProps.allInvoices !== this.props.allInvoices) {

            this.setState({ invoicesList: this.props.allInvoices });
            // when the invoice information is updated, and we are editing an invoice, then also update this invoice
            if (this.state.editing) {
                this.setState({
                    editing: this.props.allInvoices.filter(invoice=>invoice.invoiceNumber == this.state.editing.invoiceNumber)[0]
                })
            }
        }

        if (this.props.editingInvoiceChanged) {
            if (this.state.editing) {
                this.setState({
                    editing: this.props.allInvoices.filter(invoice => invoice.invoiceNumber == this.state.editing.invoiceNumber)[0]
                })
            }
            this.props.updateCustomerInvoiceChanged()
        }

        if (prevProps.operationResponse !== this.props.operationResponse && this.props.operationResponse !== "") {
            this.setState({
                feedbackBarOpen: true,
                feedbackBarText:this.props.operationResponse
            })
            this.props.storeCustomerInvoiceOperationResponse("")
        }

        if (!prevState.editing && prevState.editing !== this.state.editing) {
            if (this.state.editing) {
                this.setState(prevState => {

                    //only show those line items with quantity > 0
                    const updatedItems = prevState.editing.customerInvoiceItems ? prevState.editing.customerInvoiceItems.filter(invoice => invoice.quantity > 0) : null;
                    if (updatedItems === null) {
                        return null;
                    }


                    // if a lineItem have tax as a null value, assign it a 0.00, Also calulates the Amount subtotal and Tax subtotal
                    let amountTotal = 0
                    let taxTotal = 0
                    let taxChanged = false
                    updatedItems.map(item =>{
                        if (!item.tax) {
                            item.tax = "0.00"
                            taxChanged = true
                        }
                        amountTotal += parseFloat(item.amount)
                        taxTotal += parseFloat(item.tax)
                    })

                    // Check if the filtered items are different from the current state
                    return {
                        editing: {
                            ...prevState.editing, // preserve all the existing properties of editing
                            customerInvoiceItems: updatedItems,
                            amountSubTotal: this.formatDollar(amountTotal.toFixed(2)),
                            taxSubTotal: this.formatDollar(taxTotal.toFixed(2))

                        },
                    };

                    return null; // No state update
                });
            }
        }

        // when purchase order changes, then recalculate the tax for the whole invoice
        if (prevProps.purchaseOrder.poNumber && prevProps.purchaseOrder?.poNumber!== this.props.purchaseOrder?.poNumber) {
            if (this.state.editing) {
                let tempEditing = this.state.editing
                tempEditing.taxPercentage = this.props.purchaseOrder.taxPercentage
                this.setState({
                    editing: tempEditing
                }, () => {this.triggerLineItemCalculations()})
            }
        }
    }

    setFetchedList = (fetched) => {
        this.setState({
            fetchedList: fetched
        })
    }

    createInvoice = (childState) => {
        this.setState({createInvoice: childState})
    }

    getFromDate = () => {
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    handleDateChange = (date, key) => {
        this.setState({
            [key]: date
        })
    }

    getPurchaseOrder = (value) => {
        this.props.getCustomerPurchaseOrder(value.trim())
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    setEditing(value) {
        this.setState({ editing: value })
    }

    openInvoiceTemplates() {
        this.setState({
            openInvoiceTemplates: true
        })
    }
    closeInvoiceTemplates = () => {
        this.setState({
            openInvoiceTemplates: false
        })
    }

    triggerLineItemCalculations = () => {
        let tempEditing = this.state.editing
        let newTaxSubTotal = 0;
        let newAmountSubTotal = 0;
        // recalculate for each entry and recalculate the sum and tax
        tempEditing.customerInvoiceItems.map((item,index) => {
            this.changeEditingItem('discount',item.discount,index)

            newTaxSubTotal += parseFloat(item.tax.replace(/,/g, ''));
            newAmountSubTotal += parseFloat(item.amount.replace(/,/g, ''));
        });

        tempEditing.amountSubTotal = this.formatDollar(newAmountSubTotal.toFixed(2)); // Convert back to a string with 2 decimal places, if needed.
        tempEditing.taxSubTotal = this.formatDollar(newTaxSubTotal.toFixed(2)); // Convert back to a string with 2 decimal places, if needed.

        // Calculate invoiceTax based on the subtotal and shipping amount
        let shippingAmount = parseFloat(tempEditing.shippingAmount.replace(/,/g, '')) || 0;
        let taxPercentage = parseFloat(tempEditing.taxPercentage) || 0;
        let newInvoiceTax = ((newAmountSubTotal + shippingAmount) * (taxPercentage / 100)).toFixed(2);

        tempEditing.invoiceTax = this.formatDollar(newInvoiceTax);


        this.setState({
            editing: tempEditing
        })
    }
    changeEditing(name, value) {
        if (!this.state.editing) return
        if (name === "currencyCode") {
            let tempEditing = { ...this.state.editing }
            for (const item of tempEditing.customerInvoiceItems) {
                item.currencyCode = value
            }
            this.setState({ editing: tempEditing })
            return
        }


        let tempEditing = this.state.editing
        let nextChange = {name: name, value: value, index: -1} // index -1 means "not an indexed value" here
        let oldValue
        while (nextChange) {

            oldValue = tempEditing[nextChange.name]
            tempEditing[nextChange.name] = nextChange.value

            if (name === 'invoiceSubTotal' && nextChange['name'] === 'invoiceSubTotal') nextChange['name'] = 'invoiceTax' // as a special case, skip updating tax if subtotal was edited manually

            nextChange = this.getNextChange(nextChange, this.state.editing, oldValue)
        }

        this.setState({
            editing: tempEditing
        })
    }
    changeEditingItem(name, value, index) {
        if (!this.state.editing) return
        let tempEditing = this.state.editing

        let nextItemChange = {name: name, value: value, index: index}
        let oldValue
        while (nextItemChange) {

            if (nextItemChange.index !== -1) {
                oldValue = tempEditing.customerInvoiceItems[nextItemChange.index][nextItemChange.name]
                tempEditing.customerInvoiceItems[nextItemChange.index][nextItemChange.name] = nextItemChange.value
            } else {
                oldValue = tempEditing[nextItemChange.name]
                tempEditing[nextItemChange.name] = nextItemChange.value
            }
            nextItemChange = this.getNextChange(nextItemChange, tempEditing, oldValue)
        }

        let newTaxSubTotal = 0;
        let newAmountSubTotal = 0;
        tempEditing.customerInvoiceItems.map(item => {
            newTaxSubTotal += parseFloat(item.tax.replace(/,/g, ''));
            newAmountSubTotal += parseFloat(item.amount.replace(/,/g, ''));
        });


        tempEditing.amountSubTotal = this.formatDollar(newAmountSubTotal.toFixed(2));
        tempEditing.taxSubTotal = this.formatDollar(newTaxSubTotal.toFixed(2));

        this.setState({
            editing: tempEditing,
            itemError: this.state.itemError.index === index && this.state.itemError.id === name ? { index: -1, id: "" } : this.state.itemError
        })
    }

    getNextChange(oldNextItemChange, customerInvoice, oldValue) {
        let value = 0.0
        let newNextItemChange

        switch (oldNextItemChange.name) {
            case 'quantity':
            case 'unitPrice':
            case 'discount':
                value = (customerInvoice.customerInvoiceItems[oldNextItemChange.index]['quantity'] *
                    this.parseNumber(customerInvoice.customerInvoiceItems[oldNextItemChange.index]['unitPrice'])) -
                    this.parseNumber(customerInvoice.customerInvoiceItems[oldNextItemChange.index]['discount'])
                newNextItemChange = {
                    name: 'amount',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: oldNextItemChange.index
                }
                break
            case 'amount':
                value = (customerInvoice?.taxPercentage / 100) * this.parseNumber(customerInvoice.customerInvoiceItems[oldNextItemChange.index]['amount'])
                newNextItemChange = {
                    name: 'tax',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: oldNextItemChange.index
                }
                break
            case 'tax':
                customerInvoice.customerInvoiceItems.forEach((item) => value += this.parseNumber(item['amount']))
                newNextItemChange = {
                    name: 'invoiceSubTotal',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: -1
                }
                break
            case 'invoiceSubTotal':
                if (oldNextItemChange.name === 'shippingAmount') {
                    customerInvoice.customerInvoiceItems.forEach((item) => value += this.parseNumber(item['shippingAmount']));
                    newNextItemChange = {
                        name: 'shippingAmount',
                        value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                        index: -1
                    };
                } else if (oldNextItemChange.name !== 'shippingAmount') {
                        const invoiceSubTotal = this.parseNumber(customerInvoice.invoiceSubTotal);
                        const shippingAmount = this.parseNumber(customerInvoice.shippingAmount);
                        const taxPercentage = this.parseNumber(customerInvoice.taxPercentage);

                    const newTax = (invoiceSubTotal + shippingAmount) * (taxPercentage / 100).toFixed(2);

                    const invoiceTotal = (invoiceSubTotal + parseFloat(newTax) + shippingAmount).toFixed(2);


                        customerInvoice.invoiceTax = this.formatDollar(newTax, true);
                        customerInvoice.invoiceTotal = this.formatDollar(invoiceTotal, true);
                    }
                return null; // No further changes needed
                break
            case 'shippingAmount':

                const amountSubTotal = this.parseNumber(customerInvoice.amountSubTotal);
                const shippingAmount = this.parseNumber(customerInvoice.shippingAmount) || 0; // Handle zero value
                const taxPercentage = this.parseNumber(customerInvoice.taxPercentage);



                const newTax = ((amountSubTotal + shippingAmount) * (taxPercentage / 100)).toFixed(2);
                const invoiceTotal = (amountSubTotal + parseFloat(newTax) + shippingAmount).toFixed(2);



                newNextItemChange = {
                    name: 'invoiceTax',
                    value: this.formatDollar(newTax, true),
                    index: -1
                };
                customerInvoice.invoiceTotal = this.formatDollar(invoiceTotal, true);

                break

            case 'invoiceTax':
                const shipAmount = this.formatDollar((isNaN(this.parseNumber(customerInvoice['shippingAmount'])) ? 0 : this.parseNumber(customerInvoice['shippingAmount'])).toString())
                value = this.parseNumber(customerInvoice['invoiceSubTotal']) +
                    this.parseNumber(customerInvoice['invoiceTax']) +
                    this.parseNumber(shipAmount)


                newNextItemChange = {
                    name: 'invoiceTotal',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: -1
                }
                break
            case 'taxPercentage':

                this.triggerLineItemCalculations()
            default:
                return null
        }

        if(newNextItemChange['name'] === 'quantity') {
            // as a special case, quantity is saved as a number, not a string
            newNextItemChange['value'] = isNaN(value) ? 0 : value
        }

        return newNextItemChange
    }

    parseNumber = (string) => {
        // Check if the input is valid, return 0 if it's undefined, null, or an empty string
        if (!string) return 0;

        // Convert to string, remove commas, and parse as float
        return parseFloat(string?.toString().replaceAll(',', '')) || 0;
    }

    // parseNumber = (string) => {
    //     return parseFloat(string?.toString().replaceAll(',',''))
    // }

    formatDollar = (amount, forceTwoDecimals) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
        let value

        if (amount === 0) {
            return forceTwoDecimals ? "0.00" : "0";
        }

        if (!amount) {
            return "";
        }

        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            // if the amount has more than two decimal places, limit it to two
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',','')
                }
            } else {
                value = amount.replaceAll(',','')
            }
        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    blurEditing = (name, value, index) => {
        let tempEditing = this.state.editing

        switch (name) {
            case 'quantity':
                tempEditing.customerInvoiceItems[index][name] = isNaN(parseFloat(value)) ? "0" : this.parseNumber(value)
                break
            case 'unitPrice':
            case 'discount':
            case 'amount':
            case 'tax':
                tempEditing.customerInvoiceItems[index][name] = isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true)
                break
            case 'taxPercentage':
            case 'shippingAmount':
            case 'invoiceSubTotal':
            case 'invoiceTax':
                tempEditing[name] = isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true)
                break
        }

        this.setState({
            editing: tempEditing,
        })
    }

    isValid(tempEditing) {
        if (tempEditing === null) {
            return false;
        }

        // Set taxPercentage and shippingAmount to "0.00" if they are empty, null, or zero
        if (!tempEditing.taxPercentage || tempEditing.taxPercentage === '0') {
            tempEditing.taxPercentage = '0.00';
        }

        const ignoredKeys = ['paymentDate', 'referenceId', 'invoiceNumber', 'status', 'statusText', 'notes', 'deleteIndex', 'itemError', 'customer', 'date'];

        for (const key in tempEditing) {
            if (Object.hasOwn(tempEditing, key) && !ignoredKeys.includes(key)) {
                const value = tempEditing[key];
                if (
                    value === null ||
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === 'object' && Object.keys(value).length === 0)
                ) {
                    return false;
                }
            }
        }

        if (Array.isArray(tempEditing.customerInvoiceItems) && tempEditing.customerInvoiceItems.length === 0) {
            return false;
        }

        return true;
    }

    create(statusOverride, validate) {

        if (!this.state.creating) return
        let tempEditing = this.state.createInvoice
        if(this.isValid(tempEditing)) {
            let newFiles = this.state.newFiles
            for (let i = 0; i < tempEditing.customerInvoiceItems.length; ++i) {
                for (const itemCell of INVOICE_ITEM_CELLS) {
                    if (itemCell.id !== "shortcode" && !tempEditing.customerInvoiceItems[i][itemCell.id]) {
                        this.setState({itemError: {index: i, id: itemCell.id}})
                        return
                    }
                    if (itemCell.addDollar) tempEditing.customerInvoiceItems[i][itemCell.id] = tempEditing.customerInvoiceItems[i][itemCell.id].replace(",", "")
                }
            }
            tempEditing.invoiceSubTotal = tempEditing.invoiceSubTotal.replace(",", "")
            tempEditing.invoiceTax = tempEditing.invoiceTax.replace(",", "")
            tempEditing.invoiceTotal = tempEditing.invoiceTotal.replace(",", "")

            this.props.createCustomerInvoice({
                ...tempEditing,
                priceCheck: true,
                statusOverride: statusOverride,
                newFiles: newFiles,
                isSameAsBillingAddress: true
            }, validate, () => {
                // Success callback
                this.setState({creating: false});
            }, () => {
                // Error callback
                alert("Something went wrong");
            });

            if (this.props.status.success) {
                this.setState({feedbackBarOpen: true, feedbackBarStatus: true, newFiles: []})
            } else {
                this.setState({feedbackBarOpen: false, feedbackBarStatus: false})
            }
        }
    }

    save(statusOverride, validate) {
        if (!this.state.editing) return
        let tempEditing = this.state.editing
        let newFiles = this.state.newFiles
        for (let i = 0; i < tempEditing.customerInvoiceItems.length; ++i) {
            for (const itemCell of INVOICE_ITEM_CELLS) {
                if (itemCell.id !== "shortcode" && !tempEditing.customerInvoiceItems[i][itemCell.id]) {
                    this.setState({ itemError: { index: i, id: itemCell.id } })
                    return
                }
                if (itemCell.addDollar) tempEditing.customerInvoiceItems[i][itemCell.id] = tempEditing.customerInvoiceItems[i][itemCell.id].replace(",", "")
            }
        }


        tempEditing.invoiceSubTotal = tempEditing.invoiceSubTotal.replace(",", "")
        tempEditing.invoiceTax = tempEditing.invoiceTax.replace(",", "")
        tempEditing.invoiceTotal = tempEditing.invoiceTotal.replace(",", "")

        this.props.updateCustomerInvoice({ ...tempEditing, priceCheck: true, statusOverride: statusOverride, newFiles: newFiles }, validate, () => {
            //this.setState({ editing: false });
        }, () => {
            // Error callback
            alert("Something went wrong");
        });



        if(this.props.status.success){
            let tempFiles = Array.isArray(this.state.editing.files) ? [...this.state.editing.files] : []
            this.state.newFiles.forEach((file) => {
                tempFiles.push(file)
            })
            this.setState(prevState => ({
                editing: {
                    ...prevState.editing, // preserve all the existing properties of editing
                    files: tempFiles, // overwrite files with tempFiles
                },
            }));
            this.setState({ feedbackBarOpen:true,feedbackBarStatus: true, newFiles: [] })
        }
        else{
            this.setState({ feedbackBarOpen:false,feedbackBarStatus: false })
        }
    }

    fileUpload(e) {
        if (e.target.files.length === 0) return
        let file = e.target.files[0]
        let fileReader = new FileReader();

        let storage = localStorage.getItem("customerInvoices")
        let fromDate
        let toDate
        if (storage) {
            let storageObj = JSON.parse(storage);
            fromDate = storageObj?.fromDate ?? this.state.fromDate
            toDate = storageObj?.toDate ?? this.state.toDate
        }
        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
            // this.props.processCustomerInvoice(Array.from(contents), (invoice) => this.setEditing(invoice),
            //     fromDate, toDate)
        }
        fileReader.readAsArrayBuffer(file)
    }
    previewInvoice() {
        if (!this.state.editing?.invoiceNumber || !this.state.editing?.customerName) return
        let tempEditing = this.state.editing
        for (let i = 0; i < tempEditing.customerInvoiceItems.length; ++i) {
            for (const itemCell of INVOICE_ITEM_CELLS) {
                if (itemCell.id !== "shortcode" && !tempEditing.customerInvoiceItems[i][itemCell.id]) {
                    this.setState({ itemError: { index: i, id: itemCell.id } })
                    return
                }
                if (itemCell.addDollar) tempEditing.customerInvoiceItems[i][itemCell.id] = tempEditing.customerInvoiceItems[i][itemCell.id].replace(",", "")
            }
        }
        tempEditing.invoiceSubTotal = tempEditing.invoiceSubTotal.replace(",", "")
        tempEditing.invoiceTax = tempEditing.invoiceTax.replace(",", "")
        tempEditing.invoiceTotal = tempEditing.invoiceTotal.replace(",", "")


        this.props.storeCustomerInvoicePDF([])

       this.props.getCustomerInvoicePDF({...tempEditing})
       this.setState({ viewing: true })
    }
    handleAttachmentPreview = (file, index) => {
        this.props.getCustomerInvoiceAttachment({
            path: file.path
        })
        this.setState({ viewing: true, viewingFile: file, type: "attachment" })
    }
    download = () => {
        let blob = new Blob([Uint8Array.from(this.props.invoicePDF)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.viewingFile.displayName ? this.state.viewingFile.displayName : this.state.editing.invoiceNumber
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    }

    downloadAttachment = () => {
        let blob = new Blob([Uint8Array.from(this.props.supplierInvoicePDF)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.viewingFile.displayName ? this.state.viewingFile.displayName : this.state.editing.invoiceNumber
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    }

    deleteAttachment = () => {
        this.props.deleteCustomerInvoiceAttachment({
            displayName: this.state.viewingFile.displayName,
            path: this.state.viewingFile.path,
            customerInvoiceId:this.state.editing.customerInvoiceId
        },this.updateInvoice)
        this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false, type: null })
    }
    updateInvoice = (invoice) => {
        this.setState({
            editing: invoice
        })
    }
    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }
    stopCreating() {
        this.setState({ creating: false })
    }
    stopEditing() {
        this.setState({ editing: false, invoicePDF: new Uint8Array(0) })
    }
    zoomIn() {
        this.setState({ scale: Math.min(2, this.state.scale + 0.25) })
    }
    zoomOut() {
        this.setState({ scale: Math.max(1, this.state.scale - 0.25) })
    }
    changeStatus(status) {
        let customerInvoiceIds = []
        Object.keys(this.state.selectedEntries).map(key => {
            if (this.state.selectedEntries[key]) customerInvoiceIds.push(key)
        })
        this.updateStatus(customerInvoiceIds, status, () => window.location.reload())
    }
    updateStatus(customerInvoiceIds, status, callback) {
        this.props.updateCustomerInvoiceStatus({ customerInvoiceIds: customerInvoiceIds, status: status }, callback,
            this.state.fromDate, this.state.toDate)
        this.setState(prevState => ({
            invoicesList: prevState.invoicesList.map(
                invoice => customerInvoiceIds.includes(invoice.customerInvoiceId)
                    ? { ...invoice, status: status }
                    : invoice
            )
        }))
    }

    selectedReadyToSend() {
        for (const key in this.state.selectedEntries) {
            let invoice = this.props.allInvoices.find(invoice => invoice.customerInvoiceId === key)
            if (invoice?.status === READY_SEND_STATUS && this.state.selectedEntries[key]) return false
        }
        return true
    }

    selectedAreReadyToSend() {
        for (const key in this.state.selectedEntries) {
            let invoice = this.props.allInvoices.find(invoice => invoice.customerInvoiceId === key)
            if (invoice?.status !== READY_SEND_STATUS && this.state.selectedEntries[key]) return false
        }
        return true
    }
    selectedAreApproved() {
        for (const key in this.state.selectedEntries) {
            let invoice = this.props.allInvoices.find(invoice => invoice.customerInvoiceId === key)
            if (invoice?.status !== PAYMENT_PENDING_STATUS && this.state.selectedEntries[key]) return false
        }
        return true
    }
    overwriteInvoice() {
        this.props.overwritecustomerInvoice({ customerInvoiceId: this.state.editing.customerInvoiceId }, () => window.location.reload())
    }
    handleStatusClose() {
        this.props.clearStatus()
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }
    handleItemDelete = (index) => {
        let tempItems = [...this.state.editing.customerInvoiceItems]
        tempItems.splice(index, 1)
        this.setState({ editing: { ...this.state.editing, customerInvoiceItems: tempItems } })
    }
    addItem = () => {
        let newItem = { ...this.state.editing.customerInvoiceItems[0], customerInvoiceItemId: "" }
        INVOICE_ITEM_CELLS.map(cell => newItem[cell.id] = "")
        let tempItems = [...this.state.editing.customerInvoiceItems, newItem]
        this.setState({ editing: { ...this.state.editing, customerInvoiceItems: tempItems } })
    }

    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }

    handleGeneralFileDelete = (file, fileIndex) => {
        let newFiles = this.state.newFiles
        //let deletedFiles = this.state.deletedFiles
        let deletedFile = (newFiles.splice(fileIndex, 1))[0]
        /*let prevFiles = this.state.files
        let deletedFiles = this.state.deletedFiles
        let prevNewFiles = this.state.newFiles*/

        //files[index] = []
        let i = 0
        for (let file1 in newFiles) {
            if (file1 === file) {
                newFiles.splice(i, 1)
            }
        }
        //newFiles[index] = []
        //if (deletedFiles[index] && deletedFiles[index].length > 0) {
        //   deletedFiles[index].push(deletedFile)
        // }
        // else {
        //      deletedFiles[index] = [deletedFile]
        // }
        // if ("localURL" in file) {
        //      this.freeFromMemory(file.localURL)
        //  }

        this.setState({
            newFiles: newFiles,
            //deletedFiles: deletedFiles,
            //filesChanged: true
        })
    }

    invoiceSendCheck = () => {

        const templates = this.state.editing?.customer?.templates || [];
        const hasPurchaseOrderTemplate = templates.some(template => template.templateName === 'Customer Invoice');
        return !(hasPurchaseOrderTemplate);
    }

    closeFeedbackBar = () => {
        this.setState({
            feedbackBarOpen: false
        })
    }

    handleGeneralFileAdd = (file) => {
        let newFiles = this.state.newFiles
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files
        let newFile = this.getFormattedGeneralFileObject(file)
        if (newFiles && newFiles.length > 0) {
            newFiles.push(newFile)
        }
        else {
            newFiles = [newFile]
        }
        this.setState({
            newFiles: newFiles,
            //filesChanged: true
        });
    }

    render() {
        let headerButtons
        if (this.state.editing) {
            if (this.state.viewing && Object.keys(this.state.viewingFile).length === 0) {
                headerButtons = [{
                    icon: <KeyboardBackspaceIcon />,
                    handler: () => this.setState({ viewing: false, type: null }),
                    disabled: false,
                    title: "Back"
                },
                    {
                        icon: <GetApp />,
                        handler: () => this.download(),
                        title: "Download PDF" },
                    {
                        icon: <SendIcon />,
                        handler: () => {
                            this.props.sendInvoiceOrderEmail(this.state.editing.customerId,this.state.editing, () => {
                                this.changeStatus(PAYMENT_PENDING_STATUS)
                                window.location.reload()
                            })
                        },
                        disabled: this.invoiceSendCheck(),
                        title: "Save and Send" },
                { icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out", disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                {
                    icon: <ZoomInIcon />,
                    handler: () => this.setZoom(0.25),
                    title: "Zoom In",
                    disabled: false
                }]
            }
            else if (this.state.viewing && !this.state.deletingAttachment) {
                headerButtons = [
                    {
                    icon: <KeyboardBackspaceIcon />,
                    handler: () =>
                    {
                        this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false, type: null })
                    },
                    disabled: false,
                    title: "Back"
                },
                { icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out",
                    disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                { icon: <ZoomInIcon />,
                    handler: () => this.setZoom(0.25),
                    title: "Zoom In",
                    disabled: false
                },
                {
                    icon: <GetApp />,
                    handler: () => this.downloadAttachment(),
                    title: "Download PDF" },
                    {
                    icon: <DeleteIcon />,
                   handler: () => this.setState({ deletingAttachment: true }),
                   disabled: false,
                   title: "Delete"
                }]
            }
            else if (this.state.viewing && this.state.deletingAttachment) {
                headerButtons = [{
                    icon: <KeyboardBackspaceIcon/>,
                    handler: () => {
                        this.setState({viewing: false, viewingFile: {}, deletingAttachment: false, type: null})
                    },
                    disabled: false,
                    title: "Back"
                },
                {
                    icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out",
                    disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                {
                    icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title:
                        "Zoom In",
                    disabled: false
                },
                {
                    icon: <ClearIcon />,
                    handler: () => this.setState({ deletingAttachment: false }),
                    disabled: false,
                    title: "Cancel"
                    },
                {
                    icon: <DeleteIcon />,
                    handler: this.deleteAttachment,
                    disabled: false,
                    title: "Confirm Delete"
                }]
            }
            else {
                headerButtons = [
                    { icon: <KeyboardBackspaceIcon />, handler: () => this.stopEditing(), disabled: false, title: "Back" },
                {
                    icon: <DescriptionIcon />,
                    handler: this.previewInvoice,
                    disabled: false,
                    title: "View Invoice"
                },
                    {
                        icon: <></>,
                        handler: () => { },
                        disabled: false,
                        title: "",
                        divider: <span style={{ marginTop: '10px' }}>|</span>
                    },
                    {
                        icon: <SaveIcon />,
                        handler: () => this.save(false, false),
                        disabled: false,
                        title: "Save"
                    },
                    {
                        icon: <CheckCircleIcon />,
                        handler: () => this.save(false, true),
                        disabled: false,
                        title: "Save and Validate"
                    },        {
                        icon: <></>,
                        handler: () => { },
                        disabled: false,
                        title: "",
                        divider: <span style={{ marginTop: '10px' }}>|</span>
                    },
                    {
                        icon: <RateReviewIcon />,
                        handler: () => this.updateStatus([this.state.editing.customerInvoiceId], NEEDS_REVIEW_STATUS, (invoice) => this.setEditing(invoice)),
                        disabled: this.state.editing.status === NEEDS_REVIEW_STATUS,
                        title: "Mark for Review"
                    },
                    {
                        icon: <CheckIcon />,
                        handler: () => this.updateStatus([this.state.editing.customerInvoiceId], READY_SEND_STATUS, (invoice) => this.setEditing(invoice)),
                        disabled: !this.selectedReadyToSend(),
                        title: "Ready to Send"
                    },
                    {
                        icon: <PendingActionsIcon />,
                        handler: () => this.updateStatus([this.state.editing.customerInvoiceId], PAYMENT_PENDING_STATUS, (invoice) => this.setEditing(invoice)),
                        // disabled: this.state.editing.status === NEEDS_REVIEW_STATUS,
                        disabled: !this.selectedAreReadyToSend(),
                        title: "Payment Pending"
                    },
                    {
                        icon: <AttachMoneyIcon />,
                        handler: () => this.updateStatus([this.state.editing.customerInvoiceId], PAID_STATUS, (invoice) => this.setEditing(invoice)),
                        disabled: !this.selectedAreApproved(),
                        title: "Mark as Paid"
                    },

                    {
                        icon: <CreditCardIcon/>,
                        disabled: false,
                        title: "Add Payment Method",
                        popper: <PaymentPopper
                            selectedInvoiceIds={[this.state.editing.customerInvoiceId]}
                            invoiceType={INVOICE_TYPE_CUSTOMER_INVOICE}
                        />
                    }
                ]
            }
        } else {

            if (this.state.creating) {
                // Header buttons for creating state
                headerButtons = [
                    { icon: <KeyboardBackspaceIcon />, handler: () => this.stopCreating(), disabled: false, title: "Back" },
                   { icon: <SaveIcon />,
                       handler: () => this.create(false, true),
                       disabled: !this.isValid(this.state.createInvoice),
                       title: "Save" },
                ];
                }else if (this.state.selecting) {
                headerButtons = [{
                    icon: <RateReviewIcon />,
                    handler: () => this.changeStatus(NEEDS_REVIEW_STATUS),
                    disabled: false,
                    title: "Mark for Review"
                },
                {
                    icon: <CheckIcon />,
                    handler: () => {this.changeStatus(READY_SEND_STATUS)},
                    disabled: !this.selectedReadyToSend(),
                    title: "Ready to Send"
                },
                    {
                        icon: <PendingActionsIcon />,
                        handler: () => this.changeStatus(PAYMENT_PENDING_STATUS),
                        disabled: !this.selectedAreReadyToSend(),
                        title: "Payment Pending"
                    },
                    {
                        icon: <AttachMoneyIcon />,
                        handler: () => this.changeStatus(PAID_STATUS),
                        disabled: !this.selectedAreApproved(),
                        title: "Mark as Paid"
                    },
                    {
                        icon: <CreditCardIcon/>,
                        disabled: false,
                        title: "Add Payment Method",
                        popper: <PaymentPopper
                            selectedInvoiceIds={Object.keys(this.state.selectedEntries).filter(id => this.state.selectedEntries[id])}
                            invoiceType={INVOICE_TYPE_CUSTOMER_INVOICE}
                        />
                    }
                ]
            }
            else if (this.props.user.routes.includes('invoice-templates')) {
                headerButtons = [
                    {
                        icon:  this.props.processing ? <HourglassEmptyTwoTone/> : <AddCircleIcon />,
                        handler: () => {
                            this.setState({ creating : true})
                        },
                        disabled: false,
                        title: "Add Invoice"
                    },
                ]
            }
            else {

            }
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ErrorFeedbackBar />

                <Paper className="paper">
                    <FeedbackBar
                        open={this.state.feedbackBarOpen}
                        severity={SUCCESS}
                        message={this.state.feedbackBarText}
                        handleClose={this.closeFeedbackBar}
                    />
                    <input type="file" ref={this.hiddenFileInput} accept="application/pdf" hidden onChange={this.fileUpload} />
                    {!this.state.openInvoiceTemplates ?
                        <CustomToolbar
                            title={ this.state.editing || this.state.creating ? "Customer Invoice" : "Customer Invoices" }
                            createTitle={"Process Customer Invoice"}
                            backTitle={""}
                            headerButtons={headerButtons}
                        />
                        :
                        null
                    }
                    {this.state.openInvoiceTemplates ?
                        null :
                        !this.state.editing && !this.state.creating &&
                        <CustomerInvoicesTable
                            data={this.state.invoicesList}
                            setEditing={this.setEditing}
                            deleteInvoice={this.props.deleteCustomerInvoice}
                            checkedEntries={this.state.selectedEntries}
                            setSelected={(newEntries, selecting) => this.setState({ selectedEntries: newEntries, selecting: selecting })}
                            handleSearchChange={this.handleSearchTextChange}
                            searchText={this.state.searchText}
                            page={this.state.page}
                            setPage={this.setPage}
                            rowsPerPage={this.state.rowsPerPage}
                            setRowsPerPage={this.setRowsPerPage}
                            tableSetting={this.props.tableSetting}
                            saveTableSetting={this.props.saveTableSetting}
                            customers={this.props.customers ? this.props.customers.map(s => s.name) : []}
                            fetchCustomerInvoices={this.props.getAllCustomerInvoices}
                            user={this.props.user}
                            handleDateChange={this.handleDateChange}
                            setFetchedList={this.setFetchedList}
                        />||
                        <CustomerEditInvoices
                            editing={this.state.editing}
                            viewing={this.state.viewing}
                            pdfData={this.state.invoicePDF}
                            type={this.state.type}
                            attachment={this.props.supplierInvoicePDF}
                            scale={this.state.scale}
                            addItem={this.addItem}
                            itemError={this.state.itemError}
                            handleItemDelete={this.handleItemDelete}
                            handleChange={(name, value) => this.changeEditing(name, value)}
                            handlePONumberChange={(value) => this.getPurchaseOrder(value)}
                            handleItemChange={(name, value, index) => this.changeEditingItem(name, value, index)}
                            handleBlurField={(name, value, index) => this.blurEditing(name, value, index)}
                            formatDollar={this.formatDollar}
                            newFiles={this.state.newFiles}
                            handleGeneralFileAdd={this.handleGeneralFileAdd}
                            handleGeneralFileDelete={this.handleGeneralFileDelete}
                            handleAttachmentPreview={this.handleAttachmentPreview}
                            user={this.props.user}
                            defaultTaxPercentage={this.props.purchaseOrder?.taxPercentage}
                        />
                    }
                    {this.state.creating &&
                        <CustomerCreateInvoices
                            customers={this.props.customers ?? []}
                            passStateToParent={this.createInvoice}
                            creating={this.state.creating}
                            viewing={this.state.viewing}
                            scale={this.state.scale}
                            addItem={this.addItem}
                            itemError={this.state.itemError}
                            handleItemDelete={this.handleItemDelete}
                            handleChange={(name, value) => this.changeEditing(name, value)}
                            handlePONumberChange={(value) => this.getPurchaseOrder(value)}
                            handleItemChange={(name, value, index) => this.changeEditingItem(name, value, index)}
                            handleBlurField={(name, value, index) => this.blurEditing(name, value, index)}
                            formatDollar={this.formatDollar}
                            newFiles={this.state.newFiles}
                            handleGeneralFileAdd={this.handleGeneralFileAdd}
                            handleGeneralFileDelete={this.handleGeneralFileDelete}
                            handleAttachmentPreview={this.handleAttachmentPreview}
                            bankAccounts={this.props.bankAccounts}
                            user={this.props.user}
                            defaultTaxPercentage={this.props.purchaseOrder?.taxPercentage}
                        />
                    }
                </Paper>
            </MuiPickersUtilsProvider>
        );


      
    }
}

const actionCreators = {
    listCustomers,
    getAllCustomerInvoices,
    storeAllCustomerInvoices,
    getCustomerPurchaseOrder,
    updateCustomerInvoice,
    createCustomerInvoice,
    deleteCustomerInvoice,
    updateCustomerInvoiceStatus,
    clearStatus,
    saveTableSetting,
    getTableSetting,
    getCustomerInvoiceAttachment,
    deleteCustomerInvoiceAttachment,
    updateCustomerInvoiceChanged,
    storeCustomerInvoiceOperationResponse,
    getCustomerInvoicePDF,
    storeCustomerInvoicePDF,
    sendInvoiceOrderEmail
}


const mapStateToProps = (state) => ({
    editingInvoiceChanged: customerInvoiceChangedSelector(state),
    allInvoices: allCustomerInvoicesSelector(state),
    operationResponse: customerInvoiceOperationResponseSelector(state),
    customers: customersSelector(state),
    invoicePDF: customerInvoicePDFSelector(state),
    purchaseOrder: customerInvoicePOSelector(state),
    status: statusSelector(state),
    tableSetting: tableSettingSelector(state),
    user: userInfoSelector(state),
    supplierInvoicePDF: supplierInvoicePDFSelector(state),
    processing: processingSelector(state),
});

CustomerInvoices.propTypes = {
    allInvoices: PropTypes.array,
    invoicePDF: PropTypes.object,
    customers: PropTypes.array
}

CustomerInvoices.defaultProps = {
    allInvoices: [],
    status: {},
    customers: [],
    invoicePDF: new Uint8Array(0),
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, CustomerInvoices);