
export const USER_MANAGEMENT_HEAD_CELLS = [
    { id: 'displayName', center: false, disablePadding: false, label: 'Name' },
    { id: 'username', center: false, disablePadding: false, label: 'Username' },
    { id: 'authority', center: false, disablePadding: false, label: 'Authority' },
    { id: '', center: false, disablePadding: false, label: '' },
]

export const DEFAULT_SORT_BY_HEAD_CELL = 'name';
export const MANAGE_USERS_ORDERBY = "username";
export const MANAGE_USERS_TITLE = 'Manage Users';

export const AUTHORITIES = [
    "ROLE_USER",
    "ROLE_ADMIN"
]

export const ROLE_DASHBOARD = "ROLE_DASHBOARD"
export const ROLE_SHIPMENTS = "ROLE_SHIPMENTS"
export const ROLE_PENDING_SHIPMENTS = "ROLE_PENDING_SHIPMENTS"
export const ROLE_CLONED_SHIPMENTS = "ROLE_CLONED_SHIPMENTS"
export const ROLE_RETURN_REDIRECT_SHIPMENTS = "ROLE_RETURN_/_REDIRECT_SHIPMENTS"
export const ROLE_SHIPPED_SHIPMENTS = "ROLE_SHIPPED_SHIPMENTS"
export const ROLE_CANCELLED_SHIPMENTS = "ROLE_CANCELLED_SHIPMENTS"
export const ROLE_CUSTOMER_SERVICE = "ROLE_CUSTOMER_SERVICE"
export const ROLE_TICKETS = "ROLE_TICKETS"
export const ROLE_RETURNS = "ROLE_RETURNS"
export const ROLE_SETTINGS = "ROLE_SETTINGS"
export const ROLE_LOCATIONS = "ROLE_LOCATIONS"
export const ROLE_PRODUCTS = "ROLE_PRODUCTS"
export const ROLE_SUPPLIERS = "ROLE_SUPPLIERS"
export const ROLE_CUSTOMERS = "ROLE_CUSTOMERS"
export const ROLE_MANAGE_USERS = "ROLE_MANAGE_USERS"
export const ROLE_CARRIERS = "ROLE_CARRIERS"
export const ROLE_HISTORICAL = "ROLE_HISTORICAL"
export const ROLE_IMPORT_SHIPRUSH = "ROLE_IMPORT_SHIPRUSH"
export const ROLE_IMPORT_FTL = "ROLE_IMPORT_FTL"
export const ROLE_IMPORT_CLAIMS = "ROLE_IMPORT_CLAIMS"
export const ROLE_MARKETPLACES= "ROLE_MARKETPLACES"
export const ROLE_PRINTERS = "ROLE_PRINTERS"
export const ROLE_TAGS = "ROLE_TAGS"
export const ROLE_CONTROL_PANEL = "ROLE_CONTROL_PANEL"
export const ROLE_REFRESH_ORDER = "ROLE_REFRESH_ORDER"
export const ROLE_RECREATE_SHIPMENT = "ROLE_RECREATE_SHIPMENT"
export const ROLE_ARCHIVE_LABEL = "ROLE_ARCHIVE_LABEL"
export const ROLE_FETCH_ORDER = "ROLE_FETCH_ORDER"
export const ROLE_INVOICES = "ROLE_INVOICES"
export const ROLE_SUPPLIER_INVOICES = "ROLE_SUPPLIER_INVOICES"
export const ROLE_SALES_PIPELINE = "ROLE_SALES_PIPELINE"
export const ROLE_CUSTOMER_ORDERS = "ROLE_CUSTOMER_ORDERS"
export const ROLE_CUSTOMER_INVOICES = "ROLE_CUSTOMER_INVOICES"

export const ROLE_CUSTOMER = "ROLE_CUSTOMER"

export const ROLE_CARRIER_INVOICES = "ROLE_CARRIER_INVOICES"
export const ROLE_INVOICE_TEMPLATES = "ROLE_INVOICE_TEMPLATES"
export const ROLE_CARRIER_INVOICES_EDIT = "ROLE_CARRIER_INVOICES_EDIT"
export const ROLE_LISTING = "ROLE_LISTING"
export const ROLE_BARCODES = "ROLE_BARCODES"
export const ROLE_PO_PRICING = "ROLE_PO_PRICING"
export const ROLE_FTL = "ROLE_FTL"
export const ROLE_FTL_APPROVAL = "ROLE_FTL_APPROVAL"
export const ROLE_CLAIMS = "ROLE_CLAIMS"
export const ROLE_PACKAGING = "ROLE_PACKAGING"
export const ROLE_PRODUCT_CATEGORY = "ROLE_PRODUCT_CATEGORY"
export const ROLE_EMAIL_TEMAPLATES = "ROLE_EMAIL_TEMAPLATES"
export const ROLE_ACCOUNTING = 'ROLE_ACCOUNTING'
export const ROLE_TRANSACTIONS = 'ROLE_TRANSACTIONS'
export const ROLE_CHEQUES = 'ROLE_CHEQUES'
export const ROLE_SHIPPING_SUMMARY = 'ROLE_SHIPPING_SUMMARY'
export const ROLE_PURCHASE_SUMMARY = 'ROLE_PURCHASE_SUMMARY'
export const ROLE_STATEMENT_TAXES = 'ROLE_STATEMENT_TAXES'
export const ROLE_LEDGERS = 'ROLE_LEDGERS'
export const ROLE_BANK_ACCOUNTS = 'ROLE_BANK_ACCOUNTS'
export const ROLE_PRICING_DASHBOARD = 'ROLE_PRICING_DASHBOARD'

export const ROLE_ADMIN = "ROLE_ADMIN"

export const PARENT_ROLE_MAP = {
    "ROLE_SHIPMENTS": [
        ROLE_PENDING_SHIPMENTS,
        ROLE_SHIPPED_SHIPMENTS,
        ROLE_CANCELLED_SHIPMENTS,
        ROLE_CLONED_SHIPMENTS,
        ROLE_RETURN_REDIRECT_SHIPMENTS
    ],
    "ROLE_CUSTOMER_SERVICE": [
        ROLE_TICKETS,
        ROLE_RETURNS
    ],
    "ROLE_SETTINGS": [
        ROLE_LOCATIONS,
        ROLE_PRODUCTS,
        ROLE_SUPPLIERS,
        ROLE_CUSTOMERS,
        ROLE_MANAGE_USERS,
        ROLE_CARRIERS,
        ROLE_HISTORICAL,
        ROLE_MARKETPLACES,
        ROLE_PRINTERS,
        ROLE_TAGS,
        ROLE_LISTING,
        ROLE_BARCODES,
        ROLE_PACKAGING,
        ROLE_PRODUCT_CATEGORY,
        ROLE_EMAIL_TEMAPLATES,
        ROLE_BANK_ACCOUNTS,
    ],
    "ROLE_CONTROL_PANEL": [
        ROLE_REFRESH_ORDER,
        ROLE_RECREATE_SHIPMENT,
        ROLE_ARCHIVE_LABEL,
        ROLE_FETCH_ORDER
    ],
    "ROLE_INVOICES": [
        ROLE_SUPPLIER_INVOICES,
        ROLE_CARRIER_INVOICES,
        ROLE_CARRIER_INVOICES_EDIT,
        ROLE_INVOICE_TEMPLATES,
    ],
    "ROLE_CUSTOMER": [
        ROLE_SALES_PIPELINE,
        ROLE_CUSTOMER_ORDERS,
        ROLE_CUSTOMER_INVOICES,
    ],
    ROLE_ACCOUNTING: [
        ROLE_TRANSACTIONS,
        ROLE_CHEQUES,
        ROLE_SHIPPING_SUMMARY,
        ROLE_PURCHASE_SUMMARY,
        ROLE_STATEMENT_TAXES,
        ROLE_LEDGERS
    ]
}

export const CHILD_ROLE_MAP = {
    "ROLE_PENDING_SHIPMENTS": [
        ROLE_SHIPMENTS
    ],
    "ROLE_SHIPPED_SHIPMENTS": [
        ROLE_SHIPMENTS
    ],
    "ROLE_CANCELLED_SHIPMENTS": [
        ROLE_SHIPMENTS
    ],
    "ROLE_CLONED_SHIPMENTS": [
        ROLE_SHIPMENTS
    ],
    "ROLE_RETURN_/_REDIRECT_SHIPMENTS": [
        ROLE_SHIPMENTS
    ],

    "ROLE_TICKETS": [
        ROLE_CUSTOMER_SERVICE
    ],
    "ROLE_RETURNS": [
        ROLE_CUSTOMER_SERVICE
    ],

    "ROLE_LOCATIONS": [
        ROLE_SETTINGS
    ],
    "ROLE_PRODUCTS": [
        ROLE_SETTINGS
    ],
    "ROLE_SUPPLIERS": [
        ROLE_SETTINGS
    ],
    "ROLE_MANAGE_USERS": [
        ROLE_SETTINGS
    ],
    "ROLE_CUSTOMERS": [
        ROLE_SETTINGS,
    ],
    "ROLE_CARRIERS": [
        ROLE_SETTINGS
    ],
    "ROLE_HISTORICAL": [
        ROLE_SETTINGS
    ],
    "ROLE_IMPORT_CLAIMS": [
        ROLE_IMPORT_CLAIMS
    ],
    "ROLE_IMPORT_SHIPRUSH": [
        ROLE_IMPORT_SHIPRUSH
    ],
    "ROLE_IMPORT_FTL": [
        ROLE_IMPORT_FTL
    ],
    "ROLE_BANK_ACCOUNTS": [
        ROLE_SETTINGS
    ],
    "ROLE_MARKETPLACES": [
        ROLE_SETTINGS
    ],
    "ROLE_PRINTERS": [
        ROLE_SETTINGS
    ],
    "ROLE_TAGS": [
        ROLE_SETTINGS
    ],
    "ROLE_EMAIL_TEMAPLATES": [
        ROLE_SETTINGS
    ],
    "ROLE_REFRESH_ORDER": [
        ROLE_CONTROL_PANEL,
    ],
    "ROLE_RECREATE_SHIPMENT": [
        ROLE_CONTROL_PANEL,
    ],
    "ROLE_ARCHIVE_LABEL": [
        ROLE_CONTROL_PANEL
    ],
    "ROLE_FETCH_ORDER": [
        ROLE_CONTROL_PANEL
    ],
    "ROLE_SUPPLIER_INVOICES": [
        ROLE_INVOICES,
    ],
    "ROLE_CARRIER_INVOICES": [
        ROLE_INVOICES,
    ],
    "ROLE_INVOICE_TEMPLATES": [
        ROLE_INVOICES,
    ],
    "ROLE_CARRIER_INVOICES_EDIT": [
        ROLE_INVOICES,
    ],
    "ROLE_SALES_PIPELINE": [
        ROLE_CUSTOMER,
    ],
    "ROLE_CUSTOMER_ORDERS": [
        ROLE_CUSTOMER
    ],
    "ROLE_CUSTOMER_INVOICES": [
        ROLE_CUSTOMER,
    ],
    ROLE_LISTING: [
        ROLE_SETTINGS
    ],
    ROLE_BARCODES: [
        ROLE_SETTINGS
    ],
    "ROLE_PACKAGING": [
        ROLE_SETTINGS
    ],
    "ROLE_PRODUCT_CATEGORY": [
        ROLE_SETTINGS
    ],
    ROLE_TRANSACTIONS: [
        ROLE_ACCOUNTING,
        ROLE_CHEQUES,
        ROLE_SHIPPING_SUMMARY,
        ROLE_PURCHASE_SUMMARY,
        ROLE_STATEMENT_TAXES,
        ROLE_LEDGERS
    ]
}

export const ROLE_DEPTH_AND_ORDER = {
    "ROLE_DASHBOARD": 0,
    "ROLE_PRICING_DASHBOARD":0,
    "ROLE_SHIPMENTS": 0,
    "ROLE_PENDING_SHIPMENTS": 1,
    "ROLE_CLONED_SHIPMENTS": 1,
    "ROLE_RETURN_/_REDIRECT_SHIPMENTS": 1,
    "ROLE_SHIPPED_SHIPMENTS": 1,
    "ROLE_CANCELLED_SHIPMENTS": 1,
    "ROLE_FTL": 0,
    "ROLE_FTL_APPROVAL": 1,
    "ROLE_PURCHASE_ORDERS": 0,
    "ROLE_PO_PRICING": 1,
    "ROLE_INVENTORY": 0,
    "ROLE_DAMAGES": 0,
    "ROLE_INVOICES": 0,
    "ROLE_SUPPLIER_INVOICES": 1,
    "ROLE_CARRIER_INVOICES": 1,
    "ROLE_CARRIER_INVOICES_EDIT": 2,
    "ROLE_INVOICE_TEMPLATES": 1,
    "ROLE_CUSTOMER": 0,
    "ROLE_SALES_PIPELINE": 1,
    "ROLE_CUSTOMER_ORDERS": 1,
    "ROLE_CUSTOMER_INVOICES": 1,
    "ROLE_CLAIMS": 0,
    "ROLE_ACCOUNTING": 0,
    "ROLE_TRANSACTIONS": 1,
    "ROLE_CHEQUES": 1,
    "ROLE_SHIPPING_SUMMARY": 1,
    "ROLE_PURCHASE_SUMMARY": 1,
    "ROLE_STATEMENT_TAXES": 1,
    "ROLE_LEDGERS": 1,
    "ROLE_CUSTOMER_SERVICE": 0,
    "ROLE_TICKETS": 1,
    "ROLE_RETURNS": 1,
    "ROLE_AUTOMATION_RULES": 0,
    "ROLE_AUTOMATION_SETTINGS": 1,
    "ROLE_SETTINGS": 0,
    "ROLE_COMPANY":1,
    "ROLE_LOCATIONS": 1,
    "ROLE_PRODUCT_CATEGORY": 1,
    "ROLE_PRODUCTS": 1,
    "ROLE_PACKAGING": 1,
    "ROLE_SUPPLIERS": 1,
    "ROLE_CARRIERS": 1,
    "ROLE_CUSTOMERS": 1,
    "ROLE_HISTORICAL": 0,
    "ROLE_IMPORT_CLAIMS": 1,
    "ROLE_IMPORT_FTL": 1,
    "ROLE_IMPORT_HISTORICAL": 1,
    "ROLE_BANK_ACCOUNTS": 1,
    "ROLE_MARKETPLACES": 1,
    "ROLE_LISTING": 1,
    "ROLE_BARCODES": 1,
    "ROLE_BARCODES_EDIT": 2,
    "ROLE_EMAIL_TEMAPLATES": 1,
    "ROLE_TAGS": 1,
    "ROLE_PRINTERS": 1,
    "ROLE_MANAGE_USERS": 1,
    "ROLE_CONTROL_PANEL": 0,
    "ROLE_ARCHIVE_LABEL": 1,
    "ROLE_FETCH_ORDER": 1,
    "ROLE_REFRESH_ORDER": 1,
    "ROLE_RECREATE_SHIPMENT": 1,
    "ROLE_FEEDS": 0,
    "ROLE_FEEDS_AMZ": 1
}

export const SELECT_SERVICES = "Select services for which the user has access";
export const SELECT_LOCATIONS = "Select locations for which the user has access";
export const PASSWORD_ACTION = 'Generate Password';
export const PASSWORD_SUCCESS_MESSAGE = "Password successfully generated and copied to clipboard!";
export const EDIT_MESSAGE = "Editing ";
export const SAVE_ACTION = 'Save';
export const SAVING = 'Saving Changes...';
export const SAVE_SUCCESS_MESSAGE = "User info updated successfully!";
export const ERROR_MESSAGE = 'Error occurred processing request. Please try again.';

export const ERROR = 'error';
export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';

export const REGISTER_USER = "Register";
export const CANCEL = 'Cancel';

export const ACTIONS = {
    ADD: {
        title: "New User",
        description: "Add a user to your team by entering their credentials. Make sure to save their initial password so they can access their account."
    }
}

export const ROWS_PER_PAGE_LABEL = "Users per page"
export const DEFAULT_ROWS_PER_PAGE = 50;

export const MANAGE_USERS_TOOLBAR_TITLE = "Users"
export const REGISTER_DIALOG_TITLE = "Register New User";
export const REGISTER_DIALOG_DESCRIPTION = "Fill out the information to add a user to your team. Their password will be copied to your clipboard once you create the account."

export const DEFAULT = "Default"

export const TIME_ZONE = [
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/East-Saskatchewan',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Pacific-New',
    'US/Samoa',
]