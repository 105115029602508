import React from 'react';

// redux
import PropTypes from "prop-types";
import {
    getAllCarrierInvoices,
    getCarrierInvoicePDF,
    updateCarrierInvoiceStatus,
    deleteCarrierInvoice,
    processCarrierInvoice,
    storeCarrierInvoicePDF,
    reprocessCarrierInvoice,
    setInvoiceItemSmartSearchDetails,
    clearCarrierInvoiceOperationResponse,
    processFTLCarrierInvoice,
    getFTLCarrierInvoices,
    saveCarrierInvoice,
    saveFTLCarrierInvoice,
    saveCarrierInvoicePaymentDetails
} from "../../../redux/actions/invoices";
import {
    allCarrierInvoicesSelector, carrierInvoiceOperationResponseSelector,
    carrierInvoicePDFSelector, ftlCarrierInvoicesSelector,
    invoiceItemSmartSearchDetailsSelector,
    invoiceSmartSearchRowsSelector,
    processingSelector
} from "../../../redux/selectors/invoices";

// Material UI
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {IconButton, Paper} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

// Custom Components
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import CarrierInvoicesTable from "./CarrierInvoicesTable"
import EditCarrierInvoiceItemDetails from "./EditCarrierInvoiceItemDetails"
import PDFViewer from "../../global/PDFViewer";
import {GetApp, HourglassEmptyTwoTone, PostAdd, Restore} from "@material-ui/icons";
import {automationInfoSelector, tableSettingSelector} from '../../../redux/selectors/account';
import { userInfoSelector } from '../../../redux/selectors/auth';
import { getTableSetting, saveTableSetting } from "../../../redux/actions/account";
import {bankAccountsSelector, carriersSelector} from '../../../redux/selectors/settings';
import {listAllBankAccounts, listCarriers, previewBankFile} from "../../../redux/actions/settings";
import ErrorFeedbackBar from '../../global/ErrorFeedbackBar';
import RateReviewIcon from "@material-ui/icons/RateReview";
import CheckIcon from "@material-ui/icons/Check";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

// Constants
import {
    APPROVE_STATUS,
    CARRIER_TYPES, LTL_FTL_CARRIER,
    NEEDS_REVIEW_STATUS,
    PAID_STATUS,
    REPROCESSING_STATUS,
    STANDARD_CARRIER
} from "./constants";
import {DEFAULT_PDF_ZOOM} from "../../global/constants";
import {DEFAULT_ROWS_PER_PAGE} from "../../returns/constants";
import SettingsIcon from "@material-ui/icons/Settings";
import CarrierInvoiceTemplates from "../../settings/InvoiceTemplates/carrierInvoiceTemplates/CarrierInvoiceTemplates";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentPopper from "../PaymentPopper";
import {INVOICE_TYPE_CARRIER, INVOICE_TYPE_FTL_CARRIER} from "../constants";
import {ERROR, SUCCESS} from "../../shipments/constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import FTLCarrierInvoiceTemplates from "../FTLCarrierInvoices/FTLCarrierInvoiceTemplates";
import FTLCarrierInvoiceTable from "../FTLCarrierInvoices/FTLCarrierInvoiceTable";
import {fetchFullTruckloadCarriers} from "../../../redux/actions/fullTruckloadCarriers";
import {fullTruckloadCarriersSelector} from "../../../redux/selectors/fullTruckloadCarrier";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SaveIcon from "@material-ui/icons/Save";
import format from "date-fns/format";
import PrintIcon from "@material-ui/icons/Print";
import CarrierChequeForm from "./CarrierChequeForm";
import {checkChequeNumber, listTransactionCategories, saveCheque} from "../../../redux/actions/accounting";
import {transactionCategoriesSelector} from "../../../redux/selectors/accounting";
import {getCompany} from "../../../redux/actions/automationRules";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";



class CarrierInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            viewing: {
                carrierName: "",
                invoiceNumber: "",
            },
            isCheque: false,
            selecting: false,
            selectedEntries: {},
            searchText: "",
            scale: DEFAULT_PDF_ZOOM,
            openInvoiceTemplates: false,
            editInvoiceEntry: false,
            editingInvoice:null,
            editingInvoiceNumber: null,
            editingInvoiceDetails: null,
            feedBackBarOpen: false,
            carrierType: JSON.parse(localStorage?.getItem("carrierType")) ?? CARRIER_TYPES[0],
            memoo: '',
            payToName: '',
            chequeNumber: '',
            paymentDate: null,
            amount: '',
            amountWords: '',
            category: '',
            bankAccount: null,
            companyAddress:[],
            transactionCategories:this.props.transactionCategories,
        }
        this.hiddenFileInput = React.createRef()
        this.fileUpload = this.fileUpload.bind(this)
        this.handleStatusClose = this.handleStatusClose.bind(this)
    }

    componentDidMount() {
        this.props.getAllCarrierInvoices({
            fromDate: this.getFromDate(),
            toDate: this.getToDate()
        })
        this.props.getTableSetting(this.props.user.userId)
        this.props.listCarriers({ id: this.props.user.company })
        this.props.fetchFullTruckloadCarriers()
        this.props.listAllBankAccounts()
        this.props.listTransactionCategories()
        this.props.getCompany()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {


        if ( this.props.responseMessage && prevProps.responseMessage !== this.props.responseMessage) {
            this.setState({
                responseMessage: this.props.responseMessage,
                feedBackBarOpen: true
            })
        }

        if(prevProps.automationSettings !== this.props.automationSettings) {
            this.setState({
                companyAddress: this.props?.automationSettings?.companyAddress === undefined ? [] : this.props?.automationSettings?.companyAddress
            });
        }

        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }
    }

    calculateTotalAmount = () => {

        const selectedInvoiceKeys = Object.keys(this.state.selectedEntries).filter(key => this.state.selectedEntries[key]);

        let selectedCarrierIds = [];
        let amount = 0;
        let payToName = '';
        let chequeNumber = '';
        let paymentDate = '';
        let memoo = '';
        let bankAccount = null;

        for (const key of selectedInvoiceKeys) {
            if(this.state.carrierType === "Standard Carrier"){
                const invoice = this.props.allInvoices.find(invoice => invoice.carrierInvoiceId === key);

                if (invoice) {
                    selectedCarrierIds.push(key)
                    amount += parseFloat(invoice.summaryCharges.total);
                    payToName = invoice.carrierName;
                    chequeNumber = '';
                    paymentDate = invoice.paymentDate;
                    bankAccount = null;
                    memoo += invoice.invoiceNumber + ", "
                }
            }else{
                const invoice = this.props.ftlCarrierInvoices.find(invoice => invoice.invoiceId === key);
                if (invoice) {
                    selectedCarrierIds.push(key)
                    amount += parseFloat(invoice.invoiceAmount)
                    payToName = invoice.carrierName;
                    chequeNumber = '';
                    paymentDate = invoice.paymentDate;
                    bankAccount = null;
                    memoo += invoice.invoiceNumber + ", "
                }
            }


        }

        return [amount.toFixed(2), payToName, chequeNumber,paymentDate,memoo, selectedCarrierIds, bankAccount];
    }
    disableChequePrintingButton = () => {
        if(this.state.carrierType === "Standard Carrier"){
            const selectedInvoiceKeys = Object.keys(this.state.selectedEntries).filter(key => this.state.selectedEntries[key]);
            const selectedInvoiceNames = {};
            const selectedInvoiceStatuses = {};

            for (const key of selectedInvoiceKeys) {
                const invoice = this.props.allInvoices.find(invoice => invoice.carrierInvoiceId === key);

                if (invoice) {
                    selectedInvoiceNames[key] = invoice.carrierName;
                    selectedInvoiceStatuses[key] = invoice.status;
                }
            }

            if (selectedInvoiceKeys.length === 1 && Object.values(selectedInvoiceStatuses)[0] === 'Approved for Payment') {
                return false;
            }

            const firstCarrierName = Object.values(selectedInvoiceNames)[0];
            const allMatch = Object.values(selectedInvoiceNames).every(name => name === firstCarrierName);
            const allApproved = Object.values(selectedInvoiceStatuses).every(status => status === 'Approved for Payment');
            return !allMatch || !allApproved;
        }else{


            const selectedInvoiceKeys = Object.keys(this.state.selectedEntries).filter(key => this.state.selectedEntries[key]);
            const selectedInvoiceNames = {};
            const selectedInvoiceStatuses = {};

            for (const key of selectedInvoiceKeys) {
                const invoice = this.props.ftlCarrierInvoices.find(invoice => invoice.invoiceId === key);

                if (invoice) {
                    selectedInvoiceNames[key] = invoice.carrierName;
                    selectedInvoiceStatuses[key] = invoice.status;
                }
            }

            if (selectedInvoiceKeys.length === 1 && Object.values(selectedInvoiceStatuses)[0] === 'Approved for Payment') {
                return false;
            }

            const firstCarrierName = Object.values(selectedInvoiceNames)[0];


            const allMatch = Object.values(selectedInvoiceNames).every(name => name === firstCarrierName);
            const allApproved = Object.values(selectedInvoiceStatuses).every(status => status === 'Approved for Payment');
            return !allMatch || !allApproved;
        }

    }

    openIsCheque = () => {
        this.setState({
            isCheque: true
        })
    }
    closeIsCheque = () => {
        this.setState({
            isCheque: false
        })
    }

    getFromDate = () => {
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    clearSelectedInvoices = () => {
        this.setState({
            selecting: false,
            selectedEntries: {},
        })
    }

    processInvoice = () => {
        this.hiddenFileInput.current.click()
    }

    openInvoiceTemplates() {
        this.setState({
            openInvoiceTemplates: true
        })
    }

    closeInvoiceTemplates = () => {
        this.setState({
            openInvoiceTemplates: false
        })
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    fileUpload = (e) => {
        if (e.target.files.length === 0) return
        let file = e.target.files[0]
        let fileReader = new FileReader();

        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
            switch (this.state.carrierType) {
                case STANDARD_CARRIER:
                    this.props.processCarrierInvoice({content: Array.from(contents)}, () => window.location.reload(), () => alert("Something went wrong"))
                    break
                case LTL_FTL_CARRIER:
                    this.props.processFTLCarrierInvoice({content: Array.from(contents)}, () => window.location.reload(), () => alert("Something went wrong"))
            }
        }
        fileReader.readAsArrayBuffer(file)
    }
    handleView = (invoice) => {
        let newViewing = { carrierId: invoice.carrierId, carrierName: invoice.carrierName, invoiceNumber: invoice.invoiceNumber, carrierType: this.state.carrierType }
        this.props.storeCarrierInvoicePDF([])
        this.props.getCarrierInvoicePDF(newViewing)
        this.setState({ viewing: newViewing })
    }
    download = () => {
        let blob = new Blob([Uint8Array.from(this.props.invoicePDF)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.viewing.carrierName + "_" + this.state.viewing.invoiceNumber + ".pdf"
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    }

    zoomIn() {
        this.setState({ scale: Math.min(2, this.state.scale + 0.25) })
    }
    zoomOut() {
        this.setState({ scale: Math.max(1, this.state.scale - 0.25) })
    }
    changeStatus(status) {
        let carrierInvoiceIds = []
        Object.keys(this.state.selectedEntries).map(key => {
            if (this.state.selectedEntries[key]) carrierInvoiceIds.push(key)
        })
        this.updateStatus(carrierInvoiceIds, status, () => window.location.reload())

        // when mark as paid, clear selected entries
        if (status === PAID_STATUS) {
            this.clearSelectedInvoices()
        }
    }
    updateStatus(carrierInvoiceIds, status, callback) {
        this.props.updateCarrierInvoiceStatus({ carrierInvoiceIds: carrierInvoiceIds, status: status, carrierType: this.state.carrierType })
    }
    // selectStatus = (e, invoice) => {
    //     this.props.updateCarrierInvoice({ carrierInvoiceId: invoice.carrierInvoiceId, status: e.target.value })
    // }
    selectedAreApproved() {
        for (const key in this.state.selectedEntries) {
            let invoice = this.props.allInvoices.find(invoice => invoice.carrierInvoiceId === key)
            if (invoice?.status !== APPROVE_STATUS && this.state.selectedEntries[key]) return false
        }
        return true
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    reprocessInvoice = (selectedEntriesId) => {
        this.props.reprocessCarrierInvoice(Object.keys(selectedEntriesId).filter(key => selectedEntriesId[key] === true), this.state.carrierType)
    }

    addInvoiceEntry = () => {
        this.props.setInvoiceItemSmartSearchDetails([])
        this.openEditInvoiceEntry()
    }

    openEditInvoiceEntry = (details = null) => {
        let invoiceNumber = details?.invoiceNumber ?? this.props.allInvoices.find(invoice => invoice.carrierInvoiceId === Object.keys(this.state.selectedEntries).find(key => this.state.selectedEntries[key])).invoiceNumber

        this.setState({
            editInvoiceEntry: true,
            editingInvoiceNumber: invoiceNumber,
            editingInvoiceDetails: details,
        })
    }

    changeCarrierType = (type) => {
        localStorage.setItem("carrierType",JSON.stringify(type))
        this.setState({
            carrierType: type
        })
    }

    closeEditInvoiceEntry = () => {
        this.setState({
            editInvoiceEntry: false,
            editingInvoiceNumber: null,
            editingInvoiceDetails: null,
        })
    }

    closeFeedbackBar = () => {
        this.setState({
            feedBackBarOpen: false,
            responseMessage: ""
        })
        this.props.clearCarrierInvoiceOperationResponse()
    }

    displayCarrierInvoiceTemplates = (type) => {
        switch (type ) {
            case STANDARD_CARRIER:
                return (
                    <CarrierInvoiceTemplates
                        openInvoiceTemplates={this.openInvoiceTemplates}
                        closeInvoiceTemplates={this.closeInvoiceTemplates}
                    />
                )
            case LTL_FTL_CARRIER:
                return (
                    <FTLCarrierInvoiceTemplates
                        openInvoiceTemplates={this.openInvoiceTemplates}
                        closeInvoiceTemplates={this.closeInvoiceTemplates}
                    />
                )
        }
    }

    setEditing = (editingInvoice) => {
        this.setState({
            editing: true,
            editingInvoice: editingInvoice,
        })
    }

    formatDate = (date) => {
        try {
            return format(date, 'yyyy-MM-dd');
        }
        catch (e) {
            return date
        }
    }

    editSelectedInvoice = (field, value) => {
        let invoiceCopy = {...this.state.editingInvoice}
        if (field == "paymentDate") {
            invoiceCopy[field] = this.formatDate(value)
        }
        else if (field == "invoiceDate") {
            invoiceCopy[field] = this.formatDate(value)
        }
        else {
            invoiceCopy[field] = value
        }
        this.setState({
            editingInvoice: invoiceCopy
        })
    }

    save = () => {
        switch(this.state.carrierType) {
            case STANDARD_CARRIER:
                this.props.saveCarrierInvoice(this.state.editingInvoice)
                break;
            case LTL_FTL_CARRIER:
                this.props.saveFTLCarrierInvoice(this.state.editingInvoice)
                break;
        }
    }

    handleStatusClose() {
        this.props.clearStatus()
    }

    displayInvoiceTableAndForm = (type, headerButtons) => {
        switch (type ) {
            case STANDARD_CARRIER:
                return (
                    <Paper className="paper">
                        <input type="file" accept="application/pdf" ref={this.hiddenFileInput} hidden onChange={this.fileUpload} />

                        {!this.state.openInvoiceTemplates &&  !this.state.isCheque && !this.state.editInvoiceEntry ?
                            <CustomToolbar
                                title="Carrier Invoices"
                                createTitle="Process Carrier Invoice"
                                backTitle={""}
                                headerButtons={headerButtons}
                                zoomIn={() => this.zoomIn()}
                                zoomOut={() => this.zoomOut()}
                                scale={this.state.scale}
                                addZoom={this.state.viewing.invoiceNumber && this.state.viewing.carrierName}
                            />
                            :
                            null
                        }

                        {
                            this.state.openInvoiceTemplates ? (
                                this.displayCarrierInvoiceTemplates(this.state.carrierType)
                            ) : this.state.editInvoiceEntry ? (
                                <EditCarrierInvoiceItemDetails
                                    invoiceItemSmartSearchDetails={this.state.editingInvoiceDetails}
                                    invoiceNumber={this.state.editingInvoiceNumber}
                                    closeEditInvoiceEntry={this.closeEditInvoiceEntry}
                                />
                            ) : this.state.isCheque ? (
                                <CarrierChequeForm
                                    carrierType={this.state.carrierType}
                                    companyAddress={this.state.companyAddress}
                                    saveCarrierInvoicePaymentDetails={this.props.saveCarrierInvoicePaymentDetails}
                                    saveCheque={this.props.saveCheque}
                                    previewBankFile={this.props.previewBankFile}
                                    updateCarrierInvoiceStatus={this.props.updateCarrierInvoiceStatus}
                                    memoo={this.state.memoo}
                                    payToName={this.state.payToName}
                                    chequeNumber={this.state.chequeNumber}
                                    bankAccount={this.state.bankAccount}
                                    paymentDate={this.state.paymentDate}
                                    amount={this.state.amount}
                                    carrierIds={this.state.carrierIds}
                                    closeIsCheque={this.closeIsCheque}
                                    closeInvoiceTemplates={this.closeInvoiceTemplates}
                                    transactionsCategories={this.state.transactionCategories}
                                    clearStatus={this.props.clearStatus}
                                    checkChequeNumber={this.props.checkChequeNumber}
                                />
                            ) : (
                                <CarrierInvoicesTable
                                    data={this.props.allInvoices}
                                    deleteInvoice={this.props.deleteCarrierInvoice}
                                    checkedEntries={this.state.selectedEntries}
                                    setSelected={(newEntries, selecting) =>
                                        this.setState({ selectedEntries: newEntries, selecting: selecting })
                                    }
                                    handleView={this.handleView}
                                    searchText={this.state.searchText}
                                    page={this.state.page}
                                    setPage={this.setPage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                    handleSearchChange={this.handleSearchTextChange}
                                    tableSetting={this.props.tableSetting}
                                    saveTableSetting={this.props.saveTableSetting}
                                    carriers={this.props.carriers}
                                    fetchCarrierInvoices={this.props.getAllCarrierInvoices}
                                    openEditInvoiceEntry={this.openEditInvoiceEntry}
                                    invoiceSmartSearchRows={this.props.invoiceSmartSearchRows}
                                    invoiceItemSmartSearchDetails={this.props.invoiceItemSmartSearchDetails}
                                    changeCarrierType={this.changeCarrierType}
                                    bankAccounts={this.props.bankAccounts}
                                    carrierType={this.state.carrierType}
                                    setEditing={this.setEditing}
                                    editing={this.state.editing}
                                    editingInvoice={this.state.editingInvoice}
                                    editSelectedInvoice={this.editSelectedInvoice}
                                    user={this.props.user}
                                    invoicePDF={this.props.invoicePDF}
                                    scale={this.state.scale}
                                    viewingPdf={this.state.viewing.invoiceNumber !== "" && this.state.viewing.carrierName !== ""}
                                    clearSelectedInvoices={this.clearSelectedInvoices}
                                />
                            )
                        }

                    </Paper>
                )
            case LTL_FTL_CARRIER:
                return (
                    <Paper className="paper">
                        <input type="file" accept="application/pdf" ref={this.hiddenFileInput} hidden onChange={this.fileUpload} />
                        {!this.state.openInvoiceTemplates &&  !this.state.isCheque && !this.state.editInvoiceEntry ?
                            <CustomToolbar
                                title="LTL/FTL Carrier Invoices"
                                createTitle="Process Carrier Invoice"
                                backTitle={""}
                                headerButtons={headerButtons}
                                zoomIn={() => this.zoomIn()}
                                zoomOut={() => this.zoomOut()}
                                scale={this.state.scale}
                                addZoom={this.state.viewing.invoiceNumber && this.state.viewing.carrierName}
                            />
                            :
                            null
                        }


                        {this.state.openInvoiceTemplates ? (
                            this.displayCarrierInvoiceTemplates(this.state.carrierType)
                        ) : this.state.isCheque ? (
                                <CarrierChequeForm
                                    carrierType={this.state.carrierType}
                                    companyAddress={this.state.companyAddress}
                                    saveCarrierInvoicePaymentDetails={this.props.saveCarrierInvoicePaymentDetails}
                                    saveCheque={this.props.saveCheque}
                                    previewBankFile={this.props.previewBankFile}
                                    updateCarrierInvoiceStatus={this.props.updateCarrierInvoiceStatus}
                                    memoo={this.state.memoo}
                                    payToName={this.state.payToName}
                                    chequeNumber={this.state.chequeNumber}
                                    bankAccount={this.state.bankAccount}
                                    paymentDate={this.state.paymentDate}
                                    amount={this.state.amount}
                                    carrierIds={this.state.carrierIds}
                                    closeIsCheque={this.closeIsCheque}
                                    closeInvoiceTemplates={this.closeInvoiceTemplates}
                                    transactionsCategories={this.state.transactionCategories}
                                    clearStatus={this.props.clearStatus}
                                    checkChequeNumber={this.props.checkChequeNumber}
                                />
                            ) :
                            <FTLCarrierInvoiceTable
                                setEditing={this.setEditing}
                                data={this.props.ftlCarrierInvoices}
                                deleteInvoice={this.props.deleteCarrierInvoice} //TODO
                                checkedEntries={this.state.selectedEntries}
                                setSelected={(newEntries, selecting) => this.setState({
                                    selectedEntries: newEntries,
                                    selecting: selecting
                                })}
                                handleView={this.handleView}
                                searchText={this.state.searchText}
                                page={this.state.page}
                                setPage={this.setPage}
                                rowsPerPage={this.state.rowsPerPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleSearchChange={this.handleSearchTextChange}
                                tableSetting={this.props.tableSetting}
                                saveTableSetting={this.props.saveTableSetting}
                                carriers={this.props.ftlCarriers}
                                fetchCarrierInvoices={this.props.getFTLCarrierInvoices}
                                openEditInvoiceEntry={this.openEditInvoiceEntry}
                                invoiceSmartSearchRows={this.props.invoiceSmartSearchRows}
                                invoiceItemSmartSearchDetails={this.props.invoiceItemSmartSearchDetails}
                                changeCarrierType={this.changeCarrierType}
                                carrierType={this.state.carrierType}
                                user={this.props.user}
                                editing={this.state.editing}
                                editingInvoice={this.state.editingInvoice}
                                bankAccounts={this.props.bankAccounts}
                                editSelectedInvoice={this.editSelectedInvoice}
                                invoicePDF={this.props.invoicePDF}
                                scale={this.state.scale}
                                viewingPdf={this.state.viewing.invoiceNumber!=="" && this.state.viewing.carrierName!==""}
                            />
                        }
                                 {/*<PDFViewer data={this.props.invoicePDF} scale={this.state.scale} />*/}
                    </Paper>
                )
        }
    }

    render() {
        let headerButtons
        const selectedCount = Object.values(this.state.selectedEntries).filter(value => value).length
        if (!this.props.user.routes.includes('carrier-invoices-edit')) { // for view-only carrier invoice users
            headerButtons = []
        } else {
            if (this.state.viewing.invoiceNumber && this.state.viewing.carrierName) {
                headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: () => this.setState({ viewing: {
                            carrierName: "",
                            invoiceNumber: "",
                        }}), disabled: false, title: "Back" },
                    { icon: <GetApp />, handler: () => this.download(), disabled: this.props.invoicePDF.length === 0, title: "Download PDF" }]
            } else {
                if (this.state.editing) {
                    headerButtons = [
                        { icon: <KeyboardBackspaceIcon />, handler: () => this.setState({ editing: false }), disabled: false, title: "Back" },
                        { icon: <VisibilityIcon />, handler: () => this.handleView(this.state.editingInvoice), disabled: false, title: "View PDF" },
                        { icon: <SaveIcon />, handler: () => this.save(), disabled: false, title: "Save" },
                    ]
                }
                else if (this.state.selecting) {
                    headerButtons = [{
                        icon: <RateReviewIcon />,
                        handler: () => this.changeStatus(NEEDS_REVIEW_STATUS),
                        disabled: false,
                        title: "Mark for Review"
                    },
                        {
                            icon: <CheckIcon />,
                            handler: () => this.changeStatus(APPROVE_STATUS),
                            disabled: false,
                            title: "Approve for Payment"
                        },
                        {
                            icon: <AttachMoneyIcon />,
                            handler: () => this.changeStatus(PAID_STATUS),
                            disabled: !this.selectedAreApproved() && this.state.carrierType !== LTL_FTL_CARRIER,
                            title: "Mark as Paid"
                        },
                        {
                            icon: <PostAdd/>,
                            handler: () => this.addInvoiceEntry(),
                            disabled: selectedCount !== 1 ||this.state.carrierType == LTL_FTL_CARRIER,
                            title: "Add Invoice Entry"
                        },
                        {
                            icon: this.props.processing ? <HourglassEmptyTwoTone/> : <Restore />,
                            handler: () => this.reprocessInvoice(this.state.selectedEntries),
                            disabled: this.props.processing,
                            title: this.props.processing ? "Reprocessing Invoices..." : "Reprocess Invoice"
                        },
                        this.props.user.routes.includes('accounting') &&
                        {
                            icon: <CreditCardIcon/>,
                            disabled: this.props.bankAccounts.length < 1,
                            title: "Add Payment Method",
                            popper: <PaymentPopper
                                bankAccounts={this.props.bankAccounts}
                                selectedInvoiceIds={Object.keys(this.state.selectedEntries).filter(id => this.state.selectedEntries[id])}
                                invoiceType={this.state.carrierType === STANDARD_CARRIER ? INVOICE_TYPE_CARRIER : INVOICE_TYPE_FTL_CARRIER}
                            />
                        },
                         {
                            icon: <PrintIcon />,
                            handler: () => {
                                const [amount, payToName, chequeNumber,paymentDate,memoo,selectedCarrierIds, bankAccount] = this.calculateTotalAmount();
                                this.setState({
                                    memoo: memoo,
                                    payToName: payToName,
                                    chequeNumber: chequeNumber,
                                    bankAccount: bankAccount,
                                    paymentDate: paymentDate,
                                    amount: amount,
                                    carrierIds: selectedCarrierIds
                                })
                                this.openIsCheque()
                            },
                            disabled: this.disableChequePrintingButton(),
                            title: "Print Cheque"
                        }
                    ]
                }
                else if (this.props.user.routes.includes('invoice-templates')) {
                    headerButtons = [
                        {
                            icon: this.props.processing ? <HourglassEmptyTwoTone/> : <AddCircleIcon />,
                            handler: () => this.processInvoice(),
                            disabled: this.props.processing,
                            title: this.props.processing ? "Processing..." : "Process Invoice"
                        },
                        {
                            icon: <SettingsIcon />,
                            handler: () => this.openInvoiceTemplates(),
                            disabled: false,
                            title: "Carrier Invoice Templates"
                        },
                    ]
                }
                else {
                    headerButtons = [
                        {
                            icon: this.props.processing ? <HourglassEmptyTwoTone/> : <AddCircleIcon />,
                            handler: () => this.processInvoice(),
                            disabled: this.props.processing,
                            title: this.props.processing ? "Processing..." : "Process Invoice"
                        }
                    ]
                }
            }
        }

        return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ErrorFeedbackBar />
            <FeedbackBar
                open={this.state.feedBackBarOpen}
                severity={SUCCESS}
                message={this.props.responseMessage}
                handleClose={()=>this.closeFeedbackBar()}
            />
            {this.displayInvoiceTableAndForm(this.state.carrierType, headerButtons)}
        </MuiPickersUtilsProvider>)
    }
}

const actionCreators = {
    getAllCarrierInvoices,
    getCarrierInvoicePDF,
    storeCarrierInvoicePDF,
    updateCarrierInvoiceStatus,
    deleteCarrierInvoice,
    processCarrierInvoice,
    processFTLCarrierInvoice,
    saveTableSetting,
    getTableSetting,
    listCarriers,
    fetchFullTruckloadCarriers,
    reprocessCarrierInvoice,
    setInvoiceItemSmartSearchDetails,
    listAllBankAccounts,
    clearCarrierInvoiceOperationResponse,
    getFTLCarrierInvoices,
    saveCarrierInvoice,
    saveFTLCarrierInvoice,
    saveCheque,
    previewBankFile,
    listTransactionCategories,
    saveCarrierInvoicePaymentDetails,
    getCompany,
    clearStatus,
    checkChequeNumber

}

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    allInvoices: allCarrierInvoicesSelector(state),
    invoicePDF: carrierInvoicePDFSelector(state),
    tableSetting: tableSettingSelector(state),
    user: userInfoSelector(state),
    carriers: carriersSelector(state),
    ftlCarriers: fullTruckloadCarriersSelector(state),
    processing: processingSelector(state),
    invoiceSmartSearchRows: invoiceSmartSearchRowsSelector(state),
    invoiceItemSmartSearchDetails: invoiceItemSmartSearchDetailsSelector(state),
    bankAccounts: bankAccountsSelector(state),
    responseMessage: carrierInvoiceOperationResponseSelector(state),
    ftlCarrierInvoices: ftlCarrierInvoicesSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
    automationSettings: automationInfoSelector(state),
})

CarrierInvoices.propTypes = {
    allInvoices: PropTypes.array,
    invoicePDF: PropTypes.object,
    processing: PropTypes.bool
}

CarrierInvoices.defaultProps = {
    allInvoices: [],
    invoicePDF: new Uint8Array(0),
    processing: false,
    status: {},
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, CarrierInvoices);