import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK,
    LEDGERS_PAGES,
    SAVE
} from "./constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import { FormControl, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuIcon from '@mui/icons-material/Menu';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";


class EditLedger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: this.props.templates || [],
            anchorEl: null,
            activeIndex: null,
            draggedIndex: null,
        };
    }


    componentDidMount() {

        if (this.props.ledger?.ledgerEntries && this.props.ledger.ledgerEntries.length > 0) {

            this.setState({
                templates: [...this.props.ledger.ledgerEntries],
            });
        }

        const data = {ledgerId: this.props.ledger.ledgerId, year: this.props.selectedYear}
        this.props.fetchTransactionsLedger(data)
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchTransactions !== this.props.fetchTransactions) {
            this.setState({
                templates: [...this.props.fetchTransactions],
            }, this.updateBalances);
        }
    }


    onDragStart = (index) => {
        this.setState({ draggedIndex: index });
    };

    onDragOver = (index) => {
        const { draggedIndex, templates } = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...templates];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({
            templates: updatedTemplates,
            draggedIndex: index,
        }, this.updateBalances);
    }

    onDragEnd = () => {
        this.setState({ draggedIndex: null })
    }

    getContextualMenuItems = () => {
        let menuItems = [];
        menuItems.push(
            {
                title: BACK,
                icon: <KeyboardBackspaceIcon />,
                onClick: () => {
                    this.props.setCurrentPage(LEDGERS_PAGES.TABLE, {});
                    },
            },
            {
                title: SAVE,
                icon: <SaveIcon />,
                onClick: () => this.handleSave(),
                disabled: this.isSaveDisabled(),
            },
        );

        return menuItems;
    };



    handleSave = () => {
        this.props.handleSave(this.state.templates,this.props.ledger.ledgerId);
    };

    isSaveDisabled = () => {
        return this.state.templates.some(template =>
            !template.description || !template.debit || !template.credit || !template.balance
        );
    };

    formatDollar = (amount, forceTwoDecimals) => {
        if (!amount) return
        let value
        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            // if the amount has more than two decimal places, limit it to two
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',', '')
                }
            } else {
                value = amount.replaceAll(',', '')
            }
        }
        return value
    }


    parseNumber = (value) => {
        return parseFloat(value.replace(/,/g, ''));
    }

    handleTemplateChange = (e, index) => {
        let { name, value } = e.target;
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,
            };

            return { templates: updatedTemplates };
        }, this.updateBalances);
    };

    removeTemplateRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.filter((_, curIndex) => curIndex !== index);
            return { templates: updatedTemplates };
        }, this.updateBalances);
    };

    addTemplateRow = () => {
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates, {
                date: new Date(Date.now()),
                description: "",
                debit: "0.00",
                credit: "0.00",
                balance: ""
            }];
            return { templates: updatedTemplates };
        }, this.updateBalances);
    };

    updateBalances = () => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.map((template, index) => {
                let previousBalance;

                if (index === 0) {
                    // Set the opening balance for the first entry
                    previousBalance = this.parseNumber(template.balance);
                } else {
                    previousBalance = this.parseNumber(prevState.templates[index - 1].balance);
                }

                let debit = template.debit ? this.parseNumber(template.debit) : 0;
                let credit = template.credit ? this.parseNumber(template.credit) : 0;

                let newBalance = previousBalance;

                if (index !== 0) { // Skip balance calculation for the opening balance
                    switch (this.props.ledger.type) {
                        case 'Asset':
                        case 'Expense':
                            newBalance += (debit - credit);
                            break;
                        case 'Revenue':
                        case 'Equity':
                        case 'Liability':
                            newBalance += (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                }

                template.balance = this.formatDollar(newBalance.toFixed(2));

                return template;
            });

            return { templates: updatedTemplates };
        });
    }


    handleBlur = (e, index) => {
        let { name, value } = e.target;
        if (name === 'debit' || name === 'credit' || name === 'balance') {
            value = this.formatDollar(value);
        }
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true),
            };
            return { templates: updatedTemplates };
        }, this.updateBalances);
    };

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={"Ledger - " + this.props.ledger.name}
                    menuItems={this.getContextualMenuItems()}
                />


                <div style={{marginTop:"24px", marginLeft:"24px"}}>
                    <FormControl variant="outlined" className="form-control-ledger">
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                            className="select-year-ledge"
                            labelId="year-label"
                            value={this.props.selectedYear}
                            onChange={this.props.handleYearChange}
                            label="Year"
                        >
                            {this.props.years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                    <div className="transaction-form-section">
                        {this.state.templates.map((template, index) => (
                            <div
                                className="transaction-form-details"
                                key={index}
                                draggable
                                onDragStart={() => this.onDragStart(index)}
                                onDragOver={() => this.onDragOver(index)}
                                onDragEnd={this.onDragEnd}
                                style={{ background: this.state.draggedIndex === index ? "#f0f0f0" : "white" }}
                            >
                                <div style={{ width: '4.5%', display: "flex" }}>
                                    <IconButton onClick={() => this.removeTemplateRow(index)}>
                                        <RemoveCircleIcon
                                            fontSize="small"
                                            color="error"
                                        />
                                    </IconButton>
                                </div>


                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="date"
                                        label="Date"
                                        value={template.date}
                                        onChange={(e, value) => this.handleTemplateChange({ target: { name: "date", value } }, index)}
                                        format="MM/dd/yyyy"
                                        variant="inline"
                                        inputVariant="outlined"
                                        style={{ width: '15%' }}
                                        autoOk
                                        required
                                    />
                                </MuiPickersUtilsProvider>

                                <TextField
                                    variant="outlined"
                                    name="description"
                                    label="Description"
                                    style={{ width: '25%' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={template.description || ""}
                                    onChange={(e) => this.handleTemplateChange({
                                        target: { name: "description", value: e.target.value },
                                    }, index)}
                                    required
                                    error={!template.description}
                                />

                                <TextField
                                    variant="outlined"
                                    name="debit"
                                    label="Debit"
                                    style={{ width: '15%' }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputProps: { style: { textAlign: 'right' } }
                                    }}
                                    value={this.formatDollar(template.debit)}
                                    onChange={(e) => this.handleTemplateChange({
                                        target: { name: "debit", value: this.formatDollar(e.target.value) },
                                    }, index)}
                                    onBlur={(e) => this.handleBlur(e, index)}
                                    required
                                    error={!template.debit }
                                />


                                <TextField
                                    variant="outlined"
                                    name="credit"
                                    label="Credit"
                                    style={{ width: '15%' }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputProps: { style: { textAlign: 'right' } }
                                    }}
                                    value={this.formatDollar(template.credit)}
                                    onChange={(e) => this.handleTemplateChange({
                                        target: { name: "credit", value: this.formatDollar(e.target.value) },
                                    }, index)}
                                    onBlur={(e) => this.handleBlur(e, index)}
                                    required
                                    error={!template.credit}
                                />

                                <TextField
                                    variant="outlined"
                                    name="balance"
                                    style={{ width: '15%' }}
                                    label="Balance"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputProps: { style: { textAlign: 'right' } }
                                    }}
                                    value={this.formatDollar(template.balance)}
                                    onChange={(e) => this.handleTemplateChange({
                                        target: { name: "balance", value: this.formatDollar(e.target.value) },
                                    }, index)}
                                    onBlur={(e) => this.handleBlur(e, index)}
                                    required
                                    error={!template.balance}
                                />

                                <MenuIcon
                                    style={{ cursor: 'grab', marginLeft: '8px', opacity: '0.6', alignSelf: 'center' }}
                                />
                            </div>
                        ))}
                        <div style={{ marginTop: '30px' }}>
                                                     <IconButton onClick={() => this.addTemplateRow()}>
                                                          <AddCircleIcon
                                                                 fontSize="small" color='secondary'
                                                             />
                                                         </IconButton>
                                                     </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EditLedger;

