import React, { useState, useEffect } from 'react';

// custom components
import SelectCarrier from "./SelectCarrier";
import CarrierForm from "./CarrierForm";
import AddressForm from "./AddressForm";
import AddressValidationForm from "./AddressValidationForm";
import CarrierSetup from "./CarrierSetup";

// material components
import {
    Stepper,
    Step,
    StepButton,
    Button
} from "@material-ui/core";

// constants
import {
    CARRIER_STEPPER_LABELS, LTL_FTL_CARRIER_STEPPER_LABELS, LTL_FTL_CARRIERS, STEP_CASE_FTL,
    STEP_CASE_STANDARD,
} from "./constants";
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";
import {addressValidationChange} from "../../../redux/actions/settings";
import withCarrier from "../../../withCarrier";
import {userInfoSelector} from "../../../redux/selectors/auth";
import {packagesSelector, tagsSelector} from "../../../redux/selectors/settings";
import {loadingSelector} from "../../../redux/selectors/global";
import {linkedShipmentSelector, ratesSelector} from "../../../redux/selectors/orders";
import AutomationSetting from "../../global/AutomationSetting";
import FullTruckloadCarriersForm from "../../fullTruckload/FullTruckloadPages/FullTruckloadCarriersForm";



function CarrierStepper(props) {
    const [state, setState] = useState({
        parentState: {},
        isStepDisabled: true,
    })

    useEffect(() => {
        setState({
            ...state,
            parentState: { ...props.parentState }
        })
    }, [props.parentState])

    const getStepContent = (activeStep) => {
        let STEPCASE = STEP_CASE_STANDARD
        if (props.isFTLCarrier)
            STEPCASE = STEP_CASE_FTL
        switch (activeStep) {
            case STEPCASE.SELECT_SETUP:
                return (
                    <CarrierSetup
                        handleChange={props.handleChange}
                        parentState={props.parentState}
                        errors={props.parentState.errors}
                        isEdit={props.isEdit}
                        marketplaces={props.marketplaces}
                    />
                )
            case STEPCASE.SELECT_CARRIER:
                if (props.isFTLCarrier) {
                    return (
                        <FullTruckloadCarriersForm
                            parentState={props.parentState}
                            handleChange={props.handleChange}
                        />
                    )
                }
                else {
                    return (
                        <SelectCarrier
                            handleNumberChange={props.handleNumberChange}
                            handleChange={props.handleChange}
                            handleHasBuyShippingAccount={props.handleHasBuyShippingAccount}
                            parentState={props.parentState}
                            errors={props.parentState.errors}
                            isEdit={props.isEdit}
                            handleAutocomplteChange={props.handleAutocomplteChange}
                        />
                    )
                }
            case STEPCASE.CARRIER_INFORMATION:
                return (
                    <CarrierForm
                        parentState={props.parentState}
                        handleChange={props.handleChange}
                        errors={props.parentState.errors}
                        isEdit={props.isEdit}
                    />
                )

            case STEPCASE.ADDRESS_INFORMATION:
                return (
                    <AddressForm
                        addresses={props.addresses}
                        handleAddressChange={props.handleAddressChange}
                        parentState={props.parentState}
                        handleChange={props.handleChange}
                        errors={props.parentState.errors}
                        isEdit={props.isEdit}
                        handleIsDefaultChange={props.handleIsDefaultChange}
                        handleEnabledChange={props.handleEnabledChange}
                        // handleAddressValidationChange={props.addressValidationChange}
                    />
                )
            case STEPCASE.ADDRESS_VALIDATION:
                return(
                    <AddressValidationForm
                        parentState={props.parentState}
                        handleChange={props.handleChange}
                        errors={props.parentState.errors}
                        isEdit={props.isEdit}
                        handleAddressValidationChange={props.handleAddressValidationChange}
                    />
                )

            case STEPCASE.AUTOMATION_SETTINGS:
                return(
                    <AutomationSetting
                        parentState={props.parentState}
                        handleChange={props.handleChange}
                        handleAutomationSettingChanges={props.handleAutomationSettingChanges}
                        isEdit={props.isEdit}
                    />
                )
        }
    }

    return (
        <>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {
                    !props.isFTLCarrier ? CARRIER_STEPPER_LABELS.map((step, index) => (
                        <Step key={step}>
                            <StepButton>{step}</StepButton>
                        </Step>
                    ))
                    :LTL_FTL_CARRIER_STEPPER_LABELS.map((step, index) => (
                            <Step key={step}>
                                <StepButton>{step}</StepButton>
                            </Step>
                        ))
                }
            </Stepper>
            <div>
                {getStepContent(props.activeStep)}
            </div>
        </>
    )
}

const actionCreators = {
    addressValidationChange,
}

CarrierStepper.propTypes = {
    handleChange: PropTypes.func,
    addresses: PropTypes.array,
    parentState: PropTypes.object,
    handleAddressChange: PropTypes.func,
    handleIsDefaultChange: PropTypes.func,
    handleAddressValidationChange: PropTypes.func,
    handleHasBuyShippingAccount: PropTypes.func,
    handleClose: PropTypes.func,
    activeStep: PropTypes.number,
    isEdit: PropTypes.bool,
}

CarrierStepper.defaultProps = {
    handleChange: () => { },
    addresses: [],
    parentState: {},
    handleIsDefaultChange: () => { },
    handleAddressValidationChange: () => { },
    handleClose: () => { },
    handleAddressChange: ()=>{},
    handleHasBuyShippingAccount: ()=>{},
    activeStep: 0,
    isEdit: false,
}

const mapStateToProps = () => ({

})

export default withCarrier({
    mapStateToProps,
    actionCreators
}, CarrierStepper);