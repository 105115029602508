import React, { useState, useEffect } from 'react';

// material components
import {
    FormControl,
    FormHelperText,
    Select,
    MenuItem, DialogContent, TextField
} from "@material-ui/core";

// constants
import {
    AVAILABLE_CARRIERS,
    ADD_CARRIER,
    DEFAULT_SERVICE, BUY_SHIPPING_SETUP, SERVICE,
} from "./constants";
import { CARRIER_SERVICE_OPTIONS } from "../../automationrules/ConditionFilters/constants"
import styles from "./CreateCarrierDialog.module.css";
import PropTypes from "prop-types";
import DeleteDialog from "./DeleteDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";

function SelectCarrier(props) {

    const filterCarrierService = () => {
        if (props.parentState && props.parentState.carrier && !(props.parentState.carrier === "Amazon")) {
            return CARRIER_SERVICE_OPTIONS.filter(
                service => service.startsWith(props.parentState.carrier)
            )
        } else {
            return CARRIER_SERVICE_OPTIONS
        }
    }

    const currency = ['CAD', 'USD']

    return (
        <div className={styles.rowSelects}>
            <div>
                <div className={styles.rowSpec}>
                    <Autocomplete
                        options={AVAILABLE_CARRIERS}
                        getOptionLabel={(carrier) => carrier}
                        name="carrier"
                        onChange={(e, value) => props.handleAutocomplteChange(value, "carrier")}
                        value={props.parentState.carrier}
                        className={styles.formInput}
                        disabled={props.isEdit}
                        error={props.parentState.error && (props.parentState.carrier === '' || props.parentState.carrier === undefined)}
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Carrier"
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="carrier"
                            />
                        }
                    />
                    {/* <FormControl error={props.errors.carrier} required className={styles.formSelect}>
                        <FormHelperText>{ADD_CARRIER}</FormHelperText>
                        <Select
                            variant="outlined"
                            name="carrier"
                            onChange={props.handleChange}
                            value={props.parentState.carrier}
                            className={styles.formInput}
                            disabled={props.isEdit}
                            error={props.parentState.error && (props.parentState.carrier === '' || props.parentState.carrier === undefined)}
                        >
                            {AVAILABLE_CARRIERS.map(carrier => (
                                <MenuItem value={carrier}>{carrier}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl> */}

                    {/* <FormControl error={props.errors.carrier} required className={styles.formSelect}>
                        <FormHelperText>{DEFAULT_SERVICE}</FormHelperText>
                        <Select
                            variant="outlined"
                            name="defaultService"
                            onChange={props.handleChange}
                            value={props.parentState.defaultService}
                            className={styles.formInput}
                        >
                            {filterCarrierService().map(service => (
                                <MenuItem value={service}>{service}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl> */}
                    <Autocomplete
                        options={filterCarrierService()}
                        getOptionLabel={(service) => service}
                        name="defaultService"
                        onChange={(e, value) => {
                            props.handleAutocomplteChange(value, "defaultService")
                        }}
                        value={props.parentState.defaultService}
                        className={styles.formInput}
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Default Service"
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="defaultService"
                            />
                        }
                    />
                </div>
                <div className={styles.rowSpec}>
                    <Autocomplete
                        options={currency}
                        getOptionLabel={(currency) => currency}
                        name="currencyCode"
                        onChange={(e, value) => {
                            props.handleAutocomplteChange(value, "currencyCode")
                        }}
                        value={props.parentState.currencyCode}
                        className={styles.formInput}
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Currency Code"
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="currencyCode"
                            />
                        }
                    />

                    <TextField
                        className={styles.formInput}
                        variant='outlined'
                        label="Net Terms"
                        InputLabelProps={{shrink: true}}
                        name='netTerm'
                        value={props.parentState.netTerm}
                        onChange={props.handleNumberChange}
                    />
                </div>
            </div>
        </div>
    )
}

SelectCarrier.propTypes = {
    handleChange: PropTypes.func,
    parentState: PropTypes.object,
    errors: PropTypes.array,
    isEdit: PropTypes.bool
}

SelectCarrier.defaultProps = {
    handleChange: () => { },
    parentState: {},
    errors: [],
    isEdit: false
}

export default SelectCarrier;