import React from "react";

import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {TableCell} from "@mui/material";
import {Checkbox, IconButton, Slide, TablePagination} from "@material-ui/core";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import EditIcon from "@material-ui/icons/Edit";
import {
    ADD_SALES_PIPELINE,
    ROWS_PER_PAGE_LABEL,
    SALES_PIPELINE,
    SALES_PIPELINE_HEAD_CELLS,
    SALES_PIPELINE_PAGES,
    SALES_STAGES_OPTIONS
} from "./constants";
import SettingsIcon from "@material-ui/icons/Settings";
import {IMPORT_PIPELINE, MANAGE_CATEGORY} from "./ManageCategory/constants";
import moment from "moment";
import PublishIcon from "@material-ui/icons/Publish";
import FilterTagsBar from "../../shipments/FilterTagsBar";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import Tooltip from "@material-ui/core/Tooltip";
import FilterIcon from "../../shipments/FilterIcon";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import {ASC, DESC} from "../../global/constants";
import SalesPipelineFiltersDialog from "./SalesPipelineFiltersDialog";
import SearchableTable from "../../global/Search/SearchableTable";
import {DEFAULT_SORT_BY_HEAD_CELL} from "../customerInvoices/constants";


class SalesPipelineTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            deleteId: "",
            selectedEntries: [],
            isSelecting: false,
            isSearch: false,
            searchValue: "",
            order: DESC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            shownIndex: -1,
            fromDate: this.props.fromDate,
            toDate: this.props.toDate,
            filtersMap: {},
            showFilters: false,
            filtersMapBeforeOpenDialog: {},
            deletedTagsWithCookies: [],
        }
        this.onSearchValueChange = this.onSearchValueChange.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleRequestSort = this.handleRequestSort.bind(this)
        this.getDisplayed = this.getDisplayed.bind(this)
        this.selectAll = this.selectAll.bind(this)
    }

    async componentDidMount() {
        let changeBack = false
        await this.setState({
            searchValue: this.props.searchText,
        })
        let storage = JSON.parse(localStorage.getItem("salesPipeline"))
        if (storage) {
            this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (changeBack) {
                localStorage.removeItem("salesPipeline")
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    filtersMap: {}
                })
            }
        }

        if(this.props.setFetchedList){
            this.props.fetchSalesPipeline({
                fromDate: this.state.fromDate,
                toDate:  this.state.toDate
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }

    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    onSearchValueChange(e) {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value
        });
        this.props.setPage(0)
        this.props.handleSearchChange(e)
    };

    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    getNewDates = () => {
        let filter = {...this.state.filtersMap}
        let storage = JSON.parse(localStorage?.getItem("salesPipeline"))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }

        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() === defaultFromDate.getDate() &&
            someDate.getMonth() === defaultFromDate.getMonth() &&
            someDate.getFullYear() === defaultFromDate.getFullYear()
    }


    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    addFilterDates = (filters) => {
        if (filters) {
            if (!this.isDefaultFromDate(this.state.fromDate)) {
                filters['fromDate'] = this.state.fromDate
            }
            if (!this.isToday(this.state.toDate)) {
                filters['toDate'] = this.state.toDate
            }
        }
        return filters
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };
    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };
    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };
    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, (this.props.page + 1) * this.props.rowsPerPage)];
    }

    handleCheck(e, pipeline) {
        let newEntries = { ...this.props.checkedEntries, [pipeline.salesPipelineId]: e.target.checked }
        this.props.setSelected(newEntries, !!this.numSelected(newEntries))
    }
    selectAll(e) {
        let newChecked = {}
        let allEntries = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        allEntries.map((entry) => newChecked[entry.salesPipelineId] = e.target.checked)
        this.props.setSelected(newChecked, !!this.numSelected(newChecked))
    }
    numSelected(checkedEntries) {
        let rows = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        return rows.reduceRight((accum, curr) => accum + (checkedEntries[curr.salesPipelineId] ?? 0), 0)
    }

    toggleFiltersDialog = () => {
        this.setState({
            showFilters: !this.state.showFilters,
            filtersMapBeforeOpenDialog: { ...this.state.filtersMap }
        })
    }


    onFiltersInputChange = (name, val) => {
        const filtersMap = { ...this.state.filtersMap };

        // if filter already exists, then update
        if (val !== "" && val.length !== 0) {
            filtersMap[name] = val;
        }
        else {
            delete filtersMap[name];
        }

        this.saveUpdatedStatus(filtersMap)

        this.setState({
            filtersMap: filtersMap,
        });

    }

    saveUpdatedStatus = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
    }

    handleDateChange = (date, key) => {
        const filtersMap = { ...this.state.filtersMap };
        if (key === 'fromDate' && !this.isDefaultFromDate(date) ||
            key === 'toDate' && !this.isToday(date)) {
            filtersMap[key] = date;
        } else {
            delete filtersMap[key]
        }
        this.setState({
            filtersMap: filtersMap,
            [key]: date
        })
        this.props.handleDateChange(date, key)
    }

    onFiltersClose = (applyFilters, saveFilterSetting) => {
        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its
        // previous state
        this.setState({
            showFilters: false,
        })
        let filters = {}
        if (!applyFilters) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetDateFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate(), filters)
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem("salesPipeline"))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem("salesPipeline", JSON.stringify(storage))
                if(JSON.stringify(storage) === JSON.stringify({})) localStorage.removeItem("salesPipeline")
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.filtersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            let needRefresh = this.props.fetchedList
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem("salesPipeline")
                existing = existing ? JSON.parse(existing) : {};
                if(existing.fromDate!==filters.fromDate.toISOString()) {
                    needRefresh = true
                }
                existing.fromDate = filters.fromDate;
                localStorage.setItem("salesPipeline", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem("salesPipeline")
                existing = existing ? JSON.parse(existing) : {};
                if(existing.toDate!==filters.toDate.toISOString()) {
                    needRefresh = true
                }
                existing.toDate = filters.toDate;
                localStorage.setItem("salesPipeline", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            //fetching all the sales pipelines
            if (this.props.data.length===0||needRefresh){
                this.fetchSalesPipeline(this.state.fromDate, this.state.toDate)
                this.props.setFetchedList(false)
            }
        }
        if (saveFilterSetting) {
            this.saveUpdatedStatus(filters)
        }
    }

    resetDateFilters = (fromDate, toDate, filters) => {
        if (!this.isDefaultFromDate(fromDate)) {
            filters['fromDate'] = fromDate
        } else {
            delete filters['fromDate']
            fromDate = this.getFromDate()
        }
        if (!this.isToday(toDate)) {
            filters['toDate'] = toDate
        } else {
            delete filters['toDate']
            toDate = this.getToDate()
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            filtersMap: filters,
            deletedTagsWithCookies: [],
        })
        this.props.handleDateChange(fromDate, 'fromDate')
        this.props.handleDateChange(toDate, "toDate")
    }

    onTagClickedWhenDialogueOpen = (event, tag) => {
        const filtersMap = { ...this.state.filtersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('salesPipeline'))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.setState({
                fromDate: this.getFromDate()
            })
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('salesPipeline'))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.setState({
                toDate: this.getToDate()
            })
        }
        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }



    onTagClicked = (event, tag) => {

        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = { ...this.state.filtersMap };
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('salesPipeline'))
            delete storage.fromDate
            this.setState({
                fromDate: this.getFromDate()
            }, () => this.fetchSalesPipeline(this.state.fromDate, this.state.toDate))
            localStorage.setItem("salesPipeline", JSON.stringify(storage))
            if(JSON.stringify(storage) === JSON.stringify({})) localStorage.removeItem("salesPipeline")
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('salesPipeline'))
            delete storage.toDate
            this.setState({
                toDate: this.getToDate()
            }, () => this.fetchSalesPipeline(this.state.fromDate, this.state.toDate))
            localStorage.setItem("salesPipeline", JSON.stringify(storage))
            if(JSON.stringify(storage) === JSON.stringify({})) localStorage.removeItem("salesPipeline")
        }

        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }

    fetchSalesPipeline = (fromDate, toDate) => {
        this.props.fetchSalesPipeline({
            fromDate: fromDate,
            toDate: toDate
        })
    }

    applyOverviewFilters = (rows) => {
        const selectedStages = this.state.filtersMap?.stage || []
        if (selectedStages.length > 0) {
            rows = rows.filter(row => {
                return selectedStages.includes(row.stage);
            })
        }
        return rows
    }



    removeStatusFilters = () => {
        let filters = { ...this.state.filtersMap }
        delete filters['selectedStatus']
        return filters
    }



    handleDeletePipeline = () => {
        let pipelineToDelete = []
        if (this.state.deleteId) {
            pipelineToDelete.push(this.state.deleteId)
        } else if (this.state.selectedEntries.length > 0) {
            pipelineToDelete.push(...this.state.selectedEntries)
        }

        this.props.deletePipeline({pipeline: pipelineToDelete,  fromDate: this.state.fromDate, toDate: this.state.toDate},
            () => {
                this.setState({
                    deleteId: "",
                    deleting: false,
                    isSelecting: false,
                    selectedEntries: [],
                })
            })

    }


    getContextualMenuItems = () => {
        let menuItems = []

            menuItems.push(
                {
                    title: ADD_SALES_PIPELINE,
                    icon: <AddCircleIcon/>,
                    onClick: () => this.props.setCurrentPage(SALES_PIPELINE_PAGES.ADD_SALES_PIPELINE, {})
                },
                {
                    title: MANAGE_CATEGORY,
                    icon: <SettingsIcon/>,
                    onClick: () => this.props.setCurrentPage(SALES_PIPELINE_PAGES.ADD_SALES_SETTING, {})
                },
                {
                    title: IMPORT_PIPELINE,
                    icon: <PublishIcon/>,
                    onClick: () => this.props.importPipeline()
                }
            )


        return menuItems
    }



    render() {
        let rows = this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={SALES_PIPELINE}
                    menuItems={this.getContextualMenuItems()}
                />

                <div className='filter-tag-wrapper'>
                    <FilterTagsBar
                        filtersMap={this.removeStatusFilters()}
                        onClick={this.state.showFilters ? this.onTagClickedWhenDialogueOpen : this.onTagClicked}
                    />
                </div>
                <CustomTableHeader
                    searchText={this.props.searchText}
                    onSearchValueChange={this.onSearchValueChange}
                    pagination={
                        <TablePagination
                            className="table-pagination"
                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.data.length, label: "All"}]}
                            count={!this.props.data ? 0 : this.filterBySearch(this.applyOverviewFilters(this.props.data)).length}
                            rowsPerPage={this.props.rowsPerPage}
                            page={this.props.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                    showFilters={this.state.showFilters}
                    filtersComponent={
                        <Slide in={this.state.showFilters} mountOnEnter unmountOnExit timeout={300}>
                            <SalesPipelineFiltersDialog
                                open={this.state.showFilters}
                                onClose={this.onFiltersClose}
                                onChange={this.onFiltersInputChange}
                                listItems={SALES_STAGES_OPTIONS ? SALES_STAGES_OPTIONS.map(stage => stage.name) : []}
                                handleDateChange={this.handleDateChange}
                                filtersMap={this.state.filtersMap}
                                clearFilters={this.clearFiltersMap}
                                onTagClicked={this.onTagClicked}
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                fieldName={'stage'}
                                fieldTitle={'Stage'}
                            />
                        </Slide>}

                    filterIcon={
                        <Tooltip title={'Filter'} style={{paddingTop: 10}}>
                            <IconButton className="filterButton" size='small' aria-label={'Filter'}
                                        onClick={this.toggleFiltersDialog}>
                                <FilterIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                />
                <TableContainer>
                    <Table size='medium'>
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            isPurchaseOrderTable={false}
                            headCells={SALES_PIPELINE_HEAD_CELLS}
                            isInvoiceTable={false}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((pipeline, index) => (
                                <TableRow hover onDoubleClick={() =>  this.props.setCurrentPage(SALES_PIPELINE_PAGES.ADD_SALES_PIPELINE, pipeline)} key={"pipeline-" + index}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() =>  this.props.setCurrentPage(SALES_PIPELINE_PAGES.ADD_SALES_PIPELINE, pipeline)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    {SALES_PIPELINE_HEAD_CELLS.slice(0,SALES_PIPELINE_HEAD_CELLS.length).map((headCell, index) => (
                                        <TableCell key={index}
                                                   style={{textAlign: "center", paddingRight:'50px'}}
                                        >
                                            {headCell.id === 'lastContactDate'
                                                ? moment(pipeline[headCell.id]).format('YYYY/MM/DD')
                                                : pipeline[headCell.id]
                                            }
                                        </TableCell>

                                    ))}


                                    <TableCell style={{padding: "5px", width: "205px"}}>
                                        {this.state.deleteId !== pipeline.salesPipelineId ?
                                            <IconButton
                                                onClick={() => this.setState({deleteId: pipeline.salesPipelineId})}>
                                                <DeleteIcon/>
                                            </IconButton> : <div>
                                                <Button onClick={() => this.setState({deleteId: ""})}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={this.handleDeletePipeline} variant="contained"
                                                        startIcon={<DeleteIcon/>}>
                                                    Delete
                                                </Button>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </React.Fragment>
        )
    }
}


export default SalesPipelineTable;