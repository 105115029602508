
// represents a page that displays the data provided to it in table form

/* Usage Instructions:
 *
 * place the following code in parent component's mapStateToProps:
   tableRows: [[your choice of selector]](state),
 *
 * place the following code where you want your table:
   <TablePage
       tableName= [[a unique string, used internally to identify this table's local storage]]
       tableRows={this.props.tableRows}
       tableColumnHeads={[
               [[an array of column heads, created through calls to Layout.columnHead(...)]]
           ]}
       tableRow={(row, index) => <React.Fragment>
               [[a series of <TableCell>s representing a row in your table]]
           </React.Fragment>}
   />
 *
 * add optional props as necessary
 */

/* Required Props:
 *
 * // this is a name for the table, used internally to identify this table's local storage
   tableName= [[a string unique among all TablePages in the program]]
 *
 * // this is an array of the rows that the table will display
   tableRows={this.props.tableRows}
 *
 * // this is an array of column heads that go across the top of the table
   tableColumnHeads={ [[an array of column heads, created through calls to Layout.columnHead(...)]] }
 * Note: table heads should be created through calls to Layout.columnHead(...) from LayoutObject.jsx
 * Note: the number of entries in the tableColumnHeads array should match the number of <TableCell>s in tableRow
 * Note: setting text and id to null when calling Layout.columnHead(...) disables sorting by that table head
 *
 * // this is a function that returns a series of TableCells in a <React.Fragment> it is used to create each row of the table
   tableRow={(row, index) => <React.Fragment> [[a series of <TableCell>s representing a row in your table]] </React.Fragment>}
 * Note: do not have the function return a TableRow. If you need to add an on-double-click to the row, use onRowDoubleClick below.
 * WARNING: if creating a component to hold the table's row, that component's state may not stay associated with the correct row,
 *      recommend instead using the state provided by getRowId below
 */

/* Optional Props:
 *
 * // providing this parameter enables pagination, the string itself is displayed next to the page count
   paginationText= [[String to show next to pagination]]
 *
 * // this sets the default column to sort the table by
   defaultSortBy= [[Sting id of column to sort by]]
 *
 * // this is a method which the table will call to fetch data to display
   rowFetcher={(filters) => { [[a function that fetches content for the table to display]] }}
 *  - for this to work, the method should populate the contents of the tableRows prop
 *  - this method is called once when the table loads (constructor) and once whenever refetchFilters changes
 *  - filters contains objects which can be accessed with filters[i].name and filters[i].value
 *
 * // a set of filters which, if changed, should trigger rowFetcher to be called
   refetchFilters={ [[ an array of strings that are filter names ]] }
 *
 * // enables a field to search the table by
 * // the search does nothing without at least one of: searchString, smartSearchRows, and smartSearches
   hasSearchField
 *
 * // with hasSearchField, enables search to show only rows in tableRows which match the contents of the search field
 * // the function provided should return an array that contains all the text in the row that should be searched
   searchStrings={(row) => [[an array of strings representing the row's contents]] }
 *
 * // the rows found through a smart search
 * // when a smart search is matched, these rows are displayed instead of the normal contents of the table, and ignore filters
 * // these would typically have been fetched from a server through a smartSearch
   smartSearchRows={ [[an array of table row data]] }
 *
 * // an array of smart searches created through calls to Search.newSmartSearch(...)
 * // when a regular expression provided is matched by the search field, the function provided is called,
 * // and the contents of martSearchRows is displayed
   smartSearches={ [[an array of smart searches created through calls to Search.newSmartSearch(...)]] }
 *
 * // tableEvents props, which facilitate providing instructions to the table
   eventsVariable={this.state.tableEvents}
   setEventsVariable={(newState) => {this.setState({tableEvents: newState})}}
 *  - currently only used to manually set the value of a filter
 *  - also required tableEvents = [] be added to the parent component's state
 *
 * // the filters to apply to the table's data
   filters={ [[an array of filters created through calls to Filter.createFilter(...)]] }
 * Note: these filters do not automatically display, instead either:
 *  - their names need to be provided to other props which will handle displaying them, or
 *  - they can be updated through calls to the following function, added to the parent component, if tableEvents props are provided:
   updateTableFilter = (name, value) => {this.setState({tableEvents: [...this.state.tableEvents, Filter.filterUpdateEvent(name, value)]})}
 *      Note: if multiple filters are updated via tableEvents at the same time, calling this function repeatedly will
 *          cause the updates to overwrite each other before the table processes them,
 *          if this happens, you can write a custom function in the style of this one that updates all filters at once
 *          just make sure you only call setState on tableEvents once
 * Note: a filter of type containedBy has a value that is an array, not a string
 *          likewise, a filter of type containsMemberOf has a value that is an array, and compares to an array as well
 *
 * // checkboxes to be displayed above the table - can (and should) be connected to filters
   filterCheckboxes={ [[an array of checkBoxes created through calls to Layout.checkBox(...)]] }
 *
 * // a dropdown of filters to be applied to the table
   filterDropdown={ [[a layout created through a call to Layout.newLayout(...) ]] }
 *      the layout should be created through a call to Layout.newLayout(rowWidth, rows)
 *      the rows parameter should be an array of rows created through calls to Layout.row(rowElements)
 *      the rowElements parameter should be an array of rowElements created through calls to Layout.rowElement(...)
 *          rowElement style should be from Layout.elementStyle
 *          dropdownOptions is an array of strings, and only needs to be provided if style is checkboxDropdown
 *      the combined widths of rowElements in a row should equal the rowWidth of the layout
 *          a width of 1 corresponds to 300px (unless it's been changed in LayoutObject.jsx)
 *          decimal widths are allowed
   applyFilterDropdownChangesImmediately
    - this can be used to skip the need to save changes to the filter dropdown
 *
 * // performs a method when a row is double-clicked
   onRowDoubleClick={ [[a method to call when a row is double-clicked]] }
 *      the method has the form: methodName = (row) => {}
 *
 * // enables per-row state accessed through the tableRow prop
   getRowId={(row) => [[a string unique to this row]] }
 *      if this prop is provided, the signature expected by the tableRow prop changes
 *      from (row, index) => <TableCell>... to (row, index, getRowState, setRowState) => <TableCell>...
 *      where getRowState is called with getRowState()
 *          and returns an object (similar to how state is usually stored)
 *      and setRowState is called with setRowState(newState)
 *          where newState is an object (most easily created with {}, similar to setting state normally)
 *      this state is not persistent past the end of the TablePage object
 *
 * // enables a field to select a user's location. selected location is changed across the entire client
   hasLocationField
 * Note: this uses a <LocationSelectBox/> from LocationSelectBox.jsx, and it's usual instructions apply
 *      listAvailableLocations (in actions/settings) should be called in the parent component's componentDidMount()
 *      to access the selected location add selectedLocation: selectedLocationIdSelector(state) to the parent component's mapStateToProps
 * Note: The table does not automatically sort by selected location.
 *      To sort by location, create a filter and provide the selected location to it through the parent component's componentDidUpdate(...)
 *      see "filters" above for a method to do so
 *
 * // enables some try/catch blocks and can be helpful for debugging
   debug
 *
 */

/* Example Use:

    <TablePage
        tableName="DamagesTable"

        tableRows={this.props.tableRows.allDamages}
        rowFetcher={(filters) => {this.props.listAllDamages()}}

        tableColumnHeads={this.getTableHeaders()}
        tableRow={this.tableRow}

        paginationText={ROWS_PER_PAGE_LABEL}

        defaultSortBy={DEFAULT_SORT_BY_HEAD_CELL}

        hasSearchField
        searchStrings={this.searchStrings}

        filters={[
            Filter.createFilter("supplier", (row) => row.supplier, null, Filter.relations.containedBy, true),
            Filter.createFilter("hasQuantityRemaining", (row) => (row.qtyRemaining > 1), null, Filter.relations.equals, false)
        ]}

        filterCheckboxes={[
            Layout.checkBox("hasQuantityRemaining", HIDE_COMPLETED_DAMAGES_TEXT)
        ]}

        filterDropdown={
            Layout.newLayout(1, [
                Layout.row([
                    Layout.rowElement(1, "supplier", "Supplier", Layout.elementStyle.checkboxDropdown, this.props.suppliers.map((supplier) => supplier.name))
                ])
            ])
        }

        applyFilterDropdownChangesImmediately
    />

*/

// ----

import React from "react";
import withShipment from "../../../withShipment";

import './TablePage.css';
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {
    Checkbox,
    IconButton,
    Slide,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TextField
} from "@material-ui/core";
import {Filter} from "./TablePageHelpers/FilterObject";
import FilterIcon from "../../shipments/FilterIcon";
import Tooltip from "@material-ui/core/Tooltip";
import {FILTER_TAG_ID_SEPARATOR} from "./TablePageHelpers/constants";
import {Layout} from "./TablePageHelpers/LayoutObject";
import {ROWS_PER_PAGE} from "../../invoices/carrierInvoices/constants";
import {ASC, DESC} from "../constants";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import SearchableTable from "../Search/SearchableTable";
import LocationSelectBox from "../subcomponents/locationSelectBox/LocationSelectBox";
import FiltersTagsArrayBar from "./TablePageHelpers/FiltersTagsArrayBar";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Clear} from "@material-ui/icons";
import {FormControlLabel} from "@mui/material";

class TablePage extends SearchableTable {

    constructor(props) {
        super(props);

        if (this.props.debug) {
            try {
                this.constructor_debugContainer(props)
            } catch (e) {
                console.log("TablePage debug constructor exception: " + e)
            }
        } else {
            this.constructor_debugContainer(props)
        }
    }

    constructor_debugContainer(props) {

        this.state = {

            // static input - updated only in constructor
            // (they're all props)

            // internal state
            unsavedFilters: [],
            updatedUnsavedFilters: false,

            filters: [], // gets saved/loaded
            filtersDidUpdate: false,

            filteredTableRows: [],

            smartSearchRows: [],

            filterCheckboxesState: [],

            filterDropdownToggle: false,

            rowsPerPage: ROWS_PER_PAGE, // gets saved/loaded
            page: 0, // gets saved/loaded

            orderBy: "", // gets saved/loaded
            order: DESC, // gets saved/loaded

            searchString: "", // gets saved/loaded

            rowState: {},

            // output - does not affect internal state
            filterCheckboxes: [],
            filterTagsArray: [],
            filterTagIdsArray: [], // everywhere that filterTagsArray is updated, filterTagIdsArray should be as well
            filterDropdown: [],
        }
        this.setupFilterCheckboxes(this.props.filterCheckboxes)
        this.loadTableState()
        this.state.filteredTableRows = this.filterRows(this.props.tableRows)
        this.updateFilterCheckboxState(this.state.filters, true)
        if (this.props.filterCheckboxes) {
            this.state.filterCheckboxes = this.getUpdatedFilterCheckBoxes(this.props.filterCheckboxes, this.state.filterCheckboxesState)
        }
        this.updateFilterTagsArray(this.state.filters, true)
        this.state.filterDropdown = this.getFilterDropdown(this.props.filterDropdown)

        this.fetchRows(this.props.rowFetcher)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.debug) {
            try {
                this.componentDidUpdate_debugContainer(prevProps, prevState, snapshot)
            } catch (e) {
                console.log("TablePage debug componentDidUpdate exception: " + e)
            }
        } else {
            this.componentDidUpdate_debugContainer(prevProps, prevState, snapshot)
        }
    }

    componentDidUpdate_debugContainer(prevProps, prevState, snapshot) {
        if (this.props.eventsVariable && this.props.eventsVariable.length !== 0) {
            for (let i = 0; i < this.props.eventsVariable.length; i++) {
                this.setFilterValue(this.props.filters.find(filter => filter.name === this.props.eventsVariable[i].name), this.props.eventsVariable[i].value)
            }
            this.props.setEventsVariable([])
        }

        if (this.state.filtersDidUpdate) {
            this.updateFilterTagsArray(this.state.filters, false)
            this.setState({
                page: 0,
                filterDropdown: this.getFilterDropdown(this.props.filterDropdown)
            })
            this.updateFilterCheckboxState(this.state.filters, false)
            this.fetchRowsIfNeeded(this.props.refetchFilters, this.state.filters, prevState.filters)
        }

        if (this.state.filterCheckboxesState !== prevState.filterCheckboxesState) {
            this.setState({
                filterCheckboxes: this.getUpdatedFilterCheckBoxes(this.props.filterCheckboxes, this.state.filterCheckboxesState)
            })
        }

        if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage ||
            this.state.order !== prevState.order || this.state.orderBy !== prevState.orderBy ||
            this.state.searchString !== prevState.searchString || this.state.filtersDidUpdate) {
            this.saveTableState()
        }

        if (this.state.updatedUnsavedFilters) {
            this.setState({
                filterDropdown: this.getFilterDropdown(this.props.filterDropdown),
                updatedUnsavedFilters: false,
            })
            if (this.props.applyFilterDropdownChangesImmediately) {
                this.saveUnsavedFilters()
            }
        }

        if (this.props.tableRows !== prevProps.tableRows || this.state.filtersDidUpdate) {
            this.setState({
                filteredTableRows: this.filterRows(this.props.tableRows)
            })
        }

        if (this.props.smartSearchRows !== prevProps.smartSearchRows) {
            this.setState({
                smartSearchRows: this.props.smartSearchRows
            })
        }

        if (this.state.filtersDidUpdate) {
            this.setState({
                filtersDidUpdate: false
            })
        }

    }

    fetchRows = (rowFetcher) => {
        if (!rowFetcher)
            return

        let filters = this.state.filters?.map((filter) => {
            return {name: filter.name, value: filter.value}
        })

        rowFetcher(filters)
    }

    fetchRowsIfNeeded = (refetchFilters, newFilters, oldFilters) => {
        // refetchFilters is an array of strings that are filter names

        if (!refetchFilters || !newFilters || !oldFilters) {
            return
        }

        let filtersToCheck = newFilters.filter((filter) => refetchFilters.includes(filter.name))
        let changedFilters = filtersToCheck.filter((newFilter) =>
            oldFilters.find((oldFilter) => oldFilter.name === newFilter.name).value !== newFilter.value
        )

        if (changedFilters?.length > 0) {
            this.fetchRows(this.props.rowFetcher)
        }
    }

    getFilterDropdown = (filterDropdownCreator) => {
        if (!filterDropdownCreator) {
            return null
        }
        return filterDropdownCreator(
            (nameValueObj) => {this.addToUnsavedFilters(nameValueObj.name, nameValueObj.value)},
            this.filterDropdownGetElementValue,
        )
    }

    getFilterNames = () => {
        if (!this.props.filters) {
            return []
        }
        return this.props.filters.map((filter) => (filter.name))
    }

    getFilterValueFromName = (connectedFilterName, elementStyle) => {
        if (!this.state.filters) {
            return null
        }
        let filter = this.state.filters.find((filter) => filter.name === connectedFilterName)
        let filterNotEmpty = filter.value && (elementStyle !== Layout.elementStyle.checkboxDropdown || filter.value.length > 0)
        return filterNotEmpty ? filter.value : filter.defaultFilterValue
    }

    filterDropdownGetElementValue = (elementDisplayName, connectedFilterName, elementStyle) => {

        let filterNames = this.getFilterNames()
        if (!filterNames.includes(connectedFilterName)) {
            return "Error: no connected filter"
        }

        if (this.unsavedFiltersHasFilterWithName(connectedFilterName)) {
            return this.unsavedFiltersValueOfFilterWithName(connectedFilterName, elementStyle)
        } else {
            return this.getFilterValueFromName(connectedFilterName, elementStyle) ?? Layout.getDefaultElementValueFromStyle(elementStyle)
        }
    }

    unsavedFiltersHasFilterWithName = (connectedFilterName) => {
        if (!this.state.unsavedFilters) {
            return false
        }
        return this.state.unsavedFilters.find((unsavedFilter) => unsavedFilter.name === connectedFilterName)
    }

    unsavedFiltersValueOfFilterWithName = (connectedFilterName, elementStyle) => {
        if (!this.state.unsavedFilters) {
            return false
        }
        let filter = this.state.unsavedFilters.find((unsavedFilter) => unsavedFilter.name === connectedFilterName)
        let filterNotEmpty = filter.value && (elementStyle !== Layout.elementStyle.checkboxDropdown || filter.value.length > 0)
        return filterNotEmpty ? filter.value : this.props.filters.find(propFilter => propFilter.name === filter.name).defaultFilterValue
    }

    setupFilterCheckboxes = (checkboxCreators) => {
        if (!checkboxCreators) {
            return
        }

        let newFilterCheckboxesState = []
        checkboxCreators.map((checkboxCreator) => {
            newFilterCheckboxesState.push(checkboxCreator.checkedByDefault)
        })
        this.state.filterCheckboxesState = newFilterCheckboxesState
    }

    getUpdatedFilterCheckBoxes = (checkboxCreators, filterCheckboxesState) => {
        return <React.Fragment>
            {checkboxCreators.map((checkboxCreator, index) => {
                return checkboxCreator.createCheckbox(
                    filterCheckboxesState[index],
                    () => {
                        let updatedFilterCheckboxesState = [...filterCheckboxesState]
                        updatedFilterCheckboxesState[index] = !updatedFilterCheckboxesState[index]
                        let filter = this.state.filters?.find((filter) => filter.name === checkboxCreator.connectedFilterName)
                        if (filter) {
                            this.setFilterValue(filter, updatedFilterCheckboxesState[index])
                        }
                        this.setState({
                            filterCheckboxesState: updatedFilterCheckboxesState,
                            filterCheckboxes: this.getUpdatedFilterCheckBoxes(checkboxCreators, updatedFilterCheckboxesState)
                        })
                    }
                )
            })}
        </React.Fragment>
    }

    updateFilterCheckboxState = (filters, inConstructor) => {
        if (!this.props.filterCheckboxes || !filters) {
            return
        }

        let newFilterCheckboxesState = [...this.state.filterCheckboxesState]
        let filterNames = filters.map((filter) => filter.name)

        this.props.filterCheckboxes.map((checkboxCreator, index) => {
            if (filterNames.includes(checkboxCreator.connectedFilterName)) {
                newFilterCheckboxesState[index] = (!!filters.find((filter) => filter.name === checkboxCreator.connectedFilterName).value)
            }
        })

        if (inConstructor) {
            this.state.filterCheckboxesState = newFilterCheckboxesState
        } else {
            this.setState({
                filterCheckboxesState: newFilterCheckboxesState,
            })
        }
    }

    loadTableState = () => { // first loadTableState must come before first saveTableState
        // is called exclusively in the constructor
        let newFilters
        let newPage = 0
        let newRowsPerPage = ROWS_PER_PAGE
        let newOrderBy = this.props.defaultSortBy ? this.props.defaultSortBy : ""
        let newOrder = ASC
        let newSearchString = ""
        let state = localStorage.getItem(this.props.tableName)

        if (state) {
            state = JSON.parse(state)
        }

        if (state) {

            if (state["filters"]) {
                newFilters = state["filters"]
            }
            if (this.props.paginationText) {
                newPage = state["page"]
                newRowsPerPage = state["rowsPerPage"] ? state["rowsPerPage"] : ROWS_PER_PAGE
            }
            if (this.state.order !== "") {
                newOrder = state["order"]
                newOrderBy = state["orderBy"] ? state["orderBy"] : ""
            }
            if (this.props.hasSearchField) {
                newSearchString = state["searchString"]
            }

        } else { // no state variable - set default values
            if (this.props.filters) {
                newFilters = [...this.props.filters]
            }
        }

        this.state.filters = newFilters
        this.state.page = newPage
        this.state.rowsPerPage = newRowsPerPage
        this.state.orderBy = newOrderBy
        this.state.order = newOrder
        this.state.searchString = newSearchString
    }

    saveTableState = () => {
        let state = new Map()

        if (this.state.filters) {
            state["filters"] = this.state.filters
        }
        if (this.props.paginationText) {
            state["page"] = this.state.page
            state["rowsPerPage"] = this.state.rowsPerPage
        }
        if (this.state.order !== "") {
            state["order"] = this.state.order
            state["orderBy"] = this.state.orderBy
        }
        if (this.props.hasSearchField) {
            state["searchString"] = this.state.searchString
        }

        localStorage.setItem(this.props.tableName, JSON.stringify(state))
    }

    addToUnsavedFilters = (name, value) => {
        let newUnsavedFilters = [...this.state.unsavedFilters]

        let index = -1
        newUnsavedFilters.forEach((unsavedFilter, filterIndex) => {
            if (unsavedFilter.name === name) {
                index = filterIndex
            }
        })
        if (index !== -1) {
            newUnsavedFilters.splice(index, 1)
        }

        newUnsavedFilters.push({name: name, value: value})
        this.setState({
            unsavedFilters: newUnsavedFilters,
            updatedUnsavedFilters: true,
        })
    }

    clearUnsavedFilters = () => {
        this.setState({
            unsavedFilters: [],
            updatedUnsavedFilters: true,
        })
    }

    saveUnsavedFilters = () => {
        if (!this.state.unsavedFilters || !this.state.filters) {
            this.clearUnsavedFilters()
            return
        }

        let filterNames = this.state.filters.map((filter) => filter.name)

        this.state.unsavedFilters.forEach((unsavedFilter) => {
            if (filterNames.includes(unsavedFilter.name)) {
                this.setFilterValue(this.state.filters.find((filter) => filter.name === unsavedFilter.name),
                    unsavedFilter.value)
            }
        })
    }

    setFilterValue = (filter, value) => { // filter should be non-null and appear in this.state.filters
        let newFilter = Filter.createFilter(
            filter.name,
            filter.field,
            value,
            filter.relation,
            filter.appearsInTagBar,
            filter.getDisplayValue,
            filter.defaultFilterValue
        )

        let index
        this.state.filters.forEach((mapFilter, mapIndex) => {
            if (mapFilter.name === filter.name) {
                index = mapIndex
            }
        })
        let newFilters = [...this.state.filters]
        newFilters.splice(index, 1, newFilter)

        this.state.filters = newFilters
        // setting this in a setState(...) causes conflict when filters are updated more than once in quick succession
        // instead, filtersDidUpdate tracks whether filters changed, and other state must adjust accordingly

        this.setState({
            filtersDidUpdate: true
        })
        return newFilter
    }

    updateFilterTagsArray = (filters, inConstructor) => {
        let newFilterTagsArray = []
        let newFilterTagIdsArray = []
        if (filters) {
            filters.map((filter) => {
                if (filter.value && filter.appearsInTagBar) {
                    let newFilterTags = this.getFilterTagNameIdValueArray(filter)
                    newFilterTags.map((newFilterTag) => {
                        newFilterTagsArray.push({name: newFilterTag.name, value: this.props.filters.find((propFilter) => propFilter.name === filter.name)
                                .getDisplayValue(newFilterTag.value)})
                        newFilterTagIdsArray.push(newFilterTag.id)
                    })
                }
            })
        }

        if (inConstructor) {
            this.state.filterTagsArray = newFilterTagsArray
            this.state.filterTagIdsArray = newFilterTagIdsArray
        } else {
            this.setState({
                filterTagsArray: newFilterTagsArray,
                filterTagIdsArray: newFilterTagIdsArray,
            })
        }
    }

    getFilterTagNameIdValueArray = (filter) => {
        if (Array.isArray(filter.value)) {
            return filter.value.map((value) => {
                return {name: filter.name, value: value, id: filter.name.replaceAll(":","::") + FILTER_TAG_ID_SEPARATOR + value.replaceAll(":","::")}
            })
        } else {
            return [{name: filter.name, value: filter.value, id: filter.name.replaceAll(":","::") + FILTER_TAG_ID_SEPARATOR}]
        }
    }

    clearTag = (tagNameIdObject) => {
        if (!this.state.filters)
            return

        let filter = this.getFilterFromTagName(tagNameIdObject.name)

        if (filter) {
            let filterTagSpecificValue = tagNameIdObject.id.split(FILTER_TAG_ID_SEPARATOR)[1].replaceAll("::", ":")
            if (filterTagSpecificValue === "") {
                this.setFilterValue(filter, null)
            } else {
                let valueIndex = filter.value.indexOf(filterTagSpecificValue)
                let newValue = filter.value
                if (valueIndex !== -1) {
                    newValue.splice(valueIndex, 1)
                }
                if (newValue.length === 0) {newValue = null}
                this.setFilterValue(filter, newValue)
            }
        }
    }

    getFilterFromTagName = (tagName) => {
        return this.state.filters.find((filter) => filter.name === tagName)
    }

    filterRows = (rows) => {
        let newRows = [...rows]
        if (newRows.length > 0) {
            let filter
            let propFilter
            for (let i = 0; i < this.state.filters?.length; i++) {
                filter = this.state.filters[i]
                propFilter = this.props.filters.find((foundFilter) => foundFilter.name === filter.name)

                let filterValue = filter.value
                let applyFilter = false
                if (filterValue !== filter.defaultFilterValue &&
                    filterValue &&
                    ((filter.relation !== Filter.relations.containedBy && filter.relation !== Filter.relations.containsMemberOf) || filterValue.length > 0)
                ) {
                    applyFilter = true
                } else if (filter.defaultFilterValue) {
                    filterValue = filter.defaultFilterValue
                    applyFilter = true
                }

                if (applyFilter) {
                    switch (filter.relation) {
                        case Filter.relations.equals:
                            newRows = newRows.filter((row) => propFilter.field(row) === filterValue)
                            break
                        case Filter.relations.lessThanEquals:
                            newRows = newRows.filter((row) => propFilter.field(row) <= filterValue)
                            break
                        case Filter.relations.greaterThanEquals:
                            newRows = newRows.filter((row) => propFilter.field(row) >= filterValue)
                            break
                        case Filter.relations.contains:
                            newRows = newRows.filter((row) => propFilter.field(row).includes(filterValue))
                            break
                        case Filter.relations.containedBy:
                            //bank account is in the format "name (accountNumber)"
                            if(filter.name==="bankAccount"){
                                newRows = newRows.filter((row) => filterValue?.includes(row?.bankAccount.name+" ("+row?.bankAccount.accountNumber+")"))
                                break
                            }
                            //category = null cases will be considered as 'uncategorized expenses'
                            if(filter.name==="category"&&filterValue?.includes("Uncategorized")){
                                newRows = newRows.filter((row) => filterValue?.includes(propFilter.field(row))||row?.category==null)
                                break
                            }
                            newRows = newRows.filter((row) => filterValue?.includes(propFilter.field(row)))
                            break
                        case Filter.relations.containsMemberOf:
                            newRows = newRows.filter((row) => {
                                let match = false
                                for (let i = 0; i < filterValue.length; i++) {
                                    if  (propFilter.field(row) && propFilter.field(row).includes(filterValue[i])) {
                                        match = true
                                        break
                                    }
                                }
                                return match
                            })
                            break
                    }
                }
            }
        }
        if (this.props.handleCategory) {
            this.props.handleCategory(this.state.filters[1].value)
        }

        if(this.props.type === 'transactionTable'){

                const totalAmount = newRows.reduce((acc, row) => {
                    // Remove commas and convert to float for summation
                    const amount = parseFloat(row.amount.replace(/,/g, '')) || 0;
                    return acc + amount;
                }, 0);

                const totalRow = {
                    id: {},
                    taxInfos: [],
                    parentTransactionId: '',
                    date:  '',
                    files:  '',
                    uploadHash:  '',
                    isSubTransaction: false,
                    notes:  '',
                    referenceId:  '',
                    version:  '',
                    bankAccount: {},
                    transactionId: '',
                    references:  '',
                    isImportedEntry: false,
                    type: 'manual',
                    category:  '',
                    amount: totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    description: ''
                }


                   if(totalAmount !== 0){
                       newRows.push(totalRow);
                   }



        }

        return newRows
    }

    applySearch = (rows) => {
        if (!this.state.searchString || !this.props.searchStrings || !rows) {
            return rows
        }
        return rows.filter((row) => (this.props.searchStrings(row))?.find((text) => text?.toString()?.toLowerCase()?.includes(this.state.searchString.toLowerCase())))
    }

    applySort = (rows) => {
        if (this.props.type === "statementTaxes") {
            // Filter rows that shouldn't be sorted
            const unsortedRows = rows.filter(row =>
                row.description === "Total Paid" || row.description === "Taxes Receivable (Payable)" || row.description === 'Total Received'
            );

            // Filter rows that should be sorted
            let sortedRows = rows.filter(row =>
                row.description !== "Total Paid" && row.description !== "Taxes Receivable (Payable)" && row.description !==  'Total Received'
            );

            if (this.state.orderBy !== "") {
                sortedRows = this.stableSort(sortedRows, this.getComparator(this.state.order, this.state.orderBy));
            }

            // Append the unsorted rows back
            return [...sortedRows, ...unsortedRows];
        }else if(this.props.type === "transactionTable"){
            // Filter rows that shouldn't be sorted
            const unsortedRows = rows.filter(row =>
                row.type === "manual"
            );

            // Filter rows that should be sorted
            let sortedRows = rows.filter(row =>
                row.type !== "manual"
            );

            if (this.state.orderBy !== "") {
                sortedRows = this.stableSort(sortedRows, this.getComparator(this.state.order, this.state.orderBy));
            }

            // Append the unsorted rows back
            return [...sortedRows, ...unsortedRows];


        } else if (this.state.orderBy !== "") {
            return this.stableSort(rows, this.getComparator(this.state.order, this.state.orderBy));
        } else {
            return rows;
        }
    };



    paginate = (rows) => {
        if (this.props.paginationText) {
            return rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
        }
        return rows
    }

    filterTagsBar = (filterTagsArray, filterTagIdsArray) => {
        return <div className='full-width-bar'>
            <FiltersTagsArrayBar
                filtersArray={filterTagsArray}
                filtersIdsArray={filterTagIdsArray}
                onClick={(event, tagName, tagId) => this.clearTag({name: tagName, id: tagId})}
            />
        </div>
    }

    filterDropdownToggleIcon = (filterDropdown) => {
        if (!filterDropdown || !this.props.filterDropdown) {
            return null
        }

        return <div className='standard-margin-top'>
            <div className='vertically-center'>
                <Tooltip title={'Filter'} className='filter-tooltip'>
                    <IconButton className="filterButton" size='small' aria-label={'Filter'} onClick={this.toggleFiltersDialog} >
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </div>

    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        })
    }

    handleSearchChange = (newSearchValue) => {
        if (this.props.smartSearches) {
            this.props.smartSearches.forEach((smartSearch) => {
                let matches = (newSearchValue).match(smartSearch.regularExpression)
                if (matches) {
                    let matchesFullString = false
                    matches.forEach((match) => {
                        if (match.length === newSearchValue.length) {
                            matchesFullString = true
                        }
                    })
                    if (matchesFullString) {
                        smartSearch.actionCreator(newSearchValue)
                    }
                }
            })
        }

        this.setState({
            searchString: newSearchValue,
            smartSearchRows: [],
            page: 0
        })
    }

    toggleFiltersDialog = () => {
        if (!this.state.filterDropdownToggle) { // if opening filter dropdown
            this.clearUnsavedFilters()
        }
        this.setState({
            filterDropdownToggle: !this.state.filterDropdownToggle
        })
    }

    handleFilterDropdownCancel = () => {
        this.clearUnsavedFilters()
        this.toggleFiltersDialog()
    }

    handleFilterDropdownSave = () => {
        this.saveUnsavedFilters()
        this.clearUnsavedFilters()
        this.toggleFiltersDialog()
    }

    handleSort = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    }

    getRowState = (rowId) => {
        let rowState = this.state.rowState[rowId]
        return rowState ? rowState : {}
    }

    setRowState = (rowId, rowState) => {
        let newRowState = this.state.rowState
        let thisRowState = newRowState[rowId] ?? {}
        thisRowState = {
            ...thisRowState,
            ...rowState
        }
        newRowState[rowId] = thisRowState
        this.setState({
            rowState: newRowState
        })
    }

    displaySearchBar = () => {
        return <React.Fragment>
            <div className='standard-margin-left'>
                <TextField
                    style={{width: "260px"}}
                    label={"Search"}
                    name={"searchBar"}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => this.handleSearchChange(e.target.value)}
                    value={this.state.searchString}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    className={"clear-button"}
                                    onClick={(e) => {
                                        this.handleSearchChange("")
                                    }}>
                                    <Clear/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </React.Fragment>
    }

    displayTopBarLeft = (barContents, hasSearchField) => {
        return <div className='standard-margin-top'>

            {hasSearchField ? this.displaySearchBar() : null}

            {barContents}

        </div>
    }

    displayTopBarRight = (barContents, hasLocationField) => {
        return <div className='standard-margin-top'>

            {!hasLocationField ? null :
                <div className='standard-margin-right'>
                    <LocationSelectBox/>
                </div>
            }

            {barContents}

        </div>
    }

    displayFilterCheckboxes = (filterCheckboxes) => {
        return <React.Fragment>

            {filterCheckboxes}

        </React.Fragment>
    }

    displayPagination = (paginationText) => {
        if (!paginationText)
            return <></>

        let numberOfRows = (this.state.smartSearchRows && this.state.smartSearchRows.length > 0 ?
            this.state.smartSearchRows : this.applySearch(this.state.filteredTableRows))?.length ?? 0

        return <React.Fragment>
            <div> {/* This div prevents a 16px margin from appearing around pagination - cause unknown, but keeping div to prevent margin */}
                <TablePagination
                    style={{margin: '0px', borderBottom: '0px'}}
                    labelRowsPerPage={paginationText}
                    rowsPerPageOptions={[5, 25, 50, 100, {value: numberOfRows, label: "All"}]}
                    count={numberOfRows}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </div>
        </React.Fragment>
    }

    displayFilterDropdown = (filterDropdownToggle, filterDropdown) => {
        return <Slide in={filterDropdownToggle} mountOnEnter unmountOnExit timeout={300}>
            <div className='full-width-bar'>
                <div className='horizontally-center'>
                    {this.displayFilterDropdownTitle(filterDropdown)}
                    {this.displayFilterDropdownContents(filterDropdown)}
                    {this.displayFilterDropdownCancelSave(filterDropdown)}
                </div>
            </div>
        </Slide>
    }

    handleFilterDropdownClearFilters = () => {
        //clear filters
        this.setState(prevState => ({
            filters: prevState.filters.map(obj => ({...obj, value: null}))
        }));
        // intentionally not clearing any filter not attached to labels (likely checkbox)
        if (this.state.filterTagsArray) {
            this.state.filterTagsArray.forEach((filterTag, index) => {
                this.clearTag({name: filterTag.name, id: this.state.filterTagIdsArray[index]})
            })
        }
        this.setState({
            unsavedFilters: [],
            updatedUnsavedFilters: true,
        })
    }

    displayFilterDropdownTitle = (filterDropdown) => {
        if (!filterDropdown) {
            return <></>
        }
        return <React.Fragment>
            {Layout.titleRow(filterDropdown.width, "Filters", "CLEAR FILTERS", this.handleFilterDropdownClearFilters)}
        </React.Fragment>
    }

    displayFilterDropdownContents = (filterDropdown) => {
        if (!filterDropdown) {
            return <></>
        }

        return <>
            {filterDropdown.errorMessage === "" ? filterDropdown.content : filterDropdown.errorMessage}
        </>
    }

    displayFilterDropdownCancelSave = (filterDropdown) => {
        if (!filterDropdown) {
            return <></>
        }
        return <React.Fragment>
            {Layout.cancelSaveRow(filterDropdown.width, this.handleFilterDropdownCancel, this.handleFilterDropdownSave)}
        </React.Fragment>
    }

    displayTableHeadCellContent = (columnHead) => {
        return <React.Fragment>
            {columnHead.alignLeftRightCenter === 'left' || columnHead.alignLeftRightCenter === 'right' ?
                <TableSortLabel
                    style={this.props.type === 'statementTaxes' ? { marginLeft: '24px' } : null}
                    active={this.state.orderBy === columnHead.id}
                    direction={this.state.orderBy === columnHead.id ? this.state.order : ASC}
                    onClick={this.handleSort(columnHead.id)}
                >
                    {columnHead.text}
                </TableSortLabel>
                :
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div style={{width: "24px"}}/>
                    <TableSortLabel
                        active={this.state.orderBy === columnHead.id}
                        direction={this.state.orderBy === columnHead.id ? this.state.order : ASC}
                        onClick={this.handleSort(columnHead.id)}
                    >
                        {columnHead.text}
                    </TableSortLabel>
                </div>
            }
        </React.Fragment>

    }

    displayTableHeader = (tableColumnHeads) => {
        if (!tableColumnHeads) {
            return <></>
        }

        const displayedRows = this.paginate(this.applySort(this.state.smartSearchRows && this.state.smartSearchRows.length > 0 ?
            this.state.smartSearchRows : this.applySearch(this.state.filteredTableRows)))

        return <React.Fragment>
            <TableRow className="table"> {/* it's somewhat unclear what css className="table" is connecting to, but this is responsible for the topmost divider line */}
                {tableColumnHeads.map((columnHead) => {

                    if (columnHead.id === 'selectAllCheckbox') {
                        return (
                            <TableCell align="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => this.props.handleSelectAll(displayedRows)}
                                            indeterminate={this.props.numSelectedEntries > 0 || this.props.numSelected < displayedRows.length}
                                            checked={this.props.numSelectedEntries === displayedRows.length && this.props.numSelectedEntries !== 0}
                                        />
                                    }
                                />
                            </TableCell>
                        )
                    }

                    if (columnHead.text === null) {
                        return <TableCell/>
                    }

                    return <React.Fragment>
                        {columnHead.widthString ?
                            <TableCell
                                key={columnHead.id}
                                align={columnHead.alignLeftRightCenter === 'right' ? 'right' : columnHead.alignLeftRightCenter === 'left' ? 'left' : 'center'}
                                padding='none'
                                style={{fontWeight: "bold", width: columnHead.widthString}}
                                sortDirection={this.state.orderBy === columnHead.text ? this.state.order : false}
                            >
                                {this.displayTableHeadCellContent(columnHead)}
                            </TableCell>
                        :
                            <TableCell
                                key={columnHead.id}
                                align={columnHead.alignLeftRightCenter === 'right' ? 'right' : columnHead.alignLeftRightCenter === 'left' ? 'left' : 'center'}
                                padding='none'
                                style={{fontWeight: "bold"}}
                                sortDirection={this.state.orderBy === columnHead.text ? this.state.order : false}
                            >
                                {this.displayTableHeadCellContent(columnHead)}
                            </TableCell>
                        }
                    </React.Fragment>
                })}
            </TableRow>
        </React.Fragment>
    }

    displayTableRow = (row, index) => {
        if (this.props.getRowId) {
            return this.props.tableRow(row, index, () => this.getRowState(this.props.getRowId(row)), (newState) => this.setRowState(this.props.getRowId(row), newState))
        } else {
            return this.props.tableRow(row, index)
        }
    }

    displayTableRows = (filteredTableRows) => {
        if (this.props.onRowDoubleClick) {
            return <React.Fragment>
                {filteredTableRows.map((row, index) =>
                    <TableRow hover onDoubleClick={() => this.props.onRowDoubleClick(row)}>
                        {this.displayTableRow(row, index)}
                    </TableRow>
                )}
            </React.Fragment>
        } else {
            return <React.Fragment>
                {filteredTableRows.map((row, index) =>
                    <TableRow>
                        {this.displayTableRow(row, index)}
                    </TableRow>
                )}
            </React.Fragment>
        }
    }

    render() {
        if (this.props.debug) {
            try {
                return this.render_debugContainer()
            } catch (e) {
                console.log("TablePage debug render exception: " + e)
            }
        } else {
            return this.render_debugContainer()
        }
        return <></>
    }

    render_debugContainer() {
        return (
            <React.Fragment>

                {this.props.type === 'statementTaxes' ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                {this.displayTableHeader(this.props.tableColumnHeads)}

                            </TableHead>
                            <TableBody >

                                {this.displayTableRows(this.paginate(this.applySort(this.state.smartSearchRows && this.state.smartSearchRows.length > 0 ?
                                    this.state.smartSearchRows : this.applySearch(this.state.filteredTableRows))))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <>
                                        <div className='full-width-bar'>
                                            <div className='spacer-10px'/>
                                            <div className='standard-filter-tag-row-margin'>
                                                {this.filterTagsBar(this.state.filterTagsArray, this.state.filterTagIdsArray)}
                                            </div>
                                        </div>

                <div className='full-width-bar'>
                    <div className='half-row-align-left'>
                        <div className='spacer-10px' style={this.props.type === 'shippingSummary' ? { marginBottom: '100px' } : null}>
                        </div>
                        {this.displayTopBarLeft(this.props.topBarLeft, this.props.hasSearchField)}
                        {this.filterDropdownToggleIcon(this.state.filterDropdown)}
                    </div>
                    <div className='spacer'/>
                    <div className='half-row-align-right'>
                        {this.displayTopBarRight(this.props.topBarRight, this.props.hasLocationField)}
                        <div className='spacer-10px'/>
                    </div>
                </div>

                            <div className='full-width-bar'>
                                <div className='flex-row-align-left'>
                                    <div className='spacer-15px'/>
                                    {this.displayFilterCheckboxes(this.state.filterCheckboxes)}
                                </div>
                                <div className='tablepage-spacer'/>
                                <div className='flex-row-align-right'>
                                    {this.displayPagination(this.props.paginationText)}
                                    <div className='spacer-10px'/>
                                </div>
                            </div>

                            {this.displayFilterDropdown(this.state.filterDropdownToggle, this.state.filterDropdown)}

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        {this.displayTableHeader(this.props.tableColumnHeads)}

                                    </TableHead>
                                    <TableBody >

                                        {this.displayTableRows(this.paginate(this.applySort(this.state.smartSearchRows && this.state.smartSearchRows.length > 0 ?
                                            this.state.smartSearchRows : this.applySearch(this.state.filteredTableRows))))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </>
                }



            </React.Fragment>
        )
    }
}

TablePage.defaultProps = {
    tableRows: [],
}

TablePage.propTypes = {

}

const mapStateToProps = (state) => ({

})

const actionCreators = {

}

export default withShipment({
    mapStateToProps,
    actionCreators
}, TablePage);

