import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import LedgerTable from "./LedgerTable";
import {LEDGERS_PAGES} from "./constants";
import AddLedger from "./AddLedger";
import EditLedger from "./EditLedger";
import {
    ledgerSelector,
    transactionCategoriesSelector,
    transactionLedgerSelector
} from "../../../redux/selectors/accounting";
import {
    fetchTransactionsLedger,
    listAllLedgers,
    listTransactionCategories,
    saveLedger,
    saveTransactionsLedger
} from "../../../redux/actions/accounting";

class Ledger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: LEDGERS_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            transactionCategories:this.props.transactionCategories,
            ledgerEditing: {},
            years: ['2023','2024'],
            selectedYear: "2024"
    }
    }

    componentDidMount() {
        this.props.listAllLedgers()
        this.props.listTransactionCategories()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.ledgerEditing!== this.props.ledgerEditing && this.props.ledgerEditing) {
            this.setState({
                ledgerEditing: this.props.ledgerEditing
            })
        }

        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }

    }

    handleSave = (ledgers) => {
      this.props.saveLedger(ledgers)
      this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

     getLastTransactionBalance = (transactions) => {
        if (transactions.length === 0) return null;
        const lastTransaction = transactions[transactions.length - 1];
        return lastTransaction.balance;
    };

    handleSaveTransactionsLedger = (transactions,ledgerId) => {
        const lastBalance = this.getLastTransactionBalance(transactions)
        const entry = {
            year: this.state.selectedYear,
            entries: transactions.map(transaction => ({
                date: transaction.date,
                balance: parseFloat(transaction.balance),
                description: transaction.description,
                debit: parseFloat(transaction.debit),
                credit: parseFloat(transaction.credit),
                transactionId: transaction.transactionId
            })),
            balance: lastBalance // convert to number
        };

        const data = { ledgerEntries: entry, ledgerId: ledgerId}

        this.props.saveTransactionsLedger(data)
       this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

    handleYearChange = (event) => {
        if(this.state.currentPage === 'Edit Ledgers'){
            this.setState({ selectedYear: event.target.value },()=>{
                const data = {ledgerId: this.state.ledgerEditing.ledgerId, year: this.state.selectedYear}
                this.props.fetchTransactionsLedger(data)
            })
        }else{
            this.setState({ selectedYear: event.target.value })
        }

    }


    setCurrentPage = (newPage,data) => {
        this.setState({
            currentPage: newPage,
            ledgerEditing: data,
        })
    }


    displayTable = () => {
            return (
                <LedgerTable
                    years={this.state.years}
                    selectedYear={this.state.selectedYear}
                    handleYearChange={this.handleYearChange}
                    ledgers={this.props.ledgers}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                />
            )
    }

    displayAddLedger = () => {
        return (
            <AddLedger
                ledgers={this.props.ledgers}
                handleSave={this.handleSave}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                categories={this.state.transactionCategories}
            />
        )
    }

    displayEditLedger = () => {
        return (
            <EditLedger
                years={this.state.years}
                handleYearChange={this.handleYearChange}
                selectedYear={this.state.selectedYear}
                ledger={this.state.ledgerEditing}
                handleSave={this.handleSaveTransactionsLedger}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                fetchTransactionsLedger={this.props.fetchTransactionsLedger}
                fetchTransactions={this.props.fetchTransactions}
            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case LEDGERS_PAGES.TABLE:
                return this.displayTable()
            case LEDGERS_PAGES.ADD:
                return this.displayAddLedger()
            case LEDGERS_PAGES.EDIT:
                return  this.displayEditLedger()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
    ledgers: ledgerSelector(state),
    fetchTransactions: transactionLedgerSelector(state),
})


const actionCreators = {
    clearStatus,
    listTransactionCategories,
    listAllLedgers,
    saveLedger,
    saveTransactionsLedger,
    fetchTransactionsLedger
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, Ledger);