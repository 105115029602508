import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { SHIPPING_SUMMARY_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {
    listShippingSettings,
    listShippingSummary,
    saveShippingSummarySettings,
} from "../../../redux/actions/accounting";
import {loadingSelector, shippingSettingSelector, shippingSummarySelector} from "../../../redux/selectors/accounting";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import ShippingSummaryTable from "./ShippingSummaryTable";
import {carriersSelector} from "../../../redux/selectors/settings";
import {listCarriers} from "../../../redux/actions/settings";
import {userInfoSelector} from "../../../redux/selectors/auth";
import ShippingSummarySetting from "./ShippingSummarySetting";
class ShippingSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carriers:[],
            shippingSummary: this.props.shippingSummary,
            currentPage: SHIPPING_SUMMARY_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            selectedYear: new Date().getFullYear(),
            selectedQuarters: null,
            selectedCurrency: 'CAD',
            selectedAccountNumbers: [],
            selectedCarrierType: "Standard Carrier",
            carrierTotal: 0,
            selectedMonth: '01',
            currency: ['CAD', 'USD','Consolidated CAD'],
            consolidateByCarrier: false,
    }
    }

    componentDidMount() {
        this.props.listShippingSettings()
        this.props.listCarriers({ id: this.props.user.company })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }
        if (prevProps.carriers !== this.props.carriers) {
            this.setState({
                carriers: this.props.carriers
            }, () => {
               this.listShippingApi()
            });
        }

        if (prevProps.shippingSummary !== this.props.shippingSummary) {
             this.calculateTotal()
        }
    }


    listShippingApi = () =>{
        let accountNumbers = this.state.carriers.map(carrier => carrier.accountNumber)

        this.setState({
            accountNumbers: accountNumbers
        }, () => {
            let data
            if(this.state.selectedQuarters === null){
                 data = {
                     "type": this.state.selectedCarrierType,
                    "year": this.state.selectedYear,
                    "accountNumbers": this.state.accountNumbers,
                    "quarter": [1,2,3,4],
                     "month": this.state.selectedMonth.trim(),
                     "currency": this.state.selectedCurrency,
                     "consolidateByCarrier": this.state.consolidateByCarrier
                }
            }else{
                let newQuarter = [this.state.selectedQuarters]
                 data = {
                     "type": this.state.selectedCarrierType,
                    "year": this.state.selectedYear,
                    "accountNumbers": this.state.accountNumbers,
                    "quarter": newQuarter,
                     "month": this.state.selectedMonth.trim(),
                     "currency": this.state.selectedCurrency,
                     "consolidateByCarrier": this.state.consolidateByCarrier
                 }
            }
            this.props.listShippingSummary(data)
        });
    }

    handleYearChange = (event) => {
        this.setState({ selectedYear: event.target.value },() => {
            this.listShippingApi()
        })

    }

    handleByCarrier = (event) => {
        const isChecked = event.target.checked;
        this.setState({ consolidateByCarrier: isChecked },() => {
            this.listShippingApi()
        })
    }

    handleChange = (event) => {
        this.setState({ selectedCurrency: event.target.value },() => {
            this.listShippingApi()
        })
    }

    handleMonthChange = (event) => {
        this.setState({ selectedMonth: event.target.value },() => {
            this.listShippingApi()
        })

    }

    handleCarrierTypeChange = (event) => {

        if(event.target.value === "LTL/FTL Carrier"){
            this.setState({ currency: ['CAD','USD']})
        }else{
            this.setState({ currency: ['CAD', 'USD','Consolidated CAD']})
        }

        this.setState({ selectedCarrierType: event.target.value },() => {
            this.listShippingApi()
        })
    }

    handleQuarterChange = (quarter) => {
        const quarterMapping = {
            "QTR 1": 1,
            "QTR 2": 2,
            "QTR 3": 3,
            "QTR 4": 4
        }
        const quarterNumber = quarterMapping[quarter]
        const selectedQuarters = this.state.selectedQuarters === quarterNumber ? null : quarterNumber
        this.setState({ selectedQuarters }, () => {
            this.listShippingApi()
        })
    }

    calculateTotal = () => {
        let carrierTotal;

        // Create a new shipping summary that includes the total at the last index
        const newShippingSummary = [...this.props.shippingSummary];

        // Calculate the total based on the selected currency
        if (this.state.selectedCurrency === "Consolidated CAD" &&
            this.props.shippingSettings.yearRates &&
            this.props.shippingSettings.yearRates.length > 0) {

            const currentYearRate = this.props.shippingSettings.yearRates.find(setting => setting.year === this.state.selectedYear.toString()) || { rate: 0 };

            // Calculate the total using reduce
            carrierTotal = newShippingSummary.reduce((total, summary) => {
                const rate = summary?.currencyCode[0] === "USD" ? parseFloat(currentYearRate.rate) : 1;
                return total + parseFloat(summary?.CY_TOTAL_PAYABLE) * rate;
            }, 0);

        } else {
            carrierTotal = newShippingSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_TOTAL_PAYABLE) || 0;
            }, 0);
        }

        // Create a new object with all remaining fields from the last item and the total
        const newItem = {
            CY_SUBTOTAL: '',          // Empty field
            CY_TAX: '',               // Empty field
            CY_TOTAL: '',             // Empty field
            CY_SUBTOTAL_PAYABLE: '',  // Empty field
            CY_TAX_PAYABLE: '',       // Empty field
            PY_SUBTOTAL_PAYABLE: '',   // Empty field
            PY_TAX_PAYABLE: '',       // Empty field
            CY_TOTAL_PAYABLE: carrierTotal, // Set total
            location: '',             // Empty field
            accountNumber: '',        // Empty field
            carrierName: '',          // Empty field
            currencyCode: []          // Empty field (array)
        };

        // Add the new item at the last index only if newShippingSummary has data
        if (newShippingSummary.length > 0) {
            newShippingSummary.push(newItem);
        }

        this.setState({ carrierTotal, shippingSummary: newShippingSummary });
    }


    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
        if (this.state.carriers && this.state.carriers.length > 0) {
            return (
                <ShippingSummaryTable
                    loadingData={this.props.loadingData}
                    currency={this.state.currency}
                    selectedMonth={this.state.selectedMonth}
                    handleMonthChange={this.handleMonthChange}
                    settings={this.props.shippingSettings}
                    selectedQuarters={this.state.selectedQuarters}
                    selectedCurrency={this.state.selectedCurrency}
                    selectedYear={this.state.selectedYear}
                    selectedCarrierType={this.state.selectedCarrierType}
                    handleQuarterChange={this.handleQuarterChange}
                    handleChange={this.handleChange}
                    handleByCarrier={this.handleByCarrier}
                    carrierTotal={this.state.carrierTotal}
                    handleYearChange={this.handleYearChange}
                    handleCarrierTypeChange={this.handleCarrierTypeChange}
                    shippingSummary={this.state.shippingSummary}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                    consolidateByCarrier={this.state.consolidateByCarrier}
                />
            );
        }
    }

    displaySettings = () => {
            return (
                <ShippingSummarySetting
                    handleSave={this.handleSave}
                    settings={this.props.shippingSettings}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                />
            )

    }

    handleSave = (rates) => {
        this.props.saveShippingSummarySettings(rates)
        this.setCurrentPage(SHIPPING_SUMMARY_PAGES.TABLE, {})
    }


    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case SHIPPING_SUMMARY_PAGES.TABLE:
                return this.displayTable()
            case SHIPPING_SUMMARY_PAGES.SETTINGS:
                return this.displaySettings()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    shippingSummary: shippingSummarySelector(state),
    shippingSettings: shippingSettingSelector(state),
    carriers: carriersSelector(state),
    user: userInfoSelector(state),
    loadingData: loadingSelector(state)
})


const actionCreators = {
    clearStatus,
    listCarriers,
    listShippingSummary,
    saveShippingSummarySettings,
    listShippingSettings
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, ShippingSummary);