import React from "react";

// material components
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";

// custom components
import ReturnsTable from "./ReturnsTable";
import ReturnsForm from "./ReturnsForm";
import withShipment from "../../withShipment";
import CustomToolbar from "../global/CustomToolbar";

// constants
import {CREATE_RETURN_TITLE, RETURN_TOOLBAR_TITLE, BACK_TO_MENU, DEFAULT_ROWS_PER_PAGE} from "./constants";
import {
    returnActionsSelector,
    returnConditionsSelector,
    returnsSelector
} from "../../redux/selectors/returns";
import {
    createReturn,
    listReturnActions,
    listReturnConditions,
    listReturns, previewReturnFile,
    updateReturnItem,
    listUncompletedReturns, fetchRmaInfo
} from "../../redux/actions/returns";
import {
    listReturnReasons,
} from "../../redux/actions/returns";
import {
    getUsers,
} from "../../redux/actions/auth";
import {
    returnReasonsSelector,
    rmaFetchSelector
} from "../../redux/selectors/returns";
import {listAvailableLocations, listProducts} from "../../redux/actions/settings";
import { availableLocationsSelector, productsSelector } from "../../redux/selectors/settings";
import {selectedLocationIdSelector, userInfoSelector, usersSelector} from "../../redux/selectors/auth";
import PropTypes from "prop-types";
import {
    saveTableSetting,
    getTableSetting
} from "../../redux/actions/account";
import {
    tableSettingSelector
} from "../../redux/selectors/account";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";

// redux

// styling

class Returns extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            creatingReturn: false,
            returnOpened: null,
            searchText: ''
        }
    }

    componentDidMount() {
        this.props.listProducts()
        this.props.getUsers()
        this.props.listReturnActions()
        this.props.listReturnConditions()
        this.props.listReturnReasons()
        this.props.listAvailableLocations()
        this.props.getTableSetting()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if a return is opened and a return updates (preview loaded)
        if (prevProps.returns !== this.props.returns && this.state.returnOpened !== null) {
            // find the opened return
            let curReturn = this.state.returnOpened
            let returnId = curReturn.returnId
            let updatedReturn = this.props.returns.find(returnItem => {
                return returnItem.returnId === returnId
            })

            curReturn.files = updatedReturn.files

            this.setState({
                returnOpened: curReturn
            })
        }
        if (prevProps.tableSetting !== this.props.tableSetting) {
            if (this.props.tableSetting?.returnsFilterSetting?.showCompletedReturns) {
                this.props.listReturns()
            } else {
                this.props.listUncompletedReturns()
            }
        }
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    creatingReturn = () => {
        this.setState({
            creatingReturn: true
        })
    }

    cancelReturn = () => {
        this.setState({
            creatingReturn: false,
            returnOpened: null
        })
    }

    handleSubmitReturn = (data, creatingNewReturn) => {
        if (creatingNewReturn) {
            data["userInfo"] = {
                createdByName: `${this.props.currentUser.firstname} ${this.props.currentUser.lastname}`,
                createdById: this.props.currentUser.userId
            }
            delete data["files"] // we only care about the new files
            this.props.createReturn(data, () => window.location.reload())
        }
        else {
            delete data["files"] // we only care about the new files and deleted files
            this.props.updateReturnItem(data, () => window.location.reload())
        }
    }

    openReturn = (returnItem) => {
        this.setState({
            returnOpened: returnItem
        })
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ErrorFeedbackBar />
                <Paper className='paper'>
                    <CustomToolbar
                        title={RETURN_TOOLBAR_TITLE}
                        createTitle={CREATE_RETURN_TITLE}
                        backTitle={BACK_TO_MENU}
                        onClick={this.creatingReturn}
                        handleClose={this.cancelReturn}
                        creatingOrder={this.state.creatingReturn || (!!this.state.returnOpened)}
                    />
                    {!this.state.returnOpened ?
                        this.state.creatingReturn ?
                            <ReturnsForm
                                cancel={this.cancelReturn}
                                creatingForm={true}
                                submit={this.handleSubmitReturn}
                                returnItem={null}
                                returnActions={this.props.returnActions}
                                returnConditions={this.props.returnConditions}
                                currentUser={this.props.currentUser}
                                returnReasons={this.props.returnReasons}
                                users={this.props.users}
                                locations={this.props.availableLocations}
                                products={this.props.products}
                                fetchRmaInfo={this.props.fetchRmaInfo} // send to backend
                                rmaFetch={this.props.rmaFetch} // get from backend & selector
                            />
                            :
                            <ReturnsTable
                                rows={this.props.returns}
                                page={this.state.page}
                                setPage={this.setPage}
                                rowsPerPage={this.state.rowsPerPage}
                                setRowsPerPage={this.setRowsPerPage}
                                currentUser={this.props.currentUser}
                                handleDoubleClick={this.openReturn}
                                tableSetting={this.props.tableSetting}
                                saveTableSetting={this.props.saveTableSetting}
                                user={this.props.currentUser}
                                searchText={this.state.searchText}
                                handleSearchChange={this.handleSearchTextChange}
                                selectedLocation={this.props.selectedLocation}
                            />

                        :
                        <ReturnsForm
                            cancel={this.cancelReturn}
                            creatingForm={false}
                            submit={this.handleSubmitReturn}
                            returnActions={this.props.returnActions}
                            returnConditions={this.props.returnConditions}
                            returnItem={this.state.returnOpened}
                            returnReasons={this.props.returnReasons}
                            currentUser={this.props.currentUser}
                            users={this.props.users}
                            fetchFilePreview={this.props.previewReturnFile}
                            locations={this.props.availableLocations}
                            products={this.props.products}
                            fetchRmaInfo={this.props.fetchRmaInfo} // send to backend
                            rmaFetch={this.props.rmaFetch} // get from backend & selector
                        />
                    }

                </Paper>
            </MuiPickersUtilsProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    returns: returnsSelector(state),
    returnActions: returnActionsSelector(state),
    returnConditions: returnConditionsSelector(state),
    returnReasons: returnReasonsSelector(state),
    currentUser: userInfoSelector(state),
    users: usersSelector(state),
    availableLocations: availableLocationsSelector(state),
    products: productsSelector(state),
    tableSetting: tableSettingSelector(state),
    rmaFetch: rmaFetchSelector(state),
    selectedLocation: selectedLocationIdSelector(state),
});

const actionCreators = {
    listReturns,
    listProducts,
    getUsers,
    createReturn,
    listReturnActions,
    listReturnConditions,
    updateReturnItem,
    previewReturnFile,
    listReturnReasons,
    listAvailableLocations,
    saveTableSetting,
    getTableSetting,
    listUncompletedReturns,
    fetchRmaInfo,
}

Returns.propTypes = {
    returns: PropTypes.array,
    returnActions: PropTypes.array,
    returnConditions: PropTypes.array,
    currentUser: PropTypes.object,
    users: PropTypes.array,
    availableLocations: PropTypes.array,
}

Returns.defaultProps = {
    returns: [],
    returnActions: [],
    returnConditions: [],
    currentUser: {},
    users: [],
    availableLocations: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Returns);