import React from "react";


//components

import SearchableTable from "../../../global/Search/SearchableTable";
import GlobalTableHead from "../../../global/Table/GlobalTableHead";


//material ui
import {
    TableBody,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    IconButton,
    TextField,
    Tooltip,
    Button
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';



//constants
import { ASC, DESC } from "../../../global/constants";
import {
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_SORT_BY_HEAD_CELL,
    EDITING_CATEGORY_TABLE_HEAD_CELLS,
    REMOVE_CATEGORY_TITLE,
    ADD_CATEGORY_TITLE,
    CANCEL,
    SAVE,
    MANAGE_CATEGORY,
} from "./constants";





import './ManageCategoryTable.css'
import ErrorFeedbackBar from "../../../global/ErrorFeedbackBar";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TopToolbar from "../../../global/subcomponents/topToolbar/TopToolbar";
import {BACK, SALES_PIPELINE_PAGES} from "../constants";
import SearchField from "../../../global/Search/SearchField";

class ManageCategoryTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            newCategory: [],
            editedCategory: [],
            deletedCategory: [],
            successList: [],
            isEditingCategory: false,
            saved: false,
            editingCategory: this.getEditingCategory(),
        }
    }




    getEditingCategory = ()=>{
        return this.props.categories ? this.props.categories : []
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    componentDidMount() {

    }


    checkDuplicatedAndEmpty = (row) => {
        if (row.categoryName?.length > 0) {
            return this.state.editingCategory.filter(category => { return category.categoryName?.toLowerCase() === row.categoryName?.toLowerCase() }).length > 1
        }
        return true
    }

    checkDisable = () => {
        let saveDisable = false;
        this.state.editingCategory.forEach(category => {
            if (this.checkDuplicatedAndEmpty(category)) {
                saveDisable = true
            }
        })
        let isSame = true;

        this.state.editingCategory.forEach(category => {
            if (!this.props.categories.find(originCategory => {
                return originCategory.categoryId == category.categoryId && originCategory.categoryName == category.categoryName
            })) {
                isSame = false
            }
        })
        this.props.categories.forEach(category => {
            if (!this.state.editingCategory.find(editingCategory => {
                return editingCategory.categoryId == category.categoryId && editingCategory.categoryName == category.categoryName
            })) {
                isSame = false
            }
        })
        return saveDisable || (isSame)
    }

    handleUpdateCategory = () => {
        const newCategory = [];
        const editedCategory = [];
        const deletedCategory = [];
        const curCategoryIds = this.props.categories.map(category => {
            return category.categoryId
        })
        const editingCategoryIds = this.state.editingCategory.map(category => {
            return category.categoryId
        })
        //check remove
        this.props.categories.forEach(category => {
            if (!editingCategoryIds.includes(category.categoryId)) {
                deletedCategory.push(category)
            }
        })

        this.state.editingCategory.forEach(editingCategory => {
            if (curCategoryIds.includes(editingCategory.categoryId)) {
                const originCategory = this.props.categories.find(category => {
                    return category.categoryId === editingCategory.categoryId
                })
                if ((editingCategory.categoryName != originCategory.categoryName)) { //check edit
                    editedCategory.push(editingCategory)
                }
            } else {
                newCategory.push(editingCategory)
            }
        })


        this.props.editCategory(newCategory, editedCategory, deletedCategory)
    }


    handleCancel = () => {
        this.setState({
            editingCategory: [],
            isEditingCategory: false,
        })
    }

    handleSuccessClose = () => {
        this.setState({
            feedbackOpen: false
        })
    }

    handleErrorClose = () => {
        this.setState({
            errorFeedbackOpen: false
        })
    }


    onSearchValueChange = (e) => {
        const searchValue = e.target.value;
        this.setState({
            isSearch: searchValue.length > 0,
            searchValue: searchValue,
            page: 0
        });
    };

    filterBySearch = (categories) => {
        const { searchValue } = this.state;
        if (!searchValue) return categories;
        return categories.filter(category =>
            category.categoryName.toLowerCase().includes(searchValue.toLowerCase())
        );
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addEntry = () => {
        let curEditingCategory = [...this.state.editingCategory]; // Copy existing categories
        const newCategory = {
            categoryName: '',
            categoryId: Date.now(), // Temporary unique ID for client-side
        };
        curEditingCategory.push(newCategory);
        this.setState({
            editingCategory: curEditingCategory,
        });
    };

    removeEntry = (row) => {
        const { categoryId } = row;
        const newEditingCategory = this.state.editingCategory.filter(
            category => category.categoryId !== categoryId
        );
        this.setState({
            editingCategory: newEditingCategory,
        });
    };




    handleCategoryNameChange = (e, row) => {
        const categoryId = row.categoryId
        const value = e.target.value
        row["categoryName"] = value

        let newEditingCategory = []

        this.state.editingCategory.forEach(category => {
            if (category.categoryId != categoryId) {
                newEditingCategory.push(category)
            } else {
                newEditingCategory.push(row)
            }
        })
        this.setState({
            editingCategory: newEditingCategory,
        })
    }


    render() {
        return (
            <>
                <ErrorFeedbackBar />

                <TopToolbar
                    pageName={MANAGE_CATEGORY}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon/>,
                            onClick: () => {
                                this.props.setCurrentPage(SALES_PIPELINE_PAGES.TABLE, {})
                            }
                        }
                    ]}
                />

                <Paper className='paper'>

                    <div className='po-header-search-field' style={{marginBottom:'24px'}}>
                        <div className='po-search-field'>
                            <SearchField onChange={this.onSearchValueChange} searchText={this.state.searchValue}/>
                        </div>
                    </div>


                            <TableContainer>
                                <Table size='medium'>
                                    <GlobalTableHead
                                        isCreatePurchaseOrderTable={false}
                                        isOrderTable={false}
                                        numSelected={0}
                                        headCells={EDITING_CATEGORY_TABLE_HEAD_CELLS}
                                    />
                                    <TableBody>
                                        {this.filterBySearch(this.state.editingCategory).map((row, index) => {
                                            const key = row.categoryId
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell className='category-checkCircleBox'>
                                                        <Tooltip placement="left" title={REMOVE_CATEGORY_TITLE}>
                                                            <IconButton onClick={(e) => this.removeEntry(row)}>
                                                                <RemoveCircleIcon
                                                                    fontSize="small"
                                                                    color='error'
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className='category-inputBox'>
                                                        <div className='category-inputBox'>
                                                            <TextField
                                                                variant="outlined"
                                                                name={row.categoryId}
                                                                value={row.categoryName}
                                                                onChange={(e) => this.handleCategoryNameChange(e, row)}
                                                                size="small"
                                                                error={this.checkDuplicatedAndEmpty(row)}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="tag-endingBox"/>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell className='category-checkCircleBox'>
                                                <Tooltip placement="left" title={ADD_CATEGORY_TITLE}>
                                                    <IconButton onClick={() => this.addEntry()}>
                                                        <AddCircleIcon
                                                            fontSize="small"
                                                            color='secondary'
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className="tag-endingBox"/>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="createcategoryButtonBox">
                                <div className="createcategoryButton">
                                    <Button onClick={this.handleCancel} color="secondary" variant="outlined">
                                        {CANCEL}
                                    </Button>
                                </div>

                                <div className="createcategoryButton">
                                    <Button onClick={this.handleUpdateCategory} color="primary" variant="contained"
                                            disabled={this.checkDisable()}>
                                        {SAVE}
                                    </Button>
                                </div>
                            </div>
                </Paper>
            </>
    );
    }

    }


    export default ManageCategoryTable;