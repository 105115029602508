import React from 'react';

// Redux
import withShipment from "../../../../withShipment";
import PropTypes from "prop-types"
import {
    listCarrierInvoiceTemplates,
    deleteCarrierInvoiceTemplate,
    createSaveCarrierInvoiceTemplate,
    getCarrierInvoiceTemplatePDF,
    getDateFormats,
    storeCarrierInvoiceTemplatePDF, reimportCarrierInvoiceTemplatePDF
} from "../../../../redux/actions/invoices";
import {
    carrierInvoiceTemplatesSelector,
    carrierInvoiceTemplatePDFSelector,
    allDateFormats
} from "../../../../redux/selectors/invoices";

// Custom Components
import CustomToolbar from "../../../global/CustomToolbar";
import CreateCarrierInvoiceTemplate from "./CreateCarrierInvoiceTemplate";
import CarrierInvoiceTemplateTable from "./CarrierInvoiceTemplateTable";

// Material UI
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MenuIcon from "@material-ui/icons/Menu";

// Constants
import {
    CARRIER_INVOICE_TEMPLATE_FIELDS,
    CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS,
    DEFAULT_DATE_FORMAT
} from "./constants"
import ErrorFeedbackBar from '../../../global/ErrorFeedbackBar';
import SaveIcon from "@material-ui/icons/Save";
import {DEFAULT_PDF_ZOOM} from "../../../global/constants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Upload} from "@mui/icons-material";

class CarrierInvoiceTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: DEFAULT_PDF_ZOOM,
            carrierIndex: -1,
            multiweightCheck: false,
            summaryCheck: false,
            includeSender: false,
            editing: false,
            creating: false,
            selectedCarrier: "none",
            widenBox: false,
            showFields: false,
            fieldError: "",
            fieldColumnError: -1,
            separatorError: "",
            entrySepStart: 1,
            entryMultiSepStart: 1,
            entryTransportChargeStart: 1,
            summaryStartPage: 1,
            summaryEndPage: 1,
            validationType: "List",
            validationStartText: "",
            validationEndText: "",
            entrySepText: "",
            entryMultiSepText: "",
            entryTransportChargeText: "",
            fields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS)),
            separatorFields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS)),
            fieldColumns: [],
            templateCopy: "none",
            template: {
                name: "",
                contents: []
            },
            selectedDateFormat: DEFAULT_DATE_FORMAT,
        }
        this.hiddenTemplateUpload = React.createRef()
    }

    componentDidMount() {
        this.props.listCarrierInvoiceTemplates()
        this.props.getDateFormats()
    }

    deleteTemplate = (carrierId, name) => {
        let data = { carrierId: carrierId, carrierName: name }
        this.props.deleteCarrierInvoiceTemplate(data, () => window.location.reload(), () => alert("Something Went Wrong"))
    }

    toggleFieldShow = () => {
        if (this.state.showFields) { // Close sidebar
            setTimeout(() => {
                this.setState({ widenBox: false })
            }, 225);
        } else { // Open sidebar
            this.setState({ widenBox: true })
        }
        this.setState({ showFields: !this.state.showFields })
    }

    updateField = (field, value) => {
        this.setState({ [field]: value })
    }
    zoomIn(){
        this.setState({scale: Math.min(2, this.state.scale + 0.25)})
    }
    zoomOut() {
        this.setState({scale: Math.max(1, this.state.scale - 0.25)})
    }
    setDateFormat = (format) => {
        this.setState({
            selectedDateFormat: format
        })
    }
    updateParentFields = (fields) => {
        this.setState({ fields: fields })
    }
    fieldDefined = (position) => {
        return position.w && position.h
    }
    changedCheck = () => {
        this.setState({multiweightCheck: !this.state.multiweightCheck});
    }
    changedSummaryCheck = () => {
        this.setState({summaryCheck: !this.state.summaryCheck})
    }
    changedSender = () => {
        this.setState({includeSender: !this.state.includeSender});
    }
    saveTemplate = () => {
        let data = {
            data: {},
            dateFormat: this.state.selectedDateFormat
        }
        let fieldKeys = Object.keys(this.state.fields)
        for (const key of fieldKeys) {
            if (this.state.fields[key].required && !this.fieldDefined(this.state.fields[key].position)) {
                this.setState({ fieldError: key })
                return
            }
            let tempPosition = {...this.state.fields[key].position}
            tempPosition.x = Math.round(tempPosition.x / this.state.scale)
            tempPosition.y = Math.round(tempPosition.y / this.state.scale)
            tempPosition.w = Math.round(tempPosition.w / this.state.scale)
            tempPosition.h = Math.round(tempPosition.h / this.state.scale)
            data.data[key] = tempPosition

        }
        let separatorKeys = Object.keys(this.state.separatorFields)
        for (const key of separatorKeys) {
            if (this.state.separatorFields[key].required && !this.state.separatorFields[key].value) {
                this.setState({ separatorError: key })
                return
            }
            data[key] = this.state.separatorFields[key].value
            if (key === "startingPage") {
                for (let i = 0; i < this.state.separatorFields[key].value.length; ++i) {
                    let current = this.state.separatorFields[key].value.charAt(i)
                    if (isNaN(parseInt(current)) || current === "0") {
                        this.setState({ separatorError: key })
                        return
                    }
                }
                data[key] = parseInt(data[key])
            }
        }
        let counter = 0
        for (const column of this.state.fieldColumns) {
            if (!column.title) {
                this.setState({ fieldColumnError: counter })
                return
            }
            ++counter
        }
        data.fieldColumns = this.state.fieldColumns
        data.carrierId = this.state.selectedCarrier
        data.pdf = Array.from(this.state.template.contents)
        data["entrySepStart"] = this.state.entrySepStart
        data["entryMultiSepStart"] = this.state.entryMultiSepStart
        data["entryTransportChargeStart"] = this.state.entryTransportChargeStart
        data["summaryStartPage"] = this.state.summaryStartPage
        data["summaryEndPage"] = this.state.summaryEndPage
        data["validationType"] = this.state.validationType
        data["validationStartText"] = this.state.validationStartText
        data["validationEndText"] = this.state.validationEndText
        data["multiweightCheck"] = this.state.multiweightCheck
        data["summaryCheck"] = this.state.summaryCheck
        data["includeSender"] = this.state.includeSender

        data["entrySepText"] = this.state.entrySepText
        data["entryMultiSepText"] = this.state.entryMultiSepText
        data["entryTransportChargeText"] = this.state.entryTransportChargeText

        this.props.createSaveCarrierInvoiceTemplate(data, () => window.location.reload(), () => alert("Something Went Wrong"))
    }
    handleEditTemplate = (template) => {
        this.props.storeCarrierInvoiceTemplatePDF([])
        this.props.getCarrierInvoiceTemplatePDF({ carrierId: template.carrierId })
        let newFields = JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS))
        let dataKeys = Object.keys(template.data)
        for (const key of dataKeys) {
            newFields[key].position = template.data[key]
        }
        let separatorFields = JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS))
        for (const key in separatorFields) {
            separatorFields[key].value = template[key] ?? ""
        }
        this.setState({
            editing: true,
            fields: newFields,
            separatorFields: separatorFields,
            fieldColumns: template.fieldColumns,
            selectedCarrier: template.carrierId,

            entrySepStart: template.entrySepStart,
            entryMultiSepStart: template.entryMultiSepStart,
            entryTransportChargeStart: template.entryTransportChargeStart,
            summaryStartPage: template.summaryStartPage,
            summaryEndPage: template.summaryEndPage,
            validationType: template.validationType,
            validationStartText: template.validationStartText,
            validationEndText: template.validationEndText,
            multiweightCheck: template.multiweightCheck,
            summaryCheck: template.summaryCheck,
            includeSender: template.includeSender,

            entrySepText: template.entrySepText,
            entryMultiSepText: template.entryMultiSepText,
            entryTransportChargeText: template.entryTransportChargeText,
        })
        this.setDateFormat(template.dateFormat ?? DEFAULT_DATE_FORMAT)
    }
    copySaveTemplate = () => {
        let oldTemplate = this.props.carrierInvoiceTemplates.find(template => template.carrierId === this.state.templateCopy)
        let data = {}
        data.oldCarrierId = oldTemplate.carrierId
        data.data = oldTemplate.data
        data.fieldColumns = oldTemplate.fieldColumns
        data.carrierId = this.state.selectedCarrier
        for (const key in CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS) {
            data[key] = oldTemplate[key]
        }
        this.props.createSaveCarrierInvoiceTemplate(data, () => window.location.reload(), () => alert("Something Went Wrong"))
    }
    cancelEdit = () => {
        this.setState({
            editing: false,
            widenBox: false,
            showFields: false,
            fieldError: "",
            fieldColumnError: -1,
            separatorError: "",
            fields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS)),
            separatorFields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS)),
            fieldColumns: [],
            selectedDateFormat: DEFAULT_DATE_FORMAT
        })
    }

    handleUpload = () => {
        this.hiddenTemplateUpload.current.click()
    }

    reimportCarrierInvoiceTemplatePDF = (e) => {
        if (e.target.files.length === 0)
            return
        let file = e.target.files[0]
        let fileReader = new FileReader()

        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
            this.props.reimportCarrierInvoiceTemplatePDF({ content: Array.from(contents), carrierId: this.state.selectedCarrier })
        }
        fileReader.readAsArrayBuffer(file)
    }

    render() {
        let headerButtons = this.state.editing ?
            [
                { icon: <KeyboardBackspaceIcon />, handler: () => this.cancelEdit(), disabled: false, title: "Back" },
                { icon: <Upload/>, handler: () => this.handleUpload(), title: "Upload file"},
                { icon: <SaveIcon />, handler: this.saveTemplate, disabled: false, title: "Save" }
            ]
            :
            this.state.creating ?
                [
                    { icon: <KeyboardBackspaceIcon />, handler: () => this.updateField("creating", false), disabled: false, title: "Cancel" },
                    { icon: <ArrowForwardIcon />, handler: () => this.updateField("editing", true), disabled: this.state.selectedCarrier === "none" || this.state.template.contents.length === 0, title: "Next" },
                    { icon: <SaveIcon />, handler: this.copySaveTemplate, disabled: this.state.selectedCarrier === "none" || this.state.templateCopy === "none", title: "Save" }
                ]
                :
                [
                    {icon: <KeyboardBackspaceIcon />, handler: () => this.props.closeInvoiceTemplates(), disabled: false, title: "Back"},
                    {icon: <AddCircleIcon/>, handler: () => this.setState({ creating: true }), disabled: false, title: "Create Carrier Invoice Template"}
                ];
        let rightHeaderButtons = this.state.editing ? [{ icon: <MenuIcon />, handler: this.toggleFieldShow, disabled: false, title: "Show Fields" }] : null
        return (<>
            <input type="file" accept="text/csv" ref={this.hiddenTemplateUpload} hidden onChange={this.reimportCarrierInvoiceTemplatePDF} />
            <ErrorFeedbackBar />
            <CustomToolbar
                createTitle="Create Carrier Invoice Template"
                title="Carrier Invoice Templates"
                onClick={() => this.setState({ creating: true })}
                headerButtons={headerButtons}
                rightHeaderButtons={rightHeaderButtons}
                zoomIn={() => this.zoomIn()}
                zoomOut={() => this.zoomOut()}
                scale={this.state.scale}
                addZoom={this.state.editing}
            />
            {this.state.creating &&
                <CreateCarrierInvoiceTemplate
                    updateField={(field, value) => this.updateField(field, value)}
                    editing={this.state.editing}
                    selectedCarrier={this.state.selectedCarrier}
                    template={this.state.template}
                    updateParentFields={this.updateParentFields}
                    fieldDefined={this.fieldDefined}
                    widenBox={this.state.widenBox}
                    showFields={this.state.showFields}
                    fieldError={this.state.fieldError}
                    fieldColumnError={this.state.fieldColumnError}
                    separatorFields={this.state.separatorFields}
                    fieldColumns={this.state.fieldColumns}
                    separatorError={this.state.separatorError}
                    templateCopy={this.state.templateCopy}
                    templates={this.props.carrierInvoiceTemplates}
                    entrySepStart={this.state.entrySepStart}
                    entryMultiSepStart={this.state.entryMultiSepStart}
                    entryTransportChargeStart={this.state.entryTransportChargeStart}
                    summaryStartPage={this.state.summaryStartPage}
                    summaryEndPage={this.state.summaryEndPage}
                    validationType={this.state.validationType}
                    validationStartText={this.state.validationStartText}
                    validationEndText={this.state.validationEndText}
                    entrySepText={this.state.entryTransportChargeText}
                    entryMultiSepText={this.state.entryMultiSepText}
                    entryTransportChargeText={this.state.entryTransportChargeText}
                    scale={this.state.scale}
                    checked={this.state.multiweightCheck}
                    summaryCheck={this.state.summaryCheck}
                    includeSender={this.state.includeSender}
                    changedCheck={this.changedCheck}
                    changedSummaryCheck={this.changedSummaryCheck}
                    changedSender={this.changedSender}
                    dateFormats={this.props.dateFormats}
                    setDateFormat={this.setDateFormat}
                    selectedFormat={this.state.selectedDateFormat}
                /> ||
                <CarrierInvoiceTemplateTable
                    data={this.props.carrierInvoiceTemplates}
                    carrierIndex={this.state.carrierIndex}
                    editing={this.state.editing}
                    updateField={(field, value) => this.updateField(field, value)}
                    updateParentFields={this.updateParentFields}
                    fieldDefined={this.fieldDefined}
                    widenBox={this.state.widenBox}
                    showFields={this.state.showFields}
                    fieldError={this.state.fieldError}
                    fields={this.state.fields}
                    fieldColumnError={this.state.fieldColumnError}
                    separatorFields={this.state.separatorFields}
                    fieldColumns={this.state.fieldColumns}
                    separatorError={this.state.separatorError}
                    pdf={this.props.carrierInvoiceTemplatePDF}
                    handleEditTemplate={this.handleEditTemplate}
                    deleteCarrierInvoiceTemplate={this.deleteTemplate}
                    entrySepStart={this.state.entrySepStart}
                    entryMultiSepStart={this.state.entryMultiSepStart}
                    entryTransportChargeStart={this.state.entryTransportChargeStart}
                    summaryStartPage={this.state.summaryStartPage}
                    summaryEndPage={this.state.summaryEndPage}
                    validationType={this.state.validationType}
                    validationStartText={this.state.validationStartText}
                    validationEndText={this.state.validationEndText}
                    entrySepText={this.state.entrySepText}
                    entryMultiSepText={this.state.entryMultiSepText}
                    entryTransportChargeText={this.state.entryTransportChargeText}
                    scale={this.state.scale}
                    checked={this.state.multiweightCheck}
                    summaryCheck={this.state.summaryCheck}
                    includeSender={this.state.includeSender}
                    changedCheck={this.changedCheck}
                    changedSummaryCheck={this.changedSummaryCheck}
                    changedSender={this.changedSender}
                    dateFormats={this.props.dateFormats}
                    setDateFormat={this.setDateFormat}
                    selectedFormat={this.state.selectedDateFormat}
                />}
        </>)
    }
}

const mapStateToProps = (state) => ({
    carrierInvoiceTemplates: carrierInvoiceTemplatesSelector(state),
    carrierInvoiceTemplatePDF: carrierInvoiceTemplatePDFSelector(state),
    dateFormats: allDateFormats(state)
});

const actionCreators = {
    listCarrierInvoiceTemplates,
    deleteCarrierInvoiceTemplate,
    createSaveCarrierInvoiceTemplate,
    getCarrierInvoiceTemplatePDF,
    storeCarrierInvoiceTemplatePDF,
    getDateFormats,
    reimportCarrierInvoiceTemplatePDF,
}

CarrierInvoiceTemplates.propTypes = {
    carrierInvoiceTemplates: PropTypes.array,
    carrierInvoiceTemplatePDF: PropTypes.object,
    dateFormats: PropTypes.array,
}

CarrierInvoiceTemplates.defaultProps = {
    carrierInvoiceTemplates: [],
    carrierInvoiceTemplatePDF: new Uint8Array(0),
    dateFormats: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, CarrierInvoiceTemplates);