import React from "react";
import withShipment from "../../../withShipment";

//styling


// constants
import {
    BACK, CHEQUE_PAGES, PREVIEW, PRINT_CHEQUE, SAVE_DRAFT,
} from "./constants";


import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {IconButton} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import {ComponentChequeToPrint} from "../../invoices/supplierInvoices/ChequeToPrint";
import ReactToPrint from "react-to-print";
import {previewBankFile} from "../../../redux/actions/settings";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";

class ChequePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signature: this.props.formState.signature || null,
            chequeId: this.props.formState.chequeId || null,
            memoo: this.props.formState.memoo || '',
            payToName: this.props.formState.payToName,
            chequeNumber: this.props.formState.chequeNumber,
            paymentDate: this.props.formState.paymentDate,
            amount: this.props.formState.amount.replace(/\$/g, ''),
            amountWords: this.props.formState.amountWords,
            category: this.props.formState?.category?.name || '',
            bankAccount: this.props.formState.bankAccount,
            imageSrc: null

        };

    }

    componentDidMount() {

        const { formState, previewBankFile } = this.props;

        if(this.props.formState.signature !== 'No signature'){
            previewBankFile({
                path: formState.signature,
                accountNumber: formState.bankAccount.accountNumber
            });
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.bankAccounts !== this.props.bankAccounts) {
            // Update the state with the new bank account details
            const updatedBankAccount = this.props.bankAccounts.find(bank => bank.bankAccountId === this.props.formState.bankAccount.bankAccountId);
            if (updatedBankAccount) {

                this.setState({ bankAccount: updatedBankAccount },()=>{

                    const matchingFile = this.state.bankAccount.files.find(
                        file => file.path === this.props.formState.signature
                    );
                    let base64String = this.arrayBufferToBase64(matchingFile.byteArray);
                    this.setState({ imageSrc: `data:image/png;base64,${base64String}` });
                });

            }
        }
    }

    arrayBufferToBase64(buffer) {
        let binary = "";
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    getTopMargin = () => {
        return '25px'
    }

    prepareToPrint = () => {
        return <React.Fragment>
            {this.state.printing &&
                <div className="display-none">
                    <ComponentChequeToPrint
                        memoo={this.state.memoo}
                        companyAddress={this.props.companyAddress}
                        bankAccount={this.state.bankAccount}
                        category={this.state.category}
                        amount={this.state.amount}
                        amountWords={this.state.amountWords}
                        payToName={this.state.payToName}
                        chequeNumber={this.state.chequeNumber}
                        signature={this.state.imageSrc}
                        paymentDate={this.state.paymentDate}
                        ref={(el) => (this.componentRef = el)}
                        topMargin={this.getTopMargin()}
                    />
                </div>
            }
        </React.Fragment>
    }
    handleBeforeGetContent = (amount, payToName, chequeNumber, paymentDate, amountWords, memoo, bankAccount, category,imgSrc) => {
        return new Promise((resolve, reject) => {
            this.setState({
                printing: true,
                amount: amount,
                payToName: payToName,
                chequeNumber: chequeNumber,
                paymentDate: paymentDate,
                amountWords: amountWords,
                memoo: memoo,
                bankAccount: bankAccount,
                category: category,
                imgSrc: imgSrc
            }, () => resolve());
        });
    }


    saveDraft = () =>{

        const {payToName, amount, memoo, chequeNumber,paymentDate, category, bankAccount, chequeId,signature } = this.state;

        let formattedDate = moment(paymentDate, 'MM/DD/YYYY').format('YYYY-MM-DD');

        const cheque = {
            amount,
            currency: bankAccount.currency,
            chequeNumber,
            paymentDate: formattedDate,
            payToName,
            category,
            memo: memoo,
            chequeId: chequeId,
            accountNumber: `${bankAccount.name} (${bankAccount.accountNumber})`,
            signature,
            status: "Draft"
        };
        this.props.saveCheque(cheque)
        this.props.setCurrentPage(CHEQUE_PAGES.TABLE, {})
    }

    displayPrintIcon = (amount,payToName, chequeNumber, paymentDate, amountWords, memoo, bankAccount, category, imgSrc) => {
        return <div>
            <div>
                <ReactToPrint
                    trigger={() => (
                        <IconButton
                            size="small"
                            style={{ marginTop: "-7px" }}
                        >
                            <PrintIcon />
                        </IconButton>
                    )}
                    onBeforeGetContent={() => this.handleBeforeGetContent(amount,payToName, chequeNumber, paymentDate, amountWords, memoo, bankAccount, category, imgSrc)}
                    onAfterPrint={() => {


                        const {payToName, amount, memoo, chequeNumber,paymentDate, category, bankAccount, chequeId, signature } = this.state;


                        let formattedDate = moment(paymentDate, 'MM/DD/YYYY').format('YYYY-MM-DD');

                        const cheque = {
                            amount,
                            currency: bankAccount.currency,
                            chequeNumber,
                            paymentDate: formattedDate,
                            payToName,
                            category,
                            memo: memoo,
                            chequeId: chequeId,
                            accountNumber: `${bankAccount.name} (${bankAccount.accountNumber})`,
                            signature,
                            status: "Printed"
                        };
                        this.props.saveCheque(cheque)

                        this.setState({
                            printing: false,
                            amount: '',
                            payToName: '',
                            chequeNumber: '',
                            amountWords: '',
                            paymentDate: null,
                            memoo: '',
                            bankAccount: null,
                            category: '',
                            imgSrc: null,
                            signature : null
                        });

                        this.props.setCurrentPage(CHEQUE_PAGES.TABLE, {})

                    }}
                    content={() => this.componentRef}
                    pageStyle="@page { margin: 5 0 0 0 !important; }" // page margins: top right bottom left
                />
            </div>
        </div>
    }
    render() {
        return (
            <React.Fragment>
                {this.prepareToPrint()}
                <ErrorFeedbackBar/>
                <TopToolbar
                    pageName={PREVIEW}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon/>,
                            onClick: () => {
                                this.props.setCurrentPage(CHEQUE_PAGES.ADD_CHECK, this.state,{})
                            }
                        },
                        {
                            title: SAVE_DRAFT,
                            icon: <SaveIcon/>,
                            onClick: () => this.saveDraft()
                        },
                        {
                            title: PRINT_CHEQUE,
                            icon: this.displayPrintIcon(this.state.amount, this.state.payToName, this.state.chequeNumber, this.state.paymentDate, this.state.amountWords, this.state.memoo, this.state.bankAccount, this.state.category, this.state.imageSrc),
                            onClick: () => {
                            }


                        }
                    ]}
                />

                <div className="check-container" style={{padding: "20px"}}>
                    <header className="check-container__header-banner">
                        <div className="check-container__name-address">

                            <div className='nested-column'>
                                {this.props.companyAddress[0] && this.props.companyAddress[0].addressLine1 &&
                                    <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                        <div className='check-company'>
                                            {this.props.companyAddress[0].name}
                                        </div>
                                        {this.props.companyAddress[0].addressLine2 &&
                                            <div style={{marginTop: " -4px"}}>
                                                {this.props.companyAddress[0].addressLine2}
                                            </div>}
                                        <div style={{marginTop: " -4px"}}>
                                            {this.props.companyAddress[0].addressLine1}
                                        </div>
                                        <div style={{marginTop: "-4px"}}>
                                            {this.props.companyAddress[0].city}, {this.props.companyAddress[0].state} {this.props.companyAddress[0].zip}
                                        </div>

                                        <div style={{marginTop: " -4px"}}>
                                            {this.props.companyAddress[0].phone}
                                        </div>
                                    </div>}
                            </div>
                        </div>

                        <div className='nested-column'>
                            {this.state.bankAccount && this.state.bankAccount.addressLine1 &&
                                <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                    <div className='check-bank'>
                                        {this.state.bankAccount.bankFullName}
                                    </div>
                                    {this.state.bankAccount.addressLine2 &&
                                        <div style={{marginTop: " -4px"}}>
                                            {this.state.bankAccount.addressLine2}
                                        </div>}
                                    <div style={{marginTop: " -4px"}}>
                                        {this.state.bankAccount.addressLine1}
                                    </div>
                                    <div style={{marginTop: " -4px"}}>
                                        {this.state.bankAccount.city}, {this.state.bankAccount.state} {this.state.bankAccount.zip}
                                    </div>
                                    <div style={{marginTop: " -4px"}}>
                                        {this.state.bankAccount.phone}
                                    </div>
                                </div>}
                        </div>

                        <div style={{textAlign: "right"}}>

                            <div className='check-number'>

                                <span style={{paddingRight: "8px"}}>{this.props.formState?.bankAccount?.prefix}</span>
                                <span style={{marginRight: "16px"}}>{this.state.chequeNumber}</span>
                            </div>

                            <div className="check-container__date" style={{paddingTop:"40px"}}>
                            <span
                            ><label htmlFor="date-input" style={{marginRight: "8px"}}>DATE</label>
                            <span
                                className="check-container__date-border">{this.state.paymentDate}</span>
                            </span>
                            </div>
                        </div>
                    </header>

                    <main className="check-container__main-section" style={{marginTop:"-30px"}}>
                        <div className="check-container__payto">
                              <span className="check-container__order">
                                <label htmlFor="name-payto" className="check-container__payto-desc check-pay-to-the-order-of">
                                  PAY TO THE ORDER OF
                                </label>
                                  <span id="name-payto"> {this.state.payToName}</span>
                              </span>
                            <span className="check-container__dollar-part" style={{marginTop: "40px"}}
                            ><label htmlFor="dollar" className='check-dollar'>$</label>

                                    <span id="dollar" style={{marginLeft: "8px"}}
                                          className="check-container__dollar-border check-dollar">
                                        {this.state.amount}
                                    </span>
                         </span>
                        </div>
                        <div className="check-container__dollar-word" style={{paddingTop: "10px"}}>
                        </div>
                        <div className="check-amount-words" style={{marginTop: "-54px", marginLeft: "24px"}}>{this.state.amountWords}
                        </div>
                    </main>

                    <footer className="check-container__footer" style={{marginTop: "78px"}}>
                        <div style={{marginTop: "-2px"}}>MEMO</div>
                        <div style={{marginLeft: "14px"}} className="check-container__memo">

                            <div style={{whiteSpace: "nowrap"}}>{this.state.memoo}</div>
                        </div>
                        <div className="check-container__signature">
                            {this.state.imageSrc && (
                                <img style={{
                                    marginTop: "-48px",
                                    marginRight: "50px",
                                    marginLeft: "50px"
                                }}
                                     src={this.state.imageSrc} alt="Signature"/>
                            )}
                        </div>
                    </footer>

                    {this.state.bankAccount && this.state.bankAccount.transitNumber &&
                        <div className="micr">
                            C{parseInt(this.state.chequeNumber, 10).toString()}C
                            A21242D{this.state.bankAccount.institutionNumber}A {this.state.bankAccount.designationNumber}D{this.state.bankAccount.accountNumber}C</div>
                    }
                </div>


            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    bankAccounts: state.settings.bankAccounts,

});

const mapDispatchToProps = {
    previewBankFile
};


export default withShipment({
    mapStateToProps,
    mapDispatchToProps
}, ChequePreview);