import React from "react";
import withShipment from "../../../withShipment";

//styling
import styles from "../../accounting/cheques/ChequeForm.module.css";

// constants
import {
    ADD_CHEQUE,
    BACK, PREVIEW,
} from "./constants";


import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CarrierChequePreview from "./CarrierChequePreview";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import {listAllBankAccounts} from "../../../redux/actions/settings";
import {bankAccountsSelector} from "../../../redux/selectors/settings";

class CarrierChequeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusText: '',
            status: null,
            signatureFeedbackBarOpen: false,
            memoo: this.props.memoo.trim().replace(/,$/, ""),
            payToName: this.props.payToName,
            chequeNumber: this.props.chequeNumber,
            paymentDate: this.formatDate(new Date()),
            signature: '',
            amount: this.formatDollar(this.props.amount,true),
            amountWords: this.convertAmountToWords(this.props.amount),
            category: '',
            bankAccount: null,
            requiredList: [
                "chequeNumber",
                "paymentDate",
                "amount",
                "amountWords",
                "payToName",
                "bankAccount",
                "signature"
            ],
            isShowPreview: false,
            checkChequeFeedback: null,
        }
        this.openShowPreview = this.openShowPreview.bind(this);
        this.closeShowPreview = this.closeShowPreview.bind(this);
    }

    componentDidMount() {

        this.props.listAllBankAccounts()

        if(this.props?.bankAccount?.accountNumber){

            const selectedBankAccount = this.props.bankAccounts.find(
                account =>  account.accountNumber  === this.props.bankAccount?.accountNumber
            );

            this.setState({
                bankAccount: selectedBankAccount ?  selectedBankAccount : this.props.bankAccount,
                signature : "No signature"
            })
        }
    }

    parseNumber = (string) => {
        return parseFloat(string?.toString().replaceAll(',',''))
    }

    formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    openShowPreview() {
        this.setState({
            isShowPreview: true
        });
    }

    closeShowPreview() {
        this.setState({
            isShowPreview: false
        });
    }

    openSignatureFeedbackBar = () => {
        this.setState({signatureFeedbackBarOpen: true, statusText : "No Signature Found"})
    }

    closeSignatureFeedbackBar = () => {
        this.setState({ signatureFeedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    convertAmountToWords = (amount) => {

        if (isNaN(amount)) return 'Invalid amount';

        const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        const numToWords = (num) => {
            if (num === 0) return 'Zero';
            if (num < 10) return ones[num];
            if (num < 20) return teens[num - 10];
            if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + ones[num % 10] : '');
            if (num < 1000) return ones[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' and ' + numToWords(num % 100) : '');
            if (num < 1000000) return numToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + numToWords(num % 1000) : '');
            if (num < 1000000000) return numToWords(Math.floor(num / 1000000)) + ' Million' + (num % 1000000 !== 0 ? ' ' + numToWords(num % 1000000) : '');
            return numToWords(Math.floor(num / 1000000000)) + ' Billion' + (num % 1000000000 !== 0 ? ' ' + numToWords(num % 1000000000) : '');
        };

        const parts = amount.toString().split('.');
        const dollars = parseInt(parts[0], 10);
        const cents = parts[1] ? parseInt(parts[1], 10) : 0;

        let result = '';

        if (dollars > 0) {
            result += numToWords(dollars);

            if (dollars === 1) {
                result += ' Dollar';
            } else {
                result += ' Dollars';
            }
        }

        if (cents > 0) {
            if (dollars > 0) {
                result += ' and ';
            }
            result += numToWords(cents);

            if (cents === 1) {
                result += ' Cent';
            } else {
                result += ' Cents';
            }
        }


        let words = result.split(" ");


        let count = words.reduce((acc, word) => word === "and" ? acc + 1 : acc, 0);


        if (count === 2) {
            let index = words.indexOf("and");
            if (index !== -1) {
                words.splice(index, 1);
            }
            result = words.join(" ");
        }

        return result;
    }


    checkPreview = () => {
        const { requiredList,checkChequeFeedback, ...fields } = this.state;
        let hasEmpty = false;

        requiredList.forEach(field => {
            if (!fields[field]) {
                hasEmpty = true;
            }
        });

        if (checkChequeFeedback) {
            return true;
        }

        return hasEmpty;
    };

    handleBlur = (name,value) => {
        if (value) {this.props.checkChequeNumber({ chequeNumber: value }, this.handleChequeStatusResponse);
        }
    }

    handleChequeStatusResponse = (response) => {
        if (response?.data?.message) {
            this.setState({ checkChequeFeedback: response.data.message});
        } else{
            this.setState({ checkChequeFeedback: null});
        }
    }

    formatDollar = (amount, forceTwoDecimals) => {
        if(!amount) return
        let value
        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            amount = String(amount);
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',','')
                }
            } else {
                value = amount.replaceAll(',','')
            }
        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    handleChangeAmount = (e) => {
        const { name, value } = e.target;
        const newValue = this.formatDollar(e.target.value)
        this.setState({
            [name]: newValue
        });

        const amountWords = this.convertAmountToWords(parseFloat(e.target.value.replace(/,/g, '')))
        this.setState({
            amountWords
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    };




    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.signatureFeedbackBarOpen}
                    handleClose={this.closeSignatureFeedbackBar}
                    severity={this.state.status ? 'success' : 'error'}
                    message={this.state.statusText}
                />

                {!this.state.isShowPreview ?
                <TopToolbar
                    pageName={ADD_CHEQUE}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon/>,
                            onClick: () => {
                                this.props.closeIsCheque()
                                this.props.closeInvoiceTemplates()
                            }
                        },
                        {
                            title: PREVIEW,
                            icon: <VisibilityIcon/>,
                            disabled: this.checkPreview(),
                            onClick: () => {
                                this.openShowPreview()
                            }
                        },

                    ]}
                />: null}


                {this.state.isShowPreview ?
                    <CarrierChequePreview
                        carrierType={this.props.carrierType}
                        carrierIds={this.props.carrierIds}
                        saveCarrierInvoicePaymentDetails={this.props.saveCarrierInvoicePaymentDetails}
                        updateCarrierInvoiceStatus={this.props.updateCarrierInvoiceStatus}
                        saveCheque={this.props.saveCheque}
                        previewBankFile={this.props.previewBankFile}
                        closeShowPreview={this.closeShowPreview}
                        companyAddress={this.props.companyAddress}
                        closeIsCheque={this.props.closeIsCheque}
                        closeInvoiceTemplates={this.props.closeInvoiceTemplates}
                        setCurrentPage={this.setCurrentPage}
                        memoo={this.state.memoo}
                        payToName={this.state.payToName}
                        signature={this.state.signature}
                        chequeNumber={this.state.chequeNumber}
                        paymentDate={this.state.paymentDate}
                        amount={this.state.amount}
                        amountWords={this.state.amountWords}
                        bankAccount={this.state.bankAccount}
                        category={this.state.category}
                        openSignatureFeedbackBar={this.openSignatureFeedbackBar}
                    />:
                    <div className={styles.rowCarrier} style={{marginTop: "24px"}}>
                        <div className={styles.rowSpec}>
                            <div style={{marginTop: "10px"}}  className='input-text-field'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="paymentDate"
                                        label="Payment Date"
                                        value={this.state.paymentDate}
                                        onChange={(e, value) => this.handleChange({
                                            target: {
                                                name: "paymentDate",
                                                value
                                            }
                                        })}
                                        format="MM/dd/yyyy"
                                        style={{width: "200px"}}
                                        variant="inline"
                                        inputVariant="outlined"
                                        InputLabelProps={{shrink: true}}
                                        InputAdornmentProps={{position: "start"}}
                                        required={this.state.requiredList.includes("paymentDate")}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <TextField
                                className={styles.formInputsingle}
                                variant='outlined'
                                label="Pay To Order Of"
                                InputLabelProps={{shrink: true}}
                                name='payToName'
                                value={this.state.payToName}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("payToName")}
                            />

                        </div>
                        <div className={styles.rowSpec}>

                            <div style={{margin: "10px"}}>
                                <Autocomplete
                                    required={this.state.requiredList.includes("bankAccount")}
                                    options={this.props.bankAccounts}
                                    getOptionLabel={(option) => `${option.name} (${option.accountNumber})`}
                                    value={this.state.bankAccount}
                                    onChange={(e, value) => {
                                        const lastChequeNumber = value?.chequeNumber ? parseInt(value.chequeNumber) + 1 : '';
                                        this.setState({chequeNumber: lastChequeNumber, signature: "No signature"});
                                        this.handleChange({target: {name: "bankAccount", value}})
                                        }
                                    }
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="bankAccount"
                                            label="Bank Account"
                                            variant="outlined"
                                            InputLabelProps={{shrink: true}}
                                            required={this.state.requiredList.includes("bankAccount")}
                                        />
                                    }
                                    style={{width: "240px"}}
                                />
                            </div>

                            <TextField
                                className={styles.formThreeInput}
                                variant='outlined'
                                label="Cheque Number"
                                InputLabelProps={{shrink: true}}
                                name='chequeNumber'
                                value={this.state.chequeNumber}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("chequeNumber")}
                                onBlur={(e) => this.handleBlur(e.target.name, e.target.value)}
                                helperText={this.state.checkChequeFeedback ? this.state.checkChequeFeedback : null}
                                FormHelperTextProps={{
                                    style: { color: 'red' }
                                }}
                            />

                            <div   className={styles.formThreeInput} style={{margin: "10px"}} >
                                <Autocomplete
                                    required={this.state.requiredList.includes("category")}
                                    options={this.props.transactionsCategories}
                                    getOptionLabel={(option) => option.name}
                                    value={this.state.category}
                                    onChange={(e, value) => this.handleChange({
                                        target: {
                                            name: "category",
                                            value: value
                                        }
                                    })}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="category"
                                            label="Category"
                                            variant="outlined"
                                            InputLabelProps={{shrink: true}}
                                            required={this.state.requiredList.includes("category")}
                                        />
                                    )}
                                />
                            </div>

                        </div>

                        <div className={styles.rowSpec}>

                            <TextField
                                className='input-text-field'
                                required={this.state.requiredList.includes("amount")}
                                variant="outlined"
                                name="amount"
                                label="Amount"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: { style: { textAlign: 'right' } } }}
                                value={ this.formatDollar(this.state.amount)}
                                onChange={(e) => this.handleChangeAmount(e)}
                            />

                            <TextField
                                disabled={true}
                                variant='outlined'
                                label=""
                                className={styles.formInputsingle}
                                InputLabelProps={{shrink: true}}
                                name='amountWords'
                                value={this.state.amountWords}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("amountWords")}
                            />
                        </div>

                        <div className={styles.rowSpec}>
                            <TextField
                                variant='outlined'
                                label="Memo"
                                className={styles.formInputsingle}
                                InputLabelProps={{shrink: true}}
                                name='memoo'
                                value={this.state.memoo}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("memoo")}
                            />

                            {this.state.bankAccount && (
                                <Autocomplete
                                    className={styles.formTwoInput}
                                    options={["No signature", ...(this.state.bankAccount?.files || [])]}
                                    getOptionLabel={(option) =>
                                        option === "No signature" ? option : option.signatoryName
                                    }
                                    value={
                                        this.state.signature
                                            ? this.state.bankAccount?.files?.find(file => file.path === this.state.signature) || "No signature"
                                            : "No signature"
                                    }
                                    onChange={(e, value) => this.handleChange({
                                        target: {
                                            name: "signature",
                                            value: value === "No signature" || !value ? 'No signature' : value.path
                                        }
                                    })}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required={this.state.requiredList.includes("signature")}
                                            name="signature"
                                            label="Signature"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            )}

                        </div>
                    </div>
                }


            </React.Fragment>
        )
    }
}

const actionCreators = {
    listAllBankAccounts,
}

const mapStateToProps = (state) => ({
    bankAccounts: bankAccountsSelector(state),
});

export default withShipment({
    mapStateToProps,
    actionCreators
}, CarrierChequeForm);
