import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { STATEMENT_TAXES_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {
    loadingSelector,
    paidListSelector,
    receivedListSelector,
    statementTaxesSummarySelector, statementTaxesTemplateSelector,
    transactionCategoriesSelector, transactionSelector
} from "../../../redux/selectors/accounting";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import {userInfoSelector} from "../../../redux/selectors/auth";
import StatementTaxesTable from "./StatementTaxesTable";
import StatementTaxesTemplate from "./StatementTaxesTemplate";
import {
    listAllTransactions,
    listStatementTaxes, listStatementTaxesTemplate,
    listTransactionCategories,
    saveStatementTaxesTemplate
} from "../../../redux/actions/accounting";

class StatementTaxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionCategories:this.props.transactionCategories,
            currentPage: STATEMENT_TAXES_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            selectedYear: new Date().getFullYear(),
            selectedCurrency: 'CAD',
            selectedMonth: '01',
            selectedQuarters: null,
            totalSum: 0
    }
    }


    componentDidMount() {
        this.listStatementTaxesAPI()
        this.props.listTransactionCategories()
        this.props.listStatementTaxesTemplate()
        this.props.listAllTransactions()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }


    }

    handleSave = (templates) => {
        this.props.saveStatementTaxesTemplate(templates, ()=>{
            this.listStatementTaxesAPI()
        })
        this.setCurrentPage(STATEMENT_TAXES_PAGES.TABLE, {})
    }

    listStatementTaxesAPI = () =>{
        let data
        if(this.state.selectedQuarters === null){
            data = {
                "year": this.state.selectedYear,
                "quarter": [1,2,3,4],
                "currency": this.state.selectedCurrency,
                "month": this.state.selectedMonth.trim()
            }
        }else{
            let newQuarter = [this.state.selectedQuarters]
            data = {
                "year": this.state.selectedYear,
                "quarter": newQuarter,
                "currency": this.state.selectedCurrency,
                "month": this.state.selectedMonth.trim()
            }
        }
        this.props.listStatementTaxes(data)
    }

    handleYearChange = (event) => {
        this.setState({ selectedYear: event.target.value },() => {
            this.listStatementTaxesAPI()
        })

    }

    handleMonthChange = (event) => {
        this.setState({ selectedMonth: event.target.value },() => {
            this.listStatementTaxesAPI()
        })

    }

    handleCurrencyChange = (event) => {
        this.setState({ selectedCurrency: event.target.value },() => {
            this.listStatementTaxesAPI()
        })
    }


    handleQuarterChange = (quarter) => {
        const quarterMapping = {
            "QTR 1": 1,
            "QTR 2": 2,
            "QTR 3": 3,
            "QTR 4": 4
        }
        const quarterNumber = quarterMapping[quarter]
        const selectedQuarters = this.state.selectedQuarters === quarterNumber ? null : quarterNumber
        this.setState({ selectedQuarters }, () => {
            this.listStatementTaxesAPI()
        })
    }


    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
            return (
                <StatementTaxesTable
                    loadingData={this.props.loadingData}
                    selectedQuarters={this.state.selectedQuarters}
                    selectedYear={this.state.selectedYear}
                    handleQuarterChange={this.handleQuarterChange}
                    handleYearChange={this.handleYearChange}
                    selectedCurrency={this.state.selectedCurrency}
                    handleCurrencyChange={this.handleCurrencyChange}
                    selectedMonth={this.state.selectedMonth}
                    handleMonthChange={this.handleMonthChange}
                    paidList={this.props.paidList}
                    receivedList={this.props.receivedList}
                    totalSum={this.state.totalSum}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                />
            )
    }

    displaySettings = () => {
        return (
            <StatementTaxesTemplate
                handleSave={this.handleSave}
                categories={this.state.transactionCategories}
                templates={this.props.templates}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                transactions={this.props.transactions}
            />
        )

    }


    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case STATEMENT_TAXES_PAGES.TABLE:
                return this.displayTable()
            case STATEMENT_TAXES_PAGES.SETTINGS:
                return this.displaySettings()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
    user: userInfoSelector(state),
    receivedList: receivedListSelector(state),
    paidList: paidListSelector(state),
    templates: statementTaxesTemplateSelector(state),
    transactions: transactionSelector(state),
    loadingData: loadingSelector(state)
})


const actionCreators = {
    clearStatus,
    listAllTransactions,
    listTransactionCategories,
    listStatementTaxes,
    saveStatementTaxesTemplate,
    listStatementTaxesTemplate
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, StatementTaxes);