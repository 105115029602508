import {
    CREATE_CUSTOMER,
    LIST_CUSTOMERS,
    PROCESS_CUSTOMER_INVOICES,
    STORE_CUSTOMERS,
    UPDATE_CUSTOMER,
    CUSTOMER_CACHE_PDF_PREVIEW,
    CUSTOMER_CANCEL_PURCHASE_ORDER,
    CUSTOMER_CREATE_NEW_PURCHASE_ORDER,
    CUSTOMER_CREATE_SEND_NEW_PURCHASE_ORDER,
    CUSTOMER_DELETE_INVENTORY_RECEIVED,
    CUSTOMER_DELETE_PACKING_SLIP,
    CUSTOMER_EDIT_PURCHASE_ORDER,
    CUSTOMER_FETCH_ALL_PURCHASE_ORDERS,
    CUSTOMER_FETCH_ALL_SUPPLIERS,
    CUSTOMER_GET_PDF_PREVIEW,
    CUSTOMER_PREVIEW_CREATED_PURCHASE_ORDER,
    CUSTOMER_PREVIEW_PACKING_SLIP_FILE,
    CUSTOMER_RESET_PDF_PREVIEW_DATA,
    CUSTOMER_STORE_ALL_PURCHASE_ORDERS,
    CUSTOMER_STORE_COMPLETED_PURCHASE_ORDERS,
    CUSTOMER_STORE_CREATED_PURCHASE_ORDER_PREVIEW,
    CUSTOMER_STORE_NEW_PURCHASE_ORDERS,
    CUSTOMER_STORE_PARTIAL_PURCHASE_ORDERS,
    CUSTOMER_STORE_PDF_PREVIEW_READY,
    CUSTOMER_STORE_UPDATED_PURCHASE_ORDER,
    CUSTOMER_STORE_UPDATED_PURCHASE_ORDER_PREVIEW,
    CUSTOMER_UNDO_CANCEL,
    CUSTOMER_UPDATE_EXPECTED_DELIVERY_DATE,
    CUSTOMER_UPDATE_PURCHASE_ORDER,
    CUSTOMER_SLIP_PDF_PREVIEW,
    GET_ALL_CUSTOMER_INVOICES,
    STORE_ALL_CUSTOMER_INVOICES,
    UPDATE_CUSTOMER_INVOICE,
    CREATE_CUSTOMER_INVOICE,
    STORE_CUSTOMER_INVOICE_OPERATION_RESPONSE,
    REMOVE_DELETED_CUSTOMER_INVOICES,
    STORE_UPDATED_CUSTOMER_INVOICE,
    DELETE_CUSTOMER_INVOICE,
    UPDATE_CUSTOMER_INVOICE_CHANGED,
    GET_CUSTOMER_INVOICE_PO,
    STORE_CUSTOMER_INVOICE_PO,
    GET_CUSTOMER_INVOICE_PDF,
    UPDATE_CUSTOMER_INVOICE_STATUS,
    DELETE_CUSTOMER_INVOICE_ATTACHMENT,
    STORE_CUSTOMER_INVOICE_PDF,
    GET_CUSTOMER_INVOICE_ATTACHMENT,
    DELETE_CUSTOMER_ORDER,
    REMOVE_DELETED_CUSTOMER_ORDER,
    SAVE_CUSTOMER_INVOICE_PAYMENT_DETAILS,
    EDIT_SALES_CATEGORY,
    LIST_SALES_CATEGORY,
    STORE_SALES_CATEGORY,
    LIST_ALL_SALES_PIPELINES,
    SAVE_SALES_PIPELINE,
    IMPORT_SALES_PIPELINE,
    DELETE_SALES_PIPELINE,
    STORE_SALES_PIPELINE,
    STORE_SALES_PIPELINE_PDF,
    STORE_UPDATED_SALES_PIPELINE,
    GET_SALES_PIPELINE_ATTACHMENT,
    DELETE_SALES_PIPELINE_ATTACHMENT, STORE_UPDATED_ATTACHMENT_SALES_PIPELINE, UPDATE_SALES_PIPELINE_CHANGED
} from "../../constants/customer";

export const updateCustomer = (updatedCustomer) => ({
    type: UPDATE_CUSTOMER,
    updatedCustomer
})

export const createCustomer = (customer, refresh) => ({
    type: CREATE_CUSTOMER,
    customer,
    refresh
})

export const listCustomers = () => ({
    type: LIST_CUSTOMERS
})

export const storeCustomers = (customers) => ({
    type: STORE_CUSTOMERS,
    customers
})

export const processCustomerInvoices = (customer) => ({
    type: PROCESS_CUSTOMER_INVOICES,
    customer
})


export const fetchAllSuppliers = () => ({
    type: CUSTOMER_FETCH_ALL_SUPPLIERS,
})

export const storePDFpreviewReady = (storePDFPreviewReady) => ({
    type: CUSTOMER_STORE_PDF_PREVIEW_READY,
    storePDFPreviewReady
})

export const createNewPurchaseOrder = (data, reset) => (
    {
        type: CUSTOMER_CREATE_NEW_PURCHASE_ORDER,
        data,
        reset
    })
export const createSendNewPurchaseOrder = (data, reset) => (
    {
        type: CUSTOMER_CREATE_SEND_NEW_PURCHASE_ORDER,
        data,
        reset
    })


export const getPDFpreview = (pdfPreview, setPdfError) => ({
    type: CUSTOMER_GET_PDF_PREVIEW,
    pdfPreview,
    setPdfError
})

export const getSlipPDFpreview = (pdfPreview, setPdfError) => ({
    type: CUSTOMER_SLIP_PDF_PREVIEW,
    pdfPreview,
    setPdfError
})




export const cachePDFpreview = (storePDFPreviewReady) => ({
    type: CUSTOMER_CACHE_PDF_PREVIEW,
    storePDFPreviewReady
})



export const fetchAllPurchaseOrders = (data) => ({
    type: CUSTOMER_FETCH_ALL_PURCHASE_ORDERS,
    data
})


export const storeAllPurchaseOrders = (allPurchaseOrders) => ({
    type: CUSTOMER_STORE_ALL_PURCHASE_ORDERS,
    allPurchaseOrders
})

export const storeNewPurchaseOrders = (newPurchaseOrders) => ({
    type: CUSTOMER_STORE_NEW_PURCHASE_ORDERS,
    newPurchaseOrders
})

export const storePartialPurchaseOrders = (partialPurchaseOrders) => ({
    type: CUSTOMER_STORE_PARTIAL_PURCHASE_ORDERS,
    partialPurchaseOrders
})

export const storeCompletedPurchaseOrders = (completedPurchaseOrders) => ({
    type: CUSTOMER_STORE_COMPLETED_PURCHASE_ORDERS,
    completedPurchaseOrders
})

export const updatePurchaseOrder = (purchaseOrder, data, callback) => ({
    type: CUSTOMER_UPDATE_PURCHASE_ORDER,
    purchaseOrder,
    data,
    callback
})

export const deleteInventoryReceived = (inventoryReceived, data, callback) => ({
    type: CUSTOMER_DELETE_INVENTORY_RECEIVED,
    inventoryReceived,
    data,
    callback
})

export const storeUpdatedPurchaseOrder = (purchaseOrder) => ({
    type: CUSTOMER_STORE_UPDATED_PURCHASE_ORDER,
    purchaseOrder
})

export const storeUpdatedPurchaseOrderPreview = (updatedPurchaseOrder, index)=> ({
    type: CUSTOMER_STORE_UPDATED_PURCHASE_ORDER_PREVIEW,
    updatedPurchaseOrder,
    index
})

export const previewPackingSlipFile = (previewRequest) => ({
    type: CUSTOMER_PREVIEW_PACKING_SLIP_FILE,
    previewRequest
});

export const previewCreatedPurchaseOrder = (purchaseOrderData) => ({
    type: CUSTOMER_PREVIEW_CREATED_PURCHASE_ORDER,
    purchaseOrderData
});

export const storeCreatedPurchaseOrderPreview = (purchaseOrderPreview) => ({
    type: CUSTOMER_STORE_CREATED_PURCHASE_ORDER_PREVIEW,
    purchaseOrderPreview
})

export const deletePackingSlip = (data) => ({
    type: CUSTOMER_DELETE_PACKING_SLIP,
    data
})

export const cancelPurchaseOrder = (purchaseOrderData, callback) => ({
    type: CUSTOMER_CANCEL_PURCHASE_ORDER,
    purchaseOrderData,
    callback
})

export const editPurchaseOrder = (purchaseOrderData, reset, showPdf, setPdfError) => ({
    type: CUSTOMER_EDIT_PURCHASE_ORDER,
    purchaseOrderData,
    reset,
    showPdf,
    setPdfError
})

export const undoCancelPurchaseOrder = (purchaseOrderData, callback) => ({
    type: CUSTOMER_UNDO_CANCEL,
    purchaseOrderData,
    callback
})

export const resetPdfPreviewData = () => ({
    type: CUSTOMER_RESET_PDF_PREVIEW_DATA,
})

export const updateExpectedDeliveryDate = (data) => ({
    type: CUSTOMER_UPDATE_EXPECTED_DELIVERY_DATE,
    data
})

export const getAllCustomerInvoices = (data) => ({
    type: GET_ALL_CUSTOMER_INVOICES,
    data
})

export const storeAllCustomerInvoices = (invoices) => ({
    type: STORE_ALL_CUSTOMER_INVOICES,
    invoices
})

export const updateCustomerInvoice = (data, validate, reset, error) => ({
    type: UPDATE_CUSTOMER_INVOICE,
    data,
    validate,
    reset,
    error
})

export const createCustomerInvoice = (data, validate, reset, error) => ({
    type: CREATE_CUSTOMER_INVOICE,
    data,
    validate,
    reset,
    error
})

export const deleteCustomerInvoiceAttachment = (data, update) => ({
    type: DELETE_CUSTOMER_INVOICE_ATTACHMENT,
    data,
    update
})



export const storeCustomerInvoiceOperationResponse = (response) => ({
    type: STORE_CUSTOMER_INVOICE_OPERATION_RESPONSE,
    response,
})

export const removeDeletedCustomerInvoices = (customerInvoiceId) => ({
    type: REMOVE_DELETED_CUSTOMER_INVOICES,
    customerInvoiceId
})

export const removeDeletedCustomerOrder = (poNumber) => ({
    type: REMOVE_DELETED_CUSTOMER_ORDER,
    poNumber
})

export const storeUpdatedCustomerInvoice = (updatedInvoice) => ({
    type: STORE_UPDATED_CUSTOMER_INVOICE,
    updatedInvoice,
})

export const storeCustomerInvoicePDF = (data) => ({
    type: STORE_CUSTOMER_INVOICE_PDF,
    data
})
export const deleteCustomerInvoice = (data, reload) => ({
    type: DELETE_CUSTOMER_INVOICE,
    data,
    reload,
})

export const deleteCustomerOrder = (data, reload) => ({
    type: DELETE_CUSTOMER_ORDER,
    data,
    reload,
})


export const getCustomerInvoiceAttachment = (data) => ({
    type: GET_CUSTOMER_INVOICE_ATTACHMENT,
    data
})

export const updateCustomerInvoiceChanged = () => ({
    type: UPDATE_CUSTOMER_INVOICE_CHANGED
})

export const getCustomerPurchaseOrder = (purchaseOrder) => ({
    type: GET_CUSTOMER_INVOICE_PO,
    purchaseOrder
})

export const storeCustomerInvoicePurchaseOrder = (data) => ({
    type: STORE_CUSTOMER_INVOICE_PO,
    data
})

export const getCustomerInvoicePDF = (data) => ({
    type: GET_CUSTOMER_INVOICE_PDF,
    data
})

export const updateCustomerInvoiceStatus = (data, callback, fromDate, toDate) => ({
    type: UPDATE_CUSTOMER_INVOICE_STATUS,
    data,
    callback,
    fromDate,
    toDate
})

export const saveCustomerInvoicePaymentDetails = (data) => ({
    type: SAVE_CUSTOMER_INVOICE_PAYMENT_DETAILS,
    data
})
export const editCategory = (newCategory, editedCategory, deletedCategory) => ({
    type: EDIT_SALES_CATEGORY,
    newCategory,
    editedCategory,
    deletedCategory,
})

export const listCategory = () => ({
    type: LIST_SALES_CATEGORY,
})

export const storeCategory = (category) => ({
    type: STORE_SALES_CATEGORY,
    category
})

export const listAllPipelines = (data) => ({
    type: LIST_ALL_SALES_PIPELINES,
    data
})

export const savePipeline = (data, callback) => ({
    type: SAVE_SALES_PIPELINE,
    data,
    callback
})

export const importPipeline = (data, callback) => ({
    type: IMPORT_SALES_PIPELINE,
    data,
    callback
})

export const deletePipeline = (data, callback) => ({
    type: DELETE_SALES_PIPELINE,
    data,
    callback
})

export const storePipelines = (pipelines) => ({
    type: STORE_SALES_PIPELINE,
    pipelines
})

export const storePipelineAttachmentPDF = (data) => ({
    type: STORE_SALES_PIPELINE_PDF,
    data
})

export const storeUpdatedPipeline = (updatedPipelines) => ({
    type: STORE_UPDATED_SALES_PIPELINE,
    updatedPipelines
})

export const getPipelineAttachment = (data) => ({
    type: GET_SALES_PIPELINE_ATTACHMENT,
    data
})

export const deletePipelineAttachment = (data, update) => ({
    type: DELETE_SALES_PIPELINE_ATTACHMENT,
    data,
    update
})

export const storeUpdatedAttachmentPipeline = (updatedPipeline) => ({
    type: STORE_UPDATED_ATTACHMENT_SALES_PIPELINE,
    updatedPipeline,
})

export const updatePipelineChanged = () => ({
    type: UPDATE_SALES_PIPELINE_CHANGED
})