export const CREATE_RETURN = 'CREATE_RETURN';

export const STORE_RETURNS = 'STORE_RETURNS';

export const STORE_UPDATED_RETURN = 'STORE_UPDATED_RETURN';
export const UPDATE_RETURN = 'UPDATE_RETURN';

export const LIST_RETURNS = 'LIST_RETURNS';
export const LIST_UNCOMPLETED_RETURNS = 'LIST_UNCOMPLETED_RETURNS';
export const FETCH_RMA_INFO = 'FETCH_RMA_INFO';

export const LIST_RETURN_ACTIONS = 'LIST_RETURN_ACTIONS';
export const STORE_RETURN_ACTIONS = 'STORE_RETURN_ACTIONS';

export const LIST_RETURN_CONDITIONS = 'LIST_RETURN_CONDITIONS';
export const STORE_RETURN_CONDITIONS = 'STORE_RETURN_CONDITIONS';

export const LIST_RETURN_REASONS = 'LIST_RETURN_REASONS';
export const STORE_RETURN_REASONS = 'STORE_RETURN_REASONS';

export const PREVIEW_RETURN_FILE = 'PREVIEW_RETURN_FILE';
export const STORE_UPDATED_RETURN_WITH_PREVIEW = 'STORE_UPDATED_RETURN_WITH_PREVIEW';
export const STORE_RMA_FETCH = 'STORE_RMA_FETCH';


