import {call, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {hideError, requestStatus, showErrorMessage} from "../../actions/global";
import {
    FIND_INVOICE_BY_INVOICE_NUMBER_ERROR_MESSAGE,
    SERVER_FAILED_ERROR_MESSAGE
} from "../../../pages/Dashboard/DashboardFinal/constants";
import {
    ADD_TAX_INFO,
    APPLY_TRANSACTIONS_RULES,
    ASSIGN_CATEGORY,
    DELETE_TRANSACTION_ATTACHMENT,
    DELETE_TRANSACTION_CATEGORY,
    DELETE_TRANSACTIONS,
    GET_INVOICE_BY_INVOICE_NUMBER,
    GET_SUPPLIER_REFERENCES,
    GET_TRANSACTION_TAX_AMOUNT,
    GET_TAXTYPE_WITH_STATE_PROVINCE,
    GET_TRANSACTION_ATTACHMENT,
    IMPORT_TRANSACTION,
    LIST_ALL_TRANSACTIONS,
    LIST_TRANSACTION_CATEGORIES,
    SAVE_TRANSACTION,
    SAVE_TRANSACTION_CATEGORY,
    SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION,
    SAVE_CHEQUE,
    LIST_ALL_CHEQUES,
    DELETE_CHEQUES,
    LIST_SHIPPING_SUMMARY,
    LIST_SHIPPING_SETTINGS,
    SAVE_SHIPPING_SETTINGS,
    SAVE_LEDGER,
    LIST_ALL_LEDGERS,
    SAVE_TRANSACTION_LEDGER,
    FETCH_TRANSACTIONS_LEDGER,
    SAVE_STATEMENT_TAXES_TEMPLATE,
    LIST_STATEMENT_TAXES,
    LIST_STATEMENT_TAXES_TEMPLATE,
    IMPORT_CHEQUE,
    CHECK_CHEQUE_NUMBER
} from "../../constants/accounting";
import {
    CHEQUES,
    LEDGER,
    SHIPPING_SUMMARY, STATEMENT_OF_TAXES,
    TRANSACTION_CATEGORY,
    TRANSACTIONS
} from "../../../components/global/Server/endpoints";
import {
    storeAllTransactions,
    storeUpdatedTransaction,
    removeDeletedTransaction,
    setProcessing,
    storeTransactionAttachment,
    storeAllTransactionCategories,
    storeSupplierReferences,
    getInvoiceByInvoiceNumber,
    storeFoundInvoice,
    applyTransactionsRules,
    storeUpdatedTaxInfo,
    getTaxTypeWithStateProvince,
    storetaxTypeWithStateProvince,
    storeUpdatedTransactions,
    storeEditingTransaction,
    storeUpdatedCheque,
    storeAllCheques,
    storeAllShippingSummary,
    storeShippingSummarySettings,
    storeStatementTaxesTemplate,
    storeAllStatementTaxesSummary, storeAllLedgers, storeTransactionsLedger, startLoading, stopLoading,
} from "../../actions/accounting";
import {storeAllBankAccounts} from "../../actions/settings";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}


function* listAllLedgesSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LEDGER.LIST_ALL_LEDGER))
        if (response.data.success) {
            if (response.data.ledgers)
                yield put(storeAllLedgers(response.data.ledgers))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* fetchTransactionsLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.FETCH_TRANSACTION_LEDGER,action.data))
        if (response.data.success) {
            if (response.data.fetchTransactions)
                yield put(storeTransactionsLedger(response.data.fetchTransactions))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* saveLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.SAVE_LEDGER, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved ledgers",
                success: true
            }
            yield put(storeAllLedgers(response.data.ledgers))
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* saveTransactionLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.SAVE_TRANSACTION_LEDGER, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved",
                success: true
            }
            yield put(storeAllLedgers(response.data.ledgers))
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAllTransactionsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(TRANSACTIONS.LIST_ALL_TRANSACTIONS))
        if (response.data.success) {
            if (response.data.transactions) {
                let transactions = response.data.transactions

                if (action.data) {
                    if (action.data.category) {
                        transactions = transactions.filter(trans => action.data.category.includes(trans.category));
                    }

                    if (action.data.currency) {
                        transactions = transactions.filter(trans => trans.bankAccount.currency === action.data.currency);
                    }
                }

                yield put(storeAllTransactions(transactions))
            }
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* listShippingSummarySaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(SHIPPING_SUMMARY.LIST_SHIPPING_SUMMARY, action.data))
        if (response.data.success) {
            yield put(storeAllShippingSummary(response.data.summary))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* listStatementTaxesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(STATEMENT_OF_TAXES.LIST_STATEMENT_TAXES, action.data))
        if (response.data.success) {
            yield put(storeAllStatementTaxesSummary(response.data.taxSummary))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAllChequesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.LIST_ALL_CHEQUES,action.data))
        if (response.data.success) {
            yield put(storeAllCheques(response.data.cheques))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listStatementTaxesTemplate() {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => getRequest(STATEMENT_OF_TAXES.LIST_STATEMENT_TAXES_TEMPLATE))
        if (response.data.success) {
            yield put(storeStatementTaxesTemplate(response.data.templates))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* listShippingSettings() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(SHIPPING_SUMMARY.LIST_SHIPPING_SETTINGS))
        if (response.data.success) {
            yield put(storeShippingSummarySettings(response.data.rates))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* findInvoiceByInvoiceNumberSaga(action){
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(TRANSACTIONS.GET_INVOICE_BY_INVOICE_NUMBER,action.invoice))
        if(response.data.success){
            if (response.data.invoice)
                yield put(storeFoundInvoice(response.data.invoice))
            else
                yield put(storeFoundInvoice(null))
        }
        else
            yield put(storeFoundInvoice(null))
    }catch (e) {
        yield put(showErrorMessage(FIND_INVOICE_BY_INVOICE_NUMBER_ERROR_MESSAGE))
    }
}

function* importChequeSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.IMPORT_CHEQUE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Imported",
                success: true
            }
            yield put(storeUpdatedCheque(response.data.updatedCheques))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveChequeSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.SAVE_CHEQUE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Saved Cheque",
                success: true
            }
            yield put(storeUpdatedCheque(response.data.updatedCheques))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveStatementTaxesTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(STATEMENT_OF_TAXES.SAVE_STATEMENT_TAXES_TEMPLATE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Template saved successfully",
                success: true
            }
            yield put(storeStatementTaxesTemplate(response.data.templates))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveShippingSettings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SHIPPING_SUMMARY.SAVE_SHIPPING_SETTINGS, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Settings saved successfully",
                success: true
            }
            yield put(storeShippingSummarySettings(response.data.rates))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveTransactionSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.SAVE_TRANSACTION, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Saved Transaction",
                success: true
            }
            yield put(storeUpdatedTransactions(response.data.updatedTransactions))
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* assignCategoryToTransactionSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.ASSIGN_CATEGORY, {category:action.category, selectedEntries:action.selectedEntries}))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Assigned Category to Transaction(s)",
                success: true
            }
            for (let i = 0; i < response.data.updatedTransactions.length; i++) {
                yield put(storeUpdatedTransaction(response.data.updatedTransactions[i]))
            }
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* addTaxInfoSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.ADD_TAX_INFO, {taxInfo: action.taxInfo,
            selectedEntries: action.selectedEntries,}))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Assigned tax info to Transaction(s)",
                success: true
            }
            for (let i = 0; i < response.data?.updatedTransactions?.length; i++) {
                yield put(storeUpdatedTransaction(response.data?.updatedTransactions[i]))
            }
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* deleteTransactionsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.DELETE_TRANSACTIONS, action.data))
        if (response.data.success) {
            action.callback()
            yield put(storeAllTransactions(response.data.allTransactions))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteChequesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.DELETE_CHEQUE, action.data))
        if (response.data.success) {
            action.callback()
            yield put(storeAllCheques(response.data.allCheques))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* importTransactionSaga(action) {
    yield put(hideError())
    yield put(setProcessing(true))
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.IMPORT_TRANSACTION, action.data))
        yield put(setProcessing(false))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: `Imported ${response.data.transactions.length} out of ${response.data.totalTransactions} Transactions`,
                success: true
            }
            for (let i = 0; i < response.data.transactions.length; i++) {
                yield put(storeUpdatedTransaction(response.data.transactions[i]))
            }
            action.callback()
            yield put(requestStatus(status))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* getTransactionAttachmentSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_TRANSACTION_ATTACHMENT, action.data))
        if (response.data.success) {
            yield put(storeTransactionAttachment(response.data.attachmentFile))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteTransactionAttachmentSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.DELETE_TRANSACTION_ATTACHMENT, action.data))
        if (response.data.success) {
            yield put(storeUpdatedTransaction(response.data.updatedTransaction))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSupplierReferencesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_SUPPLIER_REFERENCES, action.data))
        if (response.data.success) {
            yield put(storeSupplierReferences(response.data.references))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTransactionTaxAmountSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_TRANSACTION_TAX_AMOUNT, {taxInfo: action.data, transaction: action.transaction, index: action.index}))
        if (response.data.success) {

            yield put(storeUpdatedTaxInfo(response.data.updatedTaxInfo))

        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTaxTypeWithStateProvinceSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_TAXTYPE_WITH_STATE_PROVINCE))
        if (response.data.success) {
            yield put(storetaxTypeWithStateProvince(response.data.taxTypewithStateProvince))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listTransactionCategoriesSaga() {
    yield put(hideError())
    try {
        const response = yield call (() => getRequest(TRANSACTION_CATEGORY.LIST_TRANSACTION_CATEGORIES))
        if (response.data.success) {
            yield put(storeAllTransactionCategories(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveTransactionCategorySaga(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(TRANSACTION_CATEGORY.SAVE_TRANSACTION_CATEGORY, action.data))
        if (response.data.success) {
            yield put(storeAllTransactionCategories(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveAndNavigateToChildTransaction(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(TRANSACTIONS.SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION, {
            index: action.index,
            transactionEdited: action.transactionEdited
        }))
        if (response.data.success) {
            if (response.data.listOfCreatedTransactions)
                yield put(storeUpdatedTransactions(response.data.listOfCreatedTransactions))
            if (response.data.editingTransaction)
                yield put(storeEditingTransaction(response.data.editingTransaction))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        console.log(error)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteTransactionCategorySaga(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(TRANSACTION_CATEGORY.DELETE_TRANSACTION_CATEGORY, action.data))
        if (response.data.success) {
            yield put(storeAllTransactionCategories(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* checkChequeNumberSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.CHECK_CHEQUE,action.data))
        if (response.data.success) {
            action.callback(response)
        } else {
            action.callback(response)
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
export default function* accountingSagas() {
    yield takeLatest(LIST_ALL_TRANSACTIONS, listAllTransactionsSaga)
    yield takeLatest(LIST_ALL_LEDGERS, listAllLedgesSaga)
    yield takeLatest(FETCH_TRANSACTIONS_LEDGER, fetchTransactionsLedgerSaga)
    yield takeLatest(LIST_ALL_CHEQUES, listAllChequesSaga)
    yield takeLatest(CHECK_CHEQUE_NUMBER, checkChequeNumberSaga)
    yield takeLatest(LIST_SHIPPING_SETTINGS, listShippingSettings)
    yield takeLatest(LIST_STATEMENT_TAXES_TEMPLATE, listStatementTaxesTemplate)
    yield takeLatest(LIST_SHIPPING_SUMMARY, listShippingSummarySaga)
    yield takeLatest(SAVE_TRANSACTION, saveTransactionSaga)
    yield takeLatest(SAVE_LEDGER, saveLedgerSaga)
    yield takeLatest(SAVE_TRANSACTION_LEDGER, saveTransactionLedgerSaga)
    yield takeLatest(SAVE_CHEQUE, saveChequeSaga)
    yield takeLatest(IMPORT_CHEQUE, importChequeSaga)
    yield takeLatest(SAVE_SHIPPING_SETTINGS, saveShippingSettings)
    yield takeLatest(LIST_STATEMENT_TAXES, listStatementTaxesSaga)
    yield takeLatest(SAVE_STATEMENT_TAXES_TEMPLATE, saveStatementTaxesTemplate)
    yield takeLatest(DELETE_TRANSACTIONS, deleteTransactionsSaga)
    yield takeLatest(DELETE_CHEQUES, deleteChequesSaga)
    yield takeLatest(ASSIGN_CATEGORY, assignCategoryToTransactionSaga)
    yield takeLatest(ADD_TAX_INFO, addTaxInfoSaga)
    yield takeLatest(IMPORT_TRANSACTION, importTransactionSaga)
    yield takeLatest(GET_TRANSACTION_ATTACHMENT, getTransactionAttachmentSaga)
    yield takeLatest(DELETE_TRANSACTION_ATTACHMENT, deleteTransactionAttachmentSaga)
    yield takeLatest(GET_SUPPLIER_REFERENCES, getSupplierReferencesSaga)
    yield takeLatest(GET_TRANSACTION_TAX_AMOUNT, getTransactionTaxAmountSaga)
    yield takeLatest(GET_TAXTYPE_WITH_STATE_PROVINCE, getTaxTypeWithStateProvinceSaga)
    yield takeLatest(GET_INVOICE_BY_INVOICE_NUMBER,findInvoiceByInvoiceNumberSaga)
    yield takeLatest(LIST_TRANSACTION_CATEGORIES, listTransactionCategoriesSaga)
    yield takeLatest(SAVE_TRANSACTION_CATEGORY, saveTransactionCategorySaga)
    yield takeLatest(DELETE_TRANSACTION_CATEGORY, deleteTransactionCategorySaga)
    yield takeLatest(SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION, saveAndNavigateToChildTransaction)
}